import React from 'react';
import { Button, Modal, Form, DatePicker } from 'antd';

import { ChangeTeamEndDateComponent } from '../../apollo';
import { message } from '../../components/message';

class ChangeTeamEndDateModal extends React.Component<any> {
  state = {
    visible: false,
    uri: '',
  };

  handleSubmit = (send: any, e: any) => {
    e.preventDefault();
    this.props.form.validateFields(async (err: any, values: any) => {
      if (!err) {
        Modal.confirm({
          title: '您确定要更改团队订阅到期时间吗？',
          content: '一旦修改，C端也将发生相应变化',
          okText: '确认',
          cancelText: '取消',
          onOk: async () => {
            try {
              await send({
                variables: {
                  ChangeTeamEndDate: {
                    ...values,
                    team_id: this.props.id,
                  },
                },
              });
              message.success('修改成功');
              const { refetch } = this.props;
              refetch();
              this.close();
            } catch (e) {
              message.error(e);
            }
          },
        });
      }
    });
  };

  close = () => {
    const { resetFields } = this.props.form;
    resetFields();
    this.setState({ visible: false, uri: '' });
  };

  render() {
    const { visible } = this.state;
    const { form } = this.props;
    const { getFieldDecorator } = form;
    return (
      <div>
        {/* <Button onClick={() => this.setState({ visible: true })}>
          修改到期时间
        </Button> */}
        <span
          style={{ color: 'red' }}
          className="hand"
          onClick={() => this.setState({ visible: true })}
        >
          修改
        </span>
        <Modal
          title="更改"
          centered
          visible={visible}
          onCancel={() => this.setState({ visible: false })}
          footer={null}
        >
          <Form>
            <Form.Item label="请选择到期时间">
              {getFieldDecorator('plan_end_date', {
                rules: [{ required: true, message: '请选择!' }],
              })(<DatePicker />)}
            </Form.Item>
            <p style={{ color: 'rgba(0,0,0,0.3)' }}>
              注：一旦确认修改后，前端会立刻变化
            </p>
            <Form.Item style={{ textAlign: 'right' }}>
              <Button
                onClick={() => this.setState({ visible: false })}
                style={{ marginRight: '30px' }}
              >
                取消
              </Button>
              <ChangeTeamEndDateComponent>
                {(send, { loading }) => {
                  return (
                    <Button
                      type="primary"
                      loading={loading}
                      onClick={this.handleSubmit.bind(this, send)}
                    >
                      确定
                    </Button>
                  );
                }}
              </ChangeTeamEndDateComponent>
            </Form.Item>
          </Form>
        </Modal>
      </div>
    );
  }
}

export const ChangeTeamEndDateModalForm: any = Form.create({
  name: 'change-team-end-date',
})(ChangeTeamEndDateModal);
