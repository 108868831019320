import React from 'react';
import { Modal } from 'antd';
import moment from 'moment';
import PayUriModal from './pay_uri';
import { ChangeOrderStatusByAdminComponent } from '../../../apollo';
import { message } from '../../../components/message';
import NotPassOrder from './no-pass';
import AddPrice from './add-price';
import DeleteOrderItem from './delete-order-item';
const { confirm } = Modal;

function changeOrderStatus(id: string, send: any, reFetch?: any) {
  return confirm({
    title: '请再次确认凭证内容',
    content: '确认后，订单状态将变为“支付成功”',
    okText: '确定',
    cancelText: '取消',
    async onOk() {
      await send({
        variables: {
          ChangeOrderStatus: {
            order_id: id,
          },
        },
      });
      if (reFetch) {
        reFetch();
      }
      message.success('修改成功');
    },
    onCancel() {
      // console.log('Cancel');
    },
  });
}

export const columns = (reFetch?: any) => [
  {
    title: '订单号',
    dataIndex: 'out_trade_no',
    key: 'out_trade_no',
    width: 120,
  },
  {
    title: '下单日期',
    dataIndex: 'created_date',
    key: 'created_date',
    width: 120,
    sorter: true,
    render: (text: string, record: any) => moment(text).format('YYYY-MM-DD'),
  },
  {
    title: '服务类型',
    dataIndex: 'type',
    key: 'type',
    width: 120,
    filters: [
      { text: '单次独立项目', value: '单次独立项目' },
      { text: '云设计部订阅', value: '云设计部订阅' },
    ],
  },
  {
    title: '明细',
    dataIndex: 'detail',
    key: 'detail',
    width: 120,
  },
  {
    title: '金额',
    dataIndex: 'old_price',
    key: 'old_price',
    width: 120,
    render: (text: string, record: any) => {
      if (record.type === '优惠') {
        return '-' + text;
      } else if (record.type === '价格调整') {
        return '+' + text;
      }
      return text;
    },
  },
  {
    title: '实付',
    dataIndex: 'price',
    key: 'price',
    width: 120,
  },
  {
    title: '支付方式',
    dataIndex: 'pay_type',
    key: 'pay_type',
    width: 120,
    filters: [
      { text: '支付宝', value: '支付宝' },
      { text: '微信', value: '微信' },
      { text: '对公账户', value: '对公账户' },
    ],
  },
  {
    title: '创建者',
    dataIndex: 'username',
    key: 'username',
    width: 200,
  },
  {
    title: '付款日期',
    dataIndex: 'pay_date',
    key: 'pay_date',
    width: 120,
    // sorter: true,
    render: (text: string, record: any) =>
      text ? moment(text).format('YYYY-MM-DD') : '',
  },
  // {
  //   title: '备注',
  //   dataIndex: 'remark',
  //   key: 'remark',
  //   width: 160,
  //   render: (text: string, record: any) => (
  //     <Tooltip placement="topLeft" title={text}>
  //       <span
  //         style={{
  //           display: 'block',
  //           textOverflow: 'ellipsis',
  //           whiteSpace: 'nowrap',
  //           overflow: 'hidden',
  //           width: "160px"
  //         }}
  //       >
  //         {text}
  //       </span>
  //     </Tooltip>
  //   ),
  // },
  {
    title: '状态',
    dataIndex: 'status',
    key: 'status',
    width: 120,
    render: (status: string) => {
      if (status === '未付款' || status === '待审核') {
        return <span style={{ color: '#DB5B6B' }}>{status}</span>;
      }
      return status;
    },
    filters: [
      { text: '支付成功', value: '支付成功' },
      { text: '待付款', value: '待付款' },
      { text: '已取消', value: '已取消' },
      { text: '待审核', value: '待审核' },
      { text: '审核未通过', value: '审核未通过' },
    ],
  },
  {
    title: '操作',
    dataIndex: 'action',
    key: 'action',
    width: 300,
    render: (_: any, data: any) => {
      if (data.type === '优惠' || data.type === '价格调整') {
        return <DeleteOrderItem id={data.id} reFetch={reFetch} />
      }
      switch (data.status) {
        case '待付款': {
          return (
            <>
              <AddPrice
                order_type={0}
                out_trade_no={data.out_trade_no}
                price={data.price}
                reFetch={reFetch}
              />{' '}
              |{' '}
              <AddPrice
                order_type={1}
                out_trade_no={data.out_trade_no}
                price={data.price}
                reFetch={reFetch}
                text="新增价格调整"
              />{' '}
            </>
          );
        }
        case '待审核': {
          return (
            <>
              <PayUriModal pay_uri={data.pay_uri} /> |{' '}
              <ChangeOrderStatusByAdminComponent>
                {send => {
                  return (
                    <span
                      style={{ color: 'rgb(36, 131, 248)' }}
                      className="hand"
                      onClick={() => changeOrderStatus(data.id, send, reFetch)}
                    >
                      通过
                    </span>
                  );
                }}
              </ChangeOrderStatusByAdminComponent>
              | <NotPassOrder id={data.id} reFetch={reFetch} />
            </>
          );
        }
        case '审核未通过': {
          return <PayUriModal pay_uri={data.pay_uri} />;
        }
        default:
          return '';
      }
    },
  },
];
