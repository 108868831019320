// export const server_uri = 'https://dev.nef.design';
// export const server_uri = 'http://127.0.0.1:9999';
const windows_uri = window.location.origin;

let dd_uri = windows_uri || 'https://platform.nef.design';
// let dd_uri = 'https://dev.nef.design';

if (
  window.location.hostname === '127.0.0.1' ||
  window.location.hostname === 'localhost'
) {
  dd_uri = 'http://127.0.0.1:9999';
  // dd_uri = 'https://api.test.nef.design';
}

export const client_uri = windows_uri || 'https://platform.nef.design';
export const server_uri = dd_uri;

export const oss_uri = `https://nef-design-public.oss-cn-beijing.aliyuncs.com/`

export const graphql_uri = `${server_uri}/graphql`;
export const team_uri = `https://platform.nef.design/add-team`;