import React from 'react';
import { Button, Modal } from 'antd';

// import styles from './index.module.css';
import { RightDetail, FileCard } from '../../detail';

export class IndependentModal extends React.Component<any> {
  state = {
    visible: false,
  };

  render() {
    const { visible } = this.state;
    const { record } = this.props;
    return (
      <div>
        <Button onClick={() => this.setState({ visible: true })}>查看</Button>
        <Modal
          title="详情"
          centered
          width={800}
          visible={visible}
          onCancel={() => this.setState({ visible: false })}
          footer={null}
        >
          <div>
            <RightDetail title="需求名称" content={record.name} />
            <RightDetail title="需求描述" content={record.description} />
            {record.description_files &&
              record.description_files.map(({ name, uri }: any) => {
                return <FileCard key={uri} name={name} uri={uri} />;
              })}
            {record.description_files && (
              <>
                <br />
                <br />
              </>
            )}
            <RightDetail title="项目周期" content={record.cycle} />
            <RightDetail title="预算范围" content={record.budget} />
          </div>
        </Modal>
      </div>
    );
  }
}
