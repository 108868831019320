import React from 'react';

import queryString from 'query-string';
import {
  BaseTable,
  TableTitle,
} from '../../components/base-table';
import { TeamsComponent } from '../../apollo';
import { AllTable,TableSearch } from '../../components/base-table';
import { BaseLayout } from '../../layout/base-layout';
import { columns } from './team.columns';
import { Tabs } from 'antd';

const { TabPane } = Tabs;

function getData(data: any) {
  const new_data =
    data.teams && data.teams.list
      ? data.teams.list.map((item: any) => {
        return {
          ...item,
          key: item.id,
          name: item.name,
          mobile: item.owner.mobile,
          created_date: item.created_date,
          plan_end_date: item.plan_end_date,
          balance: item.balance,
          end_date: item.subscriptions[0] && item.subscriptions[0].end_date,
        };
      })
      : [];
  const count = (data.teams && data.teams.count) || 0;
  return {
    data: new_data,
    count,
  };
}

const columns_titles = columns().map(({ title }) => title);

const base_titles = columns_titles;

class Company extends BaseTable {
  state = {
    checked_options: base_titles,
    query_params: {},
    fixed: false,
  };

  onCheckAllChange = (e: any) => {
    this.setState({
      checked_options: e.target.checked ? columns_titles : [],
    });
  };

  onColumnChange = (checkedList: any) => {
    this.setState({
      checked_options: checkedList,
    });
  };

  render() {
    const params = queryString.parse(this.props.location.search);
    const skip = Number(params.skip) || 0;
    return (
      <TeamsComponent
        variables={{
          skip,
          take: 20,
          range: this.props.range,
          ...this.state.query_params,
        }}
        fetchPolicy="no-cache"
      >
        {({ data, error, refetch }) => {
          if (error) {
            return <>Access denied</>;
          }
          const result = getData(data);
          const new_columns = columns(refetch);
          return (
            <div className="table_container">
              {/* <TableTitle name={`团队管理`} number={result.count} />
              <br />
              <br />
              <br /> */}
              <TableSearch
                  search={(e: any) => this.search(e.target.value)}
                  options={columns_titles}
                  checked_options={this.state.checked_options}
                  onCheckAllChange={this.onCheckAllChange}
                  onColumnChange={this.onColumnChange}
                  outData={() => {}}
                  columns={this.state.checked_options}
                  fixed={this.state.fixed}
                  fixedName={(fixed: any) => {
                    this.setState({ fixed });
                  }}
                  params={{
                    skip,
                    take: 20,
                    ...this.state.query_params,
                  }}
                /> 
              <AllTable
                scroll={{ x: true }}
                onChange={(pagination: any, filters: any, sorter: any) =>
                  this.onTableChange(pagination, filters, sorter, refetch)
                }
                columns={new_columns}
                loading={data && data.teams ? false : true}
                dataSource={result.data}
                pagination={{
                  total: result.count,
                  pageSize: 20,
                }}
                checked_options={this.state.checked_options}
                fixed={this.state.fixed}
              />
            </div>
          );
        }}
      </TeamsComponent>
    );
  }
}

export default class TeamTabs extends React.Component<any, any> {
  state = {
    range: "订阅中"
  }
  render() {
    return <TeamsComponent
      variables={{
        skip: 0,
        take: 1,
        range: this.state.range,
      }}
      fetchPolicy="no-cache"
    >
      {({ data, error }) => {
        if (error) {
          return <>Access denied</>;
        }
        return (
          <BaseLayout>
            <div style={{ position: 'relative' }}>
              <TableTitle name={`团队管理`} number={data && data.teams ? data.teams.count : 0} />
              <br />
              <br />
              <br />
            </div>
            <Tabs defaultActiveKey="订阅中" onChange={(key) => this.setState({ range: key })}>
              <TabPane
                tab={`订阅中`}
                key="订阅中"
              >
                <Company location={this.props.location} range="订阅中" />
              </TabPane>
              <TabPane
                tab={`全部`}
                key="全部"
              >
                <Company location={this.props.location} range="全部" />
              </TabPane>
            </Tabs>
          </BaseLayout>
        )
      }}
    </TeamsComponent>
  }
}