import React from 'react';
// import { Button } from 'antd';
// import { AddBalanceModalForm } from './add-balance';
import { UploadImageModalForm } from './upload-image';
import { ChangeTeamEndDateModalForm } from './change-team-end-date';
import { ChangeTeamNameModalForm } from './change-team-name';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { ChangeTeamDesignerModalForm } from './change-team-designer-modal-form';
import { ChangeTeamDesignerAvsierModalForm } from './change-team-designer-avsier';
import { CreateTeamUriModal } from './create-team-uri';
import { DeleteTeamComponent } from '../../apollo';
import { Modal, Tooltip } from 'antd';
import { message } from '../../components/message';

const { confirm } = Modal;

export const columns = (reFetch?: any) => [
  {
    title: '团队名称',
    dataIndex: 'name',
    key: 'name',
    // search: true,
    sorter: true,
    fixed: 'left',
    width: 200,
    render: (text: string, record: any) => (
      <Link to={`/company?id=${record.key}`}>{text}</Link>
    ),
  },
  {
    title: '创建者',
    dataIndex: 'owner',
    key: 'owner',
    width: 80,
    render: (text: string, record: any) => (
      record.owner && record.owner.username
    ),
  },
  {
    title: '注册手机号',
    dataIndex: 'mobile',
    key: 'mobile',
    width: 120,
  },
  {
    title: '注册日期',
    dataIndex: 'created_date',
    key: 'created_date',
    width: 120,
    sorter: true,
    render: (text: string, record: any) => moment(text).format('YYYY-MM-DD'),
  },
  {
    title: '订阅到期日',
    dataIndex: 'plan_end_date',
    key: 'plan_end_date',
    width: 120,
    sorter: true,
    render: (text: string, record: any) => (
      <div style={{ position: 'relative' }}>
        {text ? (
          <>
            {moment(text).format('YYYY-MM-DD')}
            <ChangeTeamEndDateModalForm refetch={reFetch} id={record.key} />
          </>
        ) : (
          text
        )}
      </div>
    ),
  },
  // {
  //   title: '订阅到期日',
  //   dataIndex: 'end_date',
  //   key: 'end_date',
  //   width: 120,
  //   sorter: true,
  //   render: (text: string, record: any) =>
  //     text ? moment(text).format('YYYY-MM-DD') : text,
  // },
  // {
  //   title: '账户余额',
  //   dataIndex: 'balance',
  //   key: 'balance',
  //   sorter: true,
  //   width: 120,
  // },
  {
    title: '设计师',
    dataIndex: 'designer',
    key: 'designer',
    width: 220,
    render: (text: string, record: any) =>{
      if (record.designer) {
        return <div style={{display:'flex',alignItems:'center'}}>
          <Tooltip title={record.designer.username}>
          <span>{record.designer.username.length>4?record.designer.username.slice(0,4)+'...':record.designer.username}</span>
          </Tooltip>
          <ChangeTeamDesignerModalForm refetch={reFetch} id={record.key} title='更换设计师' />
        </div>
      } else {
        return <ChangeTeamDesignerModalForm refetch={reFetch} id={record.key} title='选择设计师' />
      }
    },
  },
  {
    title: '设计顾问',
    dataIndex: 'designeraAviser',
    key: 'designeraAviser',
    width: 220,
    render: (text: string, record: any) => {
      if (record.designerAdviser) {
        return <div style={{display:'flex',alignItems:'center'}}>
          <Tooltip title={record.designerAdviser.username}>
          <span>{record.designerAdviser.username.length>4?record.designerAdviser.username.slice(0,4)+'...':record.designerAdviser.username}</span>
          </Tooltip>
          <ChangeTeamDesignerAvsierModalForm refetch={reFetch} id={record.key} title='更换设计顾问' />
        </div>
      } else {
        return <ChangeTeamDesignerAvsierModalForm refetch={reFetch} id={record.key} title='选择设计顾问' />
      }
    },
  },
  {
    title: '操作',
    width: 200,
    key: 'action',
    render: (_: string, record: any) => (
      <>
        {/* <AddBalanceModalForm id={record.key} refetch={reFetch} /> */}
        <UploadImageModalForm
          refetch={reFetch}
          id={record.key}
          oss_card_uri={record.oss_card_uri}
          card_uri={record.card_uri}
        />
        {/* <ChangeTeamDesignerModalForm
          refetch={reFetch}
          id={record.key}
        /> */}
        <ChangeTeamNameModalForm
          refetch={reFetch}
          id={record.key}
        />
        <CreateTeamUriModal
          // refetch={reFetch}
          team={record}
        />
        <DeleteTeamComponent>
          {send => {
            return (
              <span
                style={{ color: 'red' }}
                className="hand"
                onClick={() => {
                  confirm({
                    title: '请再次确认',
                    content: '团队删除后无法恢复',
                    okText: '确定',
                    cancelText: '取消',
                    async onOk() {
                      try {
                        await send({
                          variables: {
                            id: record.key
                          },
                        });
                        if (reFetch) {
                          reFetch();
                        }
                        message.success('删除成功');
                      } catch (e) {
                        message.error(new Error("团队无法删除"));
                      }
                    },
                    onCancel() {
                      // console.log('Cancel');
                    },
                  });
                }}
              >
                删除
              </span>
            );
          }}
        </DeleteTeamComponent>
        {/* <ChangeTeamEndDateModalForm refetch={reFetch} id={record.key} /> */}
        {/* <Button>
          <Link to={`/choose-type?team_id=${record.key}`}>+ 新增需求</Link>
        </Button> */}
      </>
    ),
    // render: (_: string, record: any) => <AddBalanceModalForm id={record.key} />,
    // render: () => <Button type="primary">赠送余额</Button>,
  },
];
