import React from 'react';

import queryString from 'query-string';
import { BaseTable, TableSearch } from '../../components/base-table';
import { DesignerListComponent } from '../../apollo';
import { AllTable } from '../../components/base-table';
import { BaseLayout } from '../../layout/base-layout';
import { columns } from './designer.columns';
// import { AddDesignerModalForm } from './create-designer';
import { Tabs } from 'antd';

const { TabPane } = Tabs;

function getData(data: any) {
  const new_data =
    data.designerList && data.designerList.list
      ? data.designerList.list.map((item: any) => {
        return {
          ...item,
          key: item.id,
        };
      })
      : [];
  const count = (data.designerList && data.designerList.count) || 0;
  return {
    data: new_data,
    count,
  };
}

const columns_titles = columns().map(({ title }) => title);

const base_titles = columns_titles;

class DesignerTable extends BaseTable {
  state = {
    checked_options: base_titles,
    query_params: { role: this.props.status },
    fixed: false,
  };

  onCheckAllChange = (e: any) => {
    this.setState({
      checked_options: e.target.checked ? columns_titles : [],
    });
  };

  onColumnChange = (checkedList: any) => {
    this.setState({
      checked_options: checkedList,
    });
  };

  render() {
    // const params = queryString.parse(this.props.location.search);
    const params = queryString.parse(this.props.params);
    const skip = Number(params.skip) || 0;
    return (
      // <BaseLayout>
      <DesignerListComponent
        variables={{
          skip,
          take: 20,
          ...this.state.query_params,
        }}
        fetchPolicy="no-cache"
      >

        {({ data, error, refetch }) => {
          if (error) {
            return <>Access denied</>;
          }
          const result = getData(data);
          const new_columns = columns(refetch);
          return (

            <div className="table_container">
              {/* <TableTitle name={`设计师管理`} number={result.count} /> */}
              <br />
              <TableSearch
                search={(e: any) => this.search(e.target.value)}
                options={columns_titles}
                checked_options={this.state.checked_options}
                onCheckAllChange={this.onCheckAllChange}
                onColumnChange={this.onColumnChange}
                outData={() => { }}
                columns={this.state.checked_options}
                fixed={this.state.fixed}
                fixedName={(fixed: any) => {
                  this.setState({ fixed });
                }}
                params={{
                  skip,
                  take: 20,
                  ...this.state.query_params,
                }}
              >
                {/* <AddDesignerModalForm refetch={refetch} /> */}
              </TableSearch>
              <AllTable
                scroll={{ x: true }}
                onChange={(pagination: any, filters: any, sorter: any) =>
                  this.onTableChange(pagination, filters, sorter, refetch)
                }
                columns={new_columns}
                loading={data && data.designerList ? false : true}
                dataSource={result.data}
                pagination={{
                  total: result.count,
                  pageSize: 20,
                }}
                checked_options={this.state.checked_options}
                fixed={this.state.fixed}
              />
            </div>
          );
        }}
      </DesignerListComponent>
      // </BaseLayout>
    );
  }
}

export default class Designer extends React.Component<any>{
  render() {
    const params = queryString.parse(this.props.location.search);
    return (
      <BaseLayout>
        <Tabs defaultActiveKey="1">
          <TabPane
            tab={`设计师管理`}
            key="1"
          >
            <DesignerTable status='设计师' params={params} />
          </TabPane>
          <TabPane
            tab={`设计顾问管理`}
            key="2"
          >
            <DesignerTable status='设计顾问' params={params} />
          </TabPane>
        </Tabs>
      </BaseLayout>

    )
  }
}
