import React from 'react';
import { Popover } from 'antd';

import styles from './index.module.css';
import { ViewerComponent } from '../../../apollo';

export class QrCode extends React.Component<any> {
  render() {
    return (
      <ViewerComponent>
        {({ data, error, loading }) => {
          if (!data || error || loading) {
            return '';
          }
          const uri =
            data.viewer && data.viewer.team && data.viewer.team.oss_card_uri;
          return (
            <div className={styles.design_consultant}>
              <Popover
                placement="left"
                title={null}
                content={
                  <div className={styles.qr_code}>
                    <img
                      src={
                        uri ||
                        'https://nef-design-public.oss-cn-beijing.aliyuncs.com/wechat.jpeg'
                      }
                      alt="二维码"
                    />
                    <span style={{ color: '#7CB2B8' }}>
                      微信扫码，联系设计顾问
                    </span>
                  </div>
                }
                trigger="hover"
              >
                联系设计顾问
              </Popover>
            </div>
          );
        }}
      </ViewerComponent>
    );
  }
}
