import React from 'react';

export const columns = [
  {
    title: '名称',
    dataIndex: 'name',
    key: 'name',
    width: 120,
    // render: (text: string, record: any) => {
    //   return <Link to={`/independent-detail?id=${record.key}`}>{text}</Link>;
    // },
  },
  {
    title: '需求提出人',
    dataIndex: 'owner_username',
    key: 'owner_username',
    width: 120,
  },
  {
    title: '公司名称',
    dataIndex: 'team_name',
    key: 'team_name',
    width: 120,
  },
  {
    title: '项目周期',
    dataIndex: 'cycle',
    key: 'cycle',
    width: 120,
  },
  {
    title: '预算范围',
    dataIndex: 'budget',
    key: 'budget',
    width: 120,
  },
  {
    title: '操作',
    width: 120,
    key: 'action',
    render: (_: string, record: any) => <div>1</div>,
  },
  // {
  //   title: '提交时间',
  //   dataIndex: 'created_date',
  //   key: 'created_date',
  //   width: 120,
  //   sorter: true,
  //   render: (text: string, record: any) => moment(text).format('YYYY-MM-DD'),
  // },
];
