import React from 'react';

import queryString from 'query-string';
import { BaseLayout } from '../../layout/base-layout';
import { Tabs } from 'antd';
import { Demand } from './demand';
import { Member } from './member';
// import { Subscription } from './subscription';
import { Order } from './order';
import { GetTeamByIdComponent } from '../../apollo';
import styles from './index.module.css';
import moment from 'moment';
import { ChangeSubscriptionCountModalForm } from './change-subscription-count-modal-form';

const { TabPane } = Tabs;

export default class Company extends React.Component {
  state = {
    query_params: {},
  };

  render() {
    const params = queryString.parse(window.location.search);
    // const skip = Number(params.skip) || 0;
    return (
      <GetTeamByIdComponent variables={{ id: String(params.id) }}>
        {({ data, loading, error, refetch }) => {
          if (!data || loading || error) {
            return '';
          }
          const subscription = data.getTeamById.subscriptions[0];
          return (
            <BaseLayout>
              <div>
                <div className={styles.title_top}>
                  <div className={styles.title_name}>
                    {data.getTeamById.name}
                    {/* <Button
                      onClick={() => this.props.history.push('/choose-type')}
                      style={{
                        position: 'absolute',
                        right: '8px',
                        top: '12px',
                      }}
                    >
                      + 新增需求
                    </Button> */}
                  </div>
                  <div className={styles.title_right}>
                    {subscription && (
                      <>
                        <div className={styles.subscription}>
                          {data.getTeamById.plan}
                          {/* {subscription.name} */}
                          <span style={{ fontSize: '16px', fontWeight: 500 }}>
                            （可同时进行{data.getTeamById.plan_count}个需求）
                            {/* （可同时进行{subscription.count}个需求） */}
                          </span>
                        </div>
                        <ChangeSubscriptionCountModalForm
                          team_id={data.getTeamById.id}
                          refetch={refetch}
                        />
                      </>
                    )}
                  </div>
                </div>
                <div className={styles.title_bottom}>
                  <div>注册手机号码：{data.getTeamById.owner.mobile}</div>
                  <div style={{ marginLeft: '120px' }}>
                    注册日期{' '}
                    {moment(data.getTeamById.created_date).format('YYYY-MM-DD')}
                  </div>
                  <div className={styles.title_balance}>
                    余额：¥{data.getTeamById.balance}
                  </div>
                </div>
              </div>
              <Tabs defaultActiveKey="2">
                <TabPane tab="需求列表" key="1">
                  <Demand />
                </TabPane>
                {/* <TabPane tab="历史订阅" key="3">
                  <Subscription />
                </TabPane> */}
                <TabPane tab="订单" key="2">
                  <Order />
                </TabPane>
                {/* <TabPane tab="充值记录" key="2">
                  <Order />
                </TabPane> */}
                <TabPane tab="公司成员" key="4">
                  <Member owner_id={data.getTeamById.owner.id} />
                </TabPane>
              </Tabs>
            </BaseLayout>
          );
        }}
      </GetTeamByIdComponent>
    );
  }
}
