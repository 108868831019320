import React from 'react';

import queryString from 'query-string';
import {
  BaseTable,
} from '../../../components/base-table';
import { MembersComponent } from '../../../apollo';
import { columns } from './member.columns';
import { withRouter } from 'react-router';
import moment from 'moment';
import styles from './index.module.css';
import { Button, Tag } from 'antd';
import { ChangeTeamModalForm } from './change-team-form';

function getData(data: any) {
  const name = 'members';
  const new_data =
    data[name] && data[name].list
      ? data[name].list.map((item: any) => {
        return {
          id: item.id,
          key: item.id,
          created_date: item.created_date,
          end_date: item.end_date,
          owner_username: item.owner.username,
          mobile: item.owner.mobile,
          owner_id: item.owner.id,
          team_id: item.team_id,
          owner_headimgurl: item.owner.headimgurl,
        };
      })
      : [];
  const count = (data[name] && data[name].count) || 0;
  return {
    data: new_data,
    count,
  };
}

const columns_titles = columns.map(({ title }) => title);

const base_titles = columns_titles;

class MemberTable extends BaseTable {
  state = {
    checked_options: base_titles,
    query_params: {},
    fixed: false,
  };

  onCheckAllChange = (e: any) => {
    this.setState({
      checked_options: e.target.checked ? columns_titles : [],
    });
  };

  onColumnChange = (checkedList: any) => {
    this.setState({
      checked_options: checkedList,
    });
  };

  render() {
    const params = queryString.parse(this.props.location.search);
    const skip = Number(params.skip) || 0;
    const { owner_id } = this.props;
    return (
      <MembersComponent
        variables={{
          skip,
          take: 20,
          team_id: String(params.id),
          ...this.state.query_params,
        }}
        fetchPolicy="no-cache"
      >
        {({ data, error, refetch }) => {
          if (error) {
            console.log('error', error);
            return <>Access denied</>;
          }
          const result = getData(data);
          return (
            <div className="table_container">
              {/* <TableTitle name={`用户管理`} number={result.count} /> */}
              {/* <TableSearch
                search={(e: any) => this.search(e.target.value)}
                options={columns_titles}
                checked_options={this.state.checked_options}
                onCheckAllChange={this.onCheckAllChange}
                onColumnChange={this.onColumnChange}
                outData={() => {}}
                columns={this.state.checked_options}
                fixed={this.state.fixed}
                fixedName={(fixed: any) => {
                  this.setState({ fixed });
                }}
                params={{
                  skip,
                  take: 20,
                  ...this.state.query_params,
                }}
              /> */}
              {/* <AllTable
                bordered={false}
                scroll={{ x: true }}
                onChange={(pagination: any, filters: any, sorter: any) =>
                  this.onTableChange(pagination, filters, sorter, refetch)
                }
                columns={columns}
                loading={data && data['members'] ? false : true}
                dataSource={result.data}
                pagination={{
                  total: result.count,
                  pageSize: 20,
                }}
                checked_options={this.state.checked_options}
                fixed={this.state.fixed}
              /> */}
              {result.data.map((item: any, index: number) => {
                return (
                  <div className={styles.list} key={item.key}>
                    <div className={styles.owner_headimgurl}>
                      <img
                        className="header_img_card"
                        src={item.owner_headimgurl}
                        alt=""
                      />
                    </div>
                    <div className={styles.owner_username}>
                      {item.owner_username}
                      {owner_id === item.owner_id && (
                        <Tag style={{ marginLeft: '12px' }} color="#7CB2B8">
                          管理员
                        </Tag>
                      )}
                    </div>
                    <div className={styles.mobile}>{item.mobile}</div>
                    <div className={styles.created_date}>
                      加入时间 {moment(item.created_date).format('YYYY-MM-DD')}
                    </div>
                    <div className={styles.created_date} onClick={() => { this.props.history.push(`/choose-demand-type?user_id=${item.owner_id}&team_id=${params.id}`) }}>
                      <Button type="primary">创建需求</Button>
                    </div>
                    <ChangeTeamModalForm user_id={item.owner_id} team_id={item.team_id} refetch={refetch} />
                  </div>
                );
              })}
            </div>
          );
        }}
      </MembersComponent>
    );
  }
}

export const Member = withRouter(MemberTable);
