import React from 'react';
import { Button, Icon, Modal, Form, Upload, Popover } from 'antd';

import { UploadTeamCardUriComponent } from '../../apollo';
import { message } from '../../components/message';
import styles from './index.module.css';
import { up_load_life_photo_options } from '../../lib/upload.client';

const { Dragger } = Upload;

class UploadImageModal extends React.Component<any> {
  state = {
    visible: false,
    uri: '',
  };

  handleSubmit = async (send: any, e: any) => {
    e.preventDefault();
    if (!this.state.uri) {
      return message.error(new Error('请先上传名片'));
    }
    try {
      await send({
        variables: {
          id: this.props.id,
          card_uri: this.state.uri,
        },
      });
      message.success('上传成功');
      const { refetch } = this.props;
      refetch();
      this.close();
    } catch (e) {
      message.error(e);
    }
  };

  close = () => {
    const { resetFields } = this.props.form;
    resetFields();
    this.setState({ visible: false, uri: '' });
  };

  render() {
    const { visible, uri } = this.state;
    const { card_uri, oss_card_uri } = this.props;
    return (
      <div style={{ display: 'inline-block', marginRight: '12px' }}>
        {!card_uri ? (
          <Button onClick={() => this.setState({ visible: true })}>
            上传名片
          </Button>
        ) : (
          <div
            style={{
              display: 'inline-block',
              marginRight: '30px',
              color: 'rgb(0, 122, 204)',
            }}
          >
            {/* <span>{card_uri}</span> */}
            <br />
            <Popover
              placement="left"
              content={<img alt="" className={styles.img} src={oss_card_uri} />}
              title={null}
            >
              <span className="hand" onClick={() => this.setState({ visible: true })}>
                更换名片
              </span>
            </Popover>
          </div>
        )}
        <UploadTeamCardUriComponent>
          {(send, { loading }) => {
            return (
              <Modal
                title="上传名片"
                centered
                visible={visible}
                onCancel={() => this.setState({ visible: false, uri: '' })}
                onOk={this.handleSubmit.bind(this, send)}
                confirmLoading={loading}
              >
                <Dragger
                  disabled={!!uri}
                  {...up_load_life_photo_options((uri, file) =>
                    this.setState({
                      // uri: file.name,
                      uri,
                    }),
                  )}
                  accept=".pdf,.jpg,.word,.rar,.zip"
                >
                  <p className="ant-upload-drag-icon">
                    <Icon type="inbox" />
                  </p>
                  <p className="ant-upload-text">点击或者拖动到此处上传</p>
                  <p className="ant-upload-hint">
                    支持文件夹上传，通过文件名和学号匹配，如果学号相同则覆盖原有底片
                  </p>
                </Dragger>
              </Modal>
            );
          }}
        </UploadTeamCardUriComponent>
      </div>
    );
  }
}

export const UploadImageModalForm: any = Form.create({ name: 'create_car' })(
  UploadImageModal,
);
