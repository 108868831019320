import React from 'react';
import { Button, Modal, Form, Radio } from 'antd';

import { ChangeSubscriptionCountComponent } from '../../../apollo';
import { message } from '../../../components/message';

class ChangeSubscriptionCountModal extends React.Component<any> {
  state = {
    visible: false,
    uri: '',
  };

  handleSubmit = (send: any, e: any) => {
    e.preventDefault();
    this.props.form.validateFields(async (err: any, values: any) => {
      if (!err) {
        Modal.confirm({
          title: '您确定要更改可同时进行的需求数目吗？',
          content: '一旦修改，C端也将发生相应变化',
          okText: '确认',
          cancelText: '取消',
          onOk: async () => {
            try {
              await send({
                variables: {
                  ChangeSubscriptionCount: {
                    ...values,
                    team_id: this.props.team_id,
                  },
                },
              });
              message.success('修改成功');
              const { refetch } = this.props;
              refetch();
              this.close();
            } catch (e) {
              message.error(e);
            }
          },
        });
      }
    });
  };

  close = () => {
    const { resetFields } = this.props.form;
    resetFields();
    this.setState({ visible: false, uri: '' });
  };

  render() {
    const { visible } = this.state;
    const { form } = this.props;
    const { getFieldDecorator } = form;
    return (
      <div>
        <Button onClick={() => this.setState({ visible: true })}>更改</Button>
        <Modal
          title="更改"
          centered
          visible={visible}
          onCancel={() => this.setState({ visible: false })}
          footer={null}
        >
          <Form>
            <Form.Item label="请选择更改后的可同时进行的需求数目">
              {getFieldDecorator('count', {
                rules: [{ required: true, message: '请选择!' }],
              })(
                <Radio.Group>
                  <Radio value={1}>一个</Radio>
                  <Radio value={2}>二个</Radio>
                  <Radio value={3}>三个</Radio>
                </Radio.Group>,
              )}
            </Form.Item>
            <p style={{ color: 'rgba(0,0,0,0.3)' }}>
              注：一旦确认修改后，该设计部将立即升级
            </p>
            <Form.Item style={{ textAlign: 'right' }}>
              <Button
                onClick={() => this.setState({ visible: false })}
                style={{ marginRight: '30px' }}
              >
                取消
              </Button>
              <ChangeSubscriptionCountComponent>
                {(send, { loading }) => {
                  return (
                    <Button
                      type="primary"
                      loading={loading}
                      onClick={this.handleSubmit.bind(this, send)}
                    >
                      确定
                    </Button>
                  );
                }}
              </ChangeSubscriptionCountComponent>
            </Form.Item>
          </Form>
        </Modal>
      </div>
    );
  }
}

export const ChangeSubscriptionCountModalForm: any = Form.create({
  name: 'create_car',
})(ChangeSubscriptionCountModal);
