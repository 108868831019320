import React from 'react';
import { Tag } from 'antd';
import moment from 'moment';
import { ChangeDemandTimeModalForm } from './change-demand-time-modal-form';
import { Link } from 'react-router-dom';

export const columns = [
  {
    title: '进度',
    dataIndex: 'status',
    key: 'status',
    search: true,
    fixed: 'left',
    width: 80,
    render: (text: string, record: any) => {
      let color =  'geekblue';
      if (record.status === "等待中") {
        color =  'red';
      }
      if (record.status === "已完成") {
        color =  'green';
      }
      if (record.status === "已取消") {
        color =  'gray';
      }
      return <Tag color={color}>{text}</Tag>;
    },
  },
  {
    title: '名称',
    dataIndex: 'name',
    key: 'name',
    width: 120,
    render: (text: string, record: any) => {
      return <Link to={`/demand-chat?id=${record.key}`}>{text}</Link>;
    },
  },
  {
    title: '类别',
    dataIndex: 'type',
    key: 'type',
    width: 120,
  },
  {
    title: '提交时间',
    dataIndex: 'created_date',
    key: 'created_date',
    width: 120,
    sorter: true,
    render: (text: string, record: any) => moment(text).format('YYYY-MM-DD HH:mm'),
  },
  {
    title: '需求提出人',
    dataIndex: 'owner_username',
    key: 'owner_username',
    width: 120,
  },
  {
    title: '（预计）完成时间',
    dataIndex: 'finish_date',
    key: 'finish_date',
    width: 120,
    render: (text: string, record: any) => moment(text).format('YYYY-MM-DD HH:mm'),
  },
  {
    title: '操作',
    width: 200,
    key: 'action',
    render: (_: string, record: any) => (
      <ChangeDemandTimeModalForm id={record.key} />
    ),
  },
];
