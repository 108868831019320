import React from 'react';
import { Modal } from 'antd';

import styles from './index.module.css';
import { withRouter } from 'react-router';

class PayUriModal extends React.Component<any> {
  state = {
    visible: false,
  };
  render() {
    return (
      <div style={{ display: 'inline-block' }}>
        <span style={{ color: 'rgb(36, 131, 248)' }} className="hand" onClick={() => this.setState({ visible: true })}>查看凭证</span>
        <Modal
          visible={this.state.visible}
          className={`modal-cover modal-cover-body`}
          width={600}
          footer={null}
          title={null}
          closable={true}
          maskClosable={true}
          onCancel={() => this.setState({ visible: false })}
        >
          <img className={styles.pay_uri} src={this.props.pay_uri} alt="" />
        </Modal>
      </div>
    );
  }
}

export default withRouter(PayUriModal);
