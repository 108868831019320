import React from 'react';

import {
  Button,
  Input,
  Icon,
  Row,
  Col,
  Popover,
  Checkbox,
  Modal,
  Spin,
  Alert,
} from 'antd';
// import Highlighter from 'react-highlight-words';
// import SeeXlsx from './SeeXlsx.graphql';
import _ from 'lodash';
import { withApollo } from 'react-apollo';
import { Table } from 'antd';
import { Resizable } from 'react-resizable';
import { dealNumber } from '../../lib';

const CheckboxGroup = Checkbox.Group;

export class BaseTable extends React.Component<any, any> {
  searchInput: any;

  search = _.debounce((value: string) => {
    this.setState({
      query_params: {
        ...this.state.query_params,
        search: value,
      },
    });
  });

  getColumnSearchProps = (dataIndex: any) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }: any) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`请输入关键字进行搜索`}
          value={selectedKeys[0]}
          onChange={e =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => confirm()}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => confirm()}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          搜索
        </Button>
        <Button
          onClick={() => clearFilters()}
          size="small"
          style={{ width: 90 }}
        >
          重置
        </Button>
      </div>
    ),
    filterIcon: (filtered: any) => (
      <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    // onFilter: (value, record) =>
    //   record[dataIndex]
    //     .toString()
    //     .toLowerCase()
    //     .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible: boolean) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    // render: (text, record) => {
    //   if (dataIndex === 'true_name') {
    //     return (
    //       <a href={`/competition/info?user_id=${record.user_id}`}>{text}</a>
    //     );
    //   }
    //   return (
    //     <Highlighter
    //       highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
    //       searchWords={[
    //         this.searchInput &&
    //           this.searchInput.props &&
    //           this.searchInput.props.value,
    //       ]}
    //       autoEscape
    //       textToHighlight={text.toString()}
    //     />
    //   );
    // },
  });

  onTableChange = (
    pagination: any,
    filters: any,
    sorter: any,
    refetch: () => void,
  ) => {
    let order: any = {};
    if (sorter.order === 'ascend') {
      order[sorter.field] = 'ASC';
    } else if (sorter.order === 'descend') {
      order[sorter.field] = 'DESC';
    }
    const options: any = {};
    if (order && Object.keys(order).length > 0) {
      options.order = order;
    }
    const skip = pagination.pageSize * (pagination.current - 1);
    options.skip = skip;
    if (filters && Object.keys(filters).length > 0) {
      Object.keys(filters).forEach(key => {
        if (filters[key] && filters[key].length > 0) {
          options[key] = filters[key];
        } else {
          options[key] = undefined;
        }
      });
    }
    this.setState({
      query_params: {
        ...this.state.query_params,
        ...options,
      },
    });
    // refetch(options);
  };
}

class TableSearchComponent extends React.Component<any, any> {
  state = {
    visible: false,
    download: false,
    uri: '',
  };

  handleVisibleChange = (visible: boolean) => {
    this.setState({ visible });
  };

  // out = async () => {
  //   try {
  //     const result = await this.props.outData({
  //       variables: { ...this.props.params, columns: this.props.columns },
  //     });
  //     this.setState({
  //       download: true,
  //     });
  //     const see = setInterval(async () => {
  //       const { data } = await this.props.client.query({
  //         query: SeeXlsx,
  //         variables: {
  //           token: Object.values(result.data)[0].token,
  //         },
  //         fetchPolicy: 'network-only',
  //         cachePolicy: { query: false, data: false },
  //       });
  //       if (data.seeXlsx.uri) {
  //         clearInterval(see);
  //         this.setState({
  //           uri: data.seeXlsx.uri,
  //         });
  //         window.open(data.seeXlsx.uri);
  //       }
  //     }, 600);
  //   } catch (e) {
  //     message.error(e.message.replace('GraphQL error:', ''));
  //   }
  // };

  content = () => {
    return (
      <div>
        <div
          style={{ borderBottom: '1px solid #E9E9E9', paddingBottom: '6px' }}
        >
          <Checkbox
            indeterminate={
              this.props.checked_options.length > 0 &&
              this.props.checked_options.length !== this.props.options.length
            }
            onChange={this.props.onCheckAllChange}
            checked={
              this.props.options.length === this.props.checked_options.length
            }
          >
            全选
          </Checkbox>
        </div>
        <div className="checkbox_table_group">
          <CheckboxGroup
            options={this.props.options}
            value={this.props.checked_options}
            onChange={this.props.onColumnChange}
          />
        </div>
      </div>
    );
  };
  render() {
    return (
      <Row
        gutter={8}
        className="search_container"
        style={this.props.style || {}}
      >
        <Modal
          visible={this.state.download}
          onCancel={() =>
            this.setState({
              download: false,
              uri: '',
            })
          }
          footer={null}
          title="导出数据"
        >
          <Spin
            tip="导出数据中...，请不要刷新页面或者关闭"
            spinning={!this.state.uri}
          >
            <Alert
              message="如果浏览器没有自动下载，可以手动点击哦"
              description={<a href={this.state.uri}>点击下载</a>}
              type="info"
            />
          </Spin>
        </Modal>
        <Col span={4}>
          <Input
            placeholder="请输入关键字进行搜索"
            onChange={this.props.search}
          />
        </Col>
        <Col span={8}>
          <Popover
            content={this.content()}
            title={null}
            placement="right"
            trigger="click"
            visible={this.state.visible}
            onVisibleChange={this.handleVisibleChange}
          >
            <Button type="primary">选择条目</Button>
          </Popover>
          {/* {this.props.fixedName &&
            (this.props.fixed ? (
              <Button
                style={{ marginLeft: '8px' }}
                type="primary"
                onClick={() => this.props.fixedName('')}
              >
                取消固定
              </Button>
            ) : (
              <Button
                style={{ marginLeft: '8px' }}
                type="primary"
                onClick={() => this.props.fixedName('left')}
              >
                固定姓名
              </Button>
            ))} */}
        </Col>
        <Col span={12} style={{ textAlign: 'right' }}>
          {/* <Button type="primary" onClick={() => this.out()}>
            导出 Excel
          </Button> */}
          {this.props.children}
        </Col>
      </Row>
    );
  }
}

export const TableSearch = withApollo(TableSearchComponent);

const ResizeableTitle = (props: any) => {
  const { onResize, width, ...restProps } = props;

  if (!width) {
    return <th {...restProps} />;
  }

  return (
    <Resizable width={width} height={0} onResize={onResize}>
      <th {...restProps} />
    </Resizable>
  );
};

export class AllTable extends React.Component<any, any> {
  searchInput: any;

  state = {
    columns: this.props.columns,
  };

  components = {
    header: {
      cell: ResizeableTitle,
    },
  };

  componentWillReceiveProps(props: any) {
    this.setState({
      columns: props.columns,
    });
  }

  sizeCache: any = {};

  handleResize = (index: number) => (e: any, { size }: any) => {
    this.setState(({ columns }: any) => {
      const nextColumns = [...columns];
      nextColumns[index] = {
        ...nextColumns[index],
        width: size.width,
      };
      this.sizeCache[index] = size.width;
      return { columns: nextColumns };
    });
  };

  getColumnSearchProps = (dataIndex: any) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }: any) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`请输入关键字进行搜索`}
          value={selectedKeys[0]}
          onChange={e =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => confirm()}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => confirm()}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          搜索
        </Button>
        <Button
          onClick={() => clearFilters()}
          size="small"
          style={{ width: 90 }}
        >
          重置
        </Button>
      </div>
    ),
    filterIcon: (filtered: any) => (
      <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    // onFilter: (value, record) =>
    //   record[dataIndex]
    //     .toString()
    //     .toLowerCase()
    //     .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible: boolean) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    // render: (text, record) => {
    //   if (dataIndex === 'true_name') {
    //     return (
    //       <a href={`/competition/info?user_id=${record.user_id}`}>{text}</a>
    //     );
    //   }
    //   return (
    //     <Highlighter
    //       highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
    //       searchWords={[
    //         this.searchInput &&
    //           this.searchInput.props &&
    //           this.searchInput.props.value,
    //       ]}
    //       autoEscape
    //       textToHighlight={text.toString()}
    //     />
    //   );
    // },
  });

  render() {
    let column_length = 0;
    const columns = this.state.columns
      .filter(({ title }: any) =>
        this.props.checked_options.find(
          (checked_option: any) => checked_option === title,
        ),
      )
      .map((col: any, index: any) => {
        if (col.width) {
          column_length += this.sizeCache[index] || col.width;
        }
        const new_column = {
          ...col,
          onHeaderCell: (column: any) => ({
            width: this.sizeCache[index] || column.width,
            onResize: this.handleResize(index),
          }),
          fixed: col.title === '姓名' && this.props.fixed,
          width: this.sizeCache[index] || col.width,
        };

        if (col.search) {
          return {
            ...new_column,
            ...this.getColumnSearchProps(col.key),
          };
        }
        return new_column;
      });
    return (
      <Table
        bordered
        {...this.props}
        components={this.components}
        columns={columns}
        defaultExpandAllRows={this.props.defaultExpandAllRows}
        scroll={{ x: column_length || 2000 }}
        style={{ minHeight: '900px', position: 'relative' }}
        pagination={{
          ...this.props.pagination,
          // showTotal: total => `当前筛选下的总条目数： ${total} `,
        }}
      />
    );
  }
}

interface TableTitleProps {
  number?: number;
  name: string;
  number_title?: string;
  Right?: any;
}

export class TableTitle extends React.Component<TableTitleProps> {
  render() {
    const { name, number, number_title, Right } = this.props;
    return (
      <>
        <p className="table_title">{name}</p>
        {Right ? (
          Right
        ) : (
          <div className="table_right">
            <p className="table_count">{dealNumber(number || 0)}</p>
            <p>{number_title || '总数'}</p>
          </div>
        )}
      </>
    );
  }
}
