import React from 'react';

import queryString from 'query-string';
import { BaseTable } from '../../components/base-table';
import { InvoicesComponent } from '../../apollo';
import { AllTable } from '../../components/base-table';
import { columns } from './order.columns';
import { withRouter } from 'react-router';
import { BaseLayout } from '../../layout/base-layout';

function getData(data: any) {
  const name = 'invoices';
  const new_data =
    data[name] && data[name].list
      ? data[name].list.map((item: any) => {
          return {
            ...item,
            key: item.id,
            username: item.owner && item.owner.username,
          };
        })
      : [];
  const count = (data[name] && data[name].count) || 0;
  return {
    data: new_data,
    count,
  };
}

const columns_titles = columns().map(({ title }) => title);

const base_titles = columns_titles;

class OrderTable extends BaseTable {
  state = {
    checked_options: base_titles,
    query_params: {},
    fixed: false,
    visible: false,
  };

  onCheckAllChange = (e: any) => {
    this.setState({
      checked_options: e.target.checked ? columns_titles : [],
    });
  };

  onColumnChange = (checkedList: any) => {
    this.setState({
      checked_options: checkedList,
    });
  };

  render() {
    const params = queryString.parse(this.props.location.search);
    const skip = Number(params.skip) || 0;
    return (
      <BaseLayout>
        <InvoicesComponent
          variables={{
            skip,
            take: 20,
          }}
          fetchPolicy="no-cache"
        >
          {({ data, error, refetch }) => {
            if (error) {
              return <>Access denied</>;
            }
            const result = getData(data);
            return (
              <div className="table_container">
                <AllTable
                  bordered={false}
                  scroll={{ x: true }}
                  onChange={(pagination: any, filters: any, sorter: any) =>
                    this.onTableChange(pagination, filters, sorter, refetch)
                  }
                  columns={columns(refetch)}
                  loading={data && data['invoices'] ? false : true}
                  dataSource={result.data}
                  pagination={{
                    total: result.count,
                    pageSize: 20,
                  }}
                  defaultExpandAllRows={true}
                  checked_options={this.state.checked_options}
                  fixed={this.state.fixed}
                />
              </div>
            );
          }}
        </InvoicesComponent>
      </BaseLayout>
    );
  }
}

export const Invoice = withRouter(OrderTable);
