import React from 'react';
import { ApolloProvider } from 'react-apollo';

import './App.css';
import Routers from './router';
import client from './apollo/client';

const App: React.FC = () => {
  console.log("版本1.0.1")
  return (
    <ApolloProvider client={client}>
      <Routers />
    </ApolloProvider>
  );
};

export default App;
