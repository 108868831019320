import { Icon } from 'antd';
import moment from 'moment';
import React from 'react';
import copy from 'copy-to-clipboard'
import { message } from '../../components/message';

function copyData(data: any) {
  copy(
    `
    企业主体名称
    ${data.company_name}

    纳税人识别号
    ${data.identification_number}

    发票金额
    ${data.amount}

    发票类型
    ${data.invoice_type}

    电子邮箱
    ${data.email}`
  )
  message.success('复制成功')
}
export const columns = (reFetch?: any) => [
  {
    title: '企业名称',
    dataIndex: 'company_name',
    key: 'company_name',
    width: 120,
  },
  {
    title: '纳税人识别号',
    dataIndex: 'identification_number',
    key: 'identification_number',
    width: 100,
    sorter: true,
  },
  {
    title: '发票金额',
    dataIndex: 'amount',
    key: 'amount',
    width: 120,
  },
  {
    title: '发票类型',
    dataIndex: 'invoice_type',
    key: 'invoice_type',
    width: 120,
  },
  {
    title: '电子邮箱',
    dataIndex: 'email',
    key: 'email',
    width: 120,
  },
  {
    title: '收票人联系电话',
    dataIndex: 'phone',
    key: 'phone',
    width: 120,
  },
  {
    title: '填写人',
    dataIndex: 'owner',
    key: 'pay_type',
    width: 120,
    render: (item: any) => item.username
  },
  {
    title: '填写时间',
    dataIndex: 'created_at',
    key: 'created_at',
    width: 200,
    render: (created_at: any) => {
      return moment(created_at).format('YYYY-MM-DD hh:ss')
    }
  },
  {
    title: '操作',
    dataIndex: 'action',
    key: 'action',
    width: 300,
    render: (_: any, record: any) => (<span style={{color:'#1A80FB'}} onClick={() => copyData(record)}><Icon type="copy" />复制信息</span>)
  },
];
