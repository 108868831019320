import React from 'react';
import { Button, Icon, Modal, Checkbox } from 'antd';
import { BaseLayout } from '../../layout/base-layout';
import styles from './index.module.css';
import Annotation from "react-image-annotation";
import {
  PointSelector,
} from 'react-image-annotation/lib/selectors'
import { FileCard } from '../demand-chat/index';
import {
  AnnexGroupListComponent,
  CreateAnnotationComponent,
  AnnexAnnotationsComponent,
  FinishAnnexComponent,
} from '../../apollo';
import queryString from 'query-string';
import moment from "moment";
import { message } from '../../components/message';
import Point from './Point';
import Editor from './Editor';
import Overlay from './Overlay';
import Content from './Content';
import { getFileTypeByPath } from '../../lib';
import { Empty } from './Empty';
import { EmptyPoint } from './EmptyPoint';

export const file_types = ["jpg", "png", "jpeg"]

export function getFileType(filePath: string) {
  var startIndex = filePath.lastIndexOf(".");
  var endIndex = filePath.lastIndexOf("?");
  if (startIndex !== -1)
    return filePath.substring(startIndex + 1, endIndex !== -1 ? endIndex : filePath.length).toLowerCase();
  else return "";
}

export function getFirstPng(items: any[]) {
  const result = items.find((item) => file_types.includes(getFileType(item.path)))
  return result
}

class Photo extends React.Component<any> {
  componentDidMount() {
    // const instance = new fabric.Canvas('c')
  }

  state = {
    annotations: [],
    checkedValues: [],
    annotation: {},
    uri: "",
    path: "",
    annex_id: ""
  }

  onChange = (annotation: any) => {
    this.setState({ annotation })
  }

  onSubmit = async (annotation: any, send: any, refetch: any, annexs: any) => {
    const { geometry, data } = annotation

    this.setState({
      annotation: {},
      annotations: this.state.annotations.concat({
        // @ts-ignore
        geometry,
        data: {
          ...data,
          id: Math.random(),
          // date: new Date(),
        }
      })
    })
    try {
      const first = getFirstPng(annexs)
      await send({
        variables: {
          CreateAnnotation: {
            id: annotation.id,
            annex_id: this.state.annex_id || first.id,
            text: data.text,
            geometry: JSON.stringify(geometry),
            data: JSON.stringify(data),
          },
        },
      });
      refetch();
    } catch (e) {
      message.error(e);
    }
  }

  onCheckChange(checkedValues: any) {
    // console.log('checked = ', checkedValues);
    this.setState({
      checkedValues
    })
  }

  sure(values: any[], send: any, refetch: any) {
    if (this.state.checkedValues.length !== values.filter((annotation) => !annotation.is_finish).length) {
      return message.error(new Error('请全部勾选后点击完成'))
    }
    if (values.length > 0) {
      Modal.confirm({
        title: '再次确认',
        content: '确定全部修改完成？',
        okText: "确定",
        cancelText: "取消",
        async onOk() {
          await send({
            variables: {
              FinishAnnex: {
                id: values[0].annex_id,
              }
            },
          });
          refetch();
        },
        onCancel() {
          // console.log('Cancel');
        },
      });
    }
  }

  render() {
    const search: any = queryString.parse(window.location.search);
    // const session = JSON.parse(String(localStorage.getItem("session")))
    return (
      <AnnexGroupListComponent variables={{ id: search.id, group_id: search.group_id }}>
        {({ data, error, loading, refetch }) => {
          if (error || loading || !data) {
            return '';
          }
          const first = getFirstPng(data.annexGroupList[0].items)
          return (
            <AnnexAnnotationsComponent variables={{ annex_id: this.state.annex_id || first.id }}>
              {({ data: annotationData, error, loading, refetch: refetchSecond }) => {
                if (error || loading || !annotationData) {
                  return '';
                }
                // const result = this.state.annotations;
                const result = annotationData.annexAnnotations;
                const annotations = result.map((item, index) => {
                  return {
                    index: result.length - index,
                    ...item,
                    geometry: JSON.parse(item.geometry),
                    data: {
                      ...JSON.parse(item.data),
                      id: item.id
                    },
                  }
                })
                const is_image = ["jpg", "jpeg", "png"].includes(getFileTypeByPath(this.state.path || first.path))
                return (<BaseLayout>
                  <div className="go_back" onClick={() => this.props.history.goBack()}>
                    <Icon type="left-circle" />&nbsp;返回上层
                  </div>
                  <div className={styles.list_photo}>
                    {data.annexGroupList[0].items.map((item) => <FileCard file_old_name={item.file_old_name} key={item.id} uri={item.uri} is_new={item.is_new} onClick={() => this.setState({
                      annex_id: item.id,
                      path: item.path,
                      uri: item.uri
                    })} />)}
                  </div>
                  {is_image && <div style={{ textAlign: "right" }}>
                    <FinishAnnexComponent>
                      {(send) => {
                        return <Button type="primary" onClick={() => this.sure(annotations, send, () => { refetch(); refetchSecond() })}>确认修改完成</Button>
                      }}
                    </FinishAnnexComponent>
                  </div>}
                  <div className={styles.leftPhoto}>
                    <CreateAnnotationComponent>
                      {(send) => {
                        return is_image ? <Annotation
                          src={this.state.uri || first.uri}
                          alt='无法显示'

                          annotations={annotations}

                          type={PointSelector.TYPE}
                          value={this.state.annotation}
                          onChange={this.onChange}
                          renderSelector={Point}
                          renderContent={({ key, annotation }: any) => (
                            <Content
                              key={key}
                              annotation={annotation}
                            />
                          )}
                          // renderHighlight={Point}
                          renderHighlight={({ key, annotation, active }: any) => {
                            return (
                              <Point
                                key={key}
                                annotation={annotation}
                                active={active}
                              />
                            )
                          }}
                          disableSelector={true}
                          disableEditor={true}
                          renderEditor={Editor}
                          renderOverlay={({ type, annotation }: any) => {
                            return <Overlay>
                              点击进行批注
                            </Overlay>
                          }}
                          onSubmit={(annotation: any) => this.onSubmit(annotation, send, refetch, data.annexGroupList[0].items)}
                        /> : <Empty uri={this.state.uri || first.uri} />
                      }}
                    </CreateAnnotationComponent>
                    <div className={styles.list_container}>
                      {is_image ? <Checkbox.Group style={{ width: '100%' }} onChange={this.onCheckChange.bind(this)}>
                        {annotations.map((annotation: any, index) => {
                          return <div key={annotation.id} className={styles.list_card}>
                            <div>
                              <div className={styles.user_name}>
                                <img
                                  className="header_img_card"
                                  src={annotation.owner.headimgurl || ''}
                                  alt=""
                                />
                                <div>{annotation.owner.username}
                                  <br />{moment(annotation.created_date).format('YYYY-MM-DD HH:mm:ss')}
                                </div>
                              </div>
                              <div className={styles.text}>
                              <span style={{ color: "rgb(116, 169, 175)" }}>{annotations.length - index}.</span> {annotation.text}
                              </div>
                            </div>
                            {!annotation.is_finish && <Checkbox value={annotation.id} className={styles.checkbox}>
                            </Checkbox>}
                          </div>
                        })}
                      </Checkbox.Group> : <EmptyPoint />}
                    </div>
                  </div>
                </BaseLayout >)
              }}
            </AnnexAnnotationsComponent>)
        }}
      </AnnexGroupListComponent>
    );
  }
}

export default Photo;
