import React from 'react';

import queryString from 'query-string';
import {
  BaseTable,
  TableTitle,
} from '../../components/base-table';
import { IndependentsComponent } from '../../apollo';
import { AllTable } from '../../components/base-table';
import { BaseLayout } from '../../layout/base-layout';
import { columns } from './independent.columns';
import { IndependentModal } from './independent-modal';
import { Link } from 'react-router-dom';

function getData(data: any) {
  const name = 'independents';
  const new_data =
    data[name] && data[name].list
      ? data[name].list.map((item: any) => {
        return {
          ...item,
          key: item.id,
          status: item.status,
          name: item.name,
          created_date: item.created_date,
          end_date: item.end_date,
          team_name: item.team.name,
          owner_username: item.owner.username,
        };
      })
      : [];
  const count = (data[name] && data[name].count) || 0;
  return {
    data: new_data,
    count,
  };
}

const columns_titles = columns.map(({ title }) => title);

const base_titles = columns_titles;

export default class Independent extends BaseTable {
  state = {
    checked_options: base_titles,
    query_params: {},
    fixed: false,
  };

  onCheckAllChange = (e: any) => {
    this.setState({
      checked_options: e.target.checked ? columns_titles : [],
    });
  };

  onColumnChange = (checkedList: any) => {
    this.setState({
      checked_options: checkedList,
    });
  };

  render() {
    const params = queryString.parse(this.props.location.search);
    const skip = Number(params.skip) || 0;
    return (
      <BaseLayout>
        <IndependentsComponent
          variables={{
            skip,
            take: 20,
            ...this.state.query_params,
          }}
          fetchPolicy="no-cache"
        >
          {({ data, error, refetch }) => {
            if (error) {
              console.log('error', error);
              return <>Access denied</>;
            }
            const result = getData(data);
            const new_columns = columns.map(item => {
              if (item.key === 'action') {
                return {
                  ...item,
                  render: (_: string, record: any) => (
                    <IndependentModal record={record} />
                  ),
                };
              }
              return item;
            });
            return (
              <div className="table_container">
                <TableTitle
                  name={`独立需求管理(${result.count})`}
                  Right={
                    <Link
                      style={{ position: 'absolute', right: '8px', top: '12px' }}
                      to={'/demand'}
                    >
                      查看订阅需求
                    </Link>
                  }
                />
                <br />
                {/* <TableSearch
                  search={(e: any) => this.search(e.target.value)}
                  options={columns_titles}
                  checked_options={this.state.checked_options}
                  onCheckAllChange={this.onCheckAllChange}
                  onColumnChange={this.onColumnChange}
                  outData={() => {}}
                  columns={this.state.checked_options}
                  fixed={this.state.fixed}
                  fixedName={(fixed: any) => {
                    this.setState({ fixed });
                  }}
                  params={{
                    skip,
                    take: 20,
                    ...this.state.query_params,
                  }}
                /> */}
                <AllTable
                  scroll={{ x: true }}
                  onChange={(pagination: any, filters: any, sorter: any) =>
                    this.onTableChange(pagination, filters, sorter, refetch)
                  }
                  columns={new_columns}
                  loading={data && data['independents'] ? false : true}
                  dataSource={result.data}
                  pagination={{
                    total: result.count,
                    pageSize: 20,
                  }}
                  checked_options={this.state.checked_options}
                  fixed={this.state.fixed}
                />
              </div>
            );
          }}
        </IndependentsComponent>
      </BaseLayout>
    );
  }
}
