import React from 'react';
import styles from './index.module.css';
import { Tag, Icon, Button, Modal, Input } from 'antd';
import { GetDemandByAdminComponent, CancelDemandComponent } from '../../apollo';
import queryString from 'query-string';
import moment from 'moment';
import { BaseLayout } from '../../layout/base-layout';
import { ChangeDemandTimeModalForm } from '../company/demand/change-demand-time-modal-form';
import { FinishDemandModalForm } from './finish-demand-modal-form';
import { message } from '../../components/message';
import { getTrueName } from '../../lib';

interface FileCardProps {
  uri: string;
  name: string;
}
export class FileCard extends React.Component<FileCardProps> {
  render() {
    const { uri, name } = this.props;
    return (
      <a
        target="view_window"
        href={uri}
        style={{ color: '#7CB2B8', marginRight: '28px' }}
      >
        <Icon type="paper-clip" /> {getTrueName(name).replace('files/', '')}
      </a>
    );
  }
}

interface RightDetailProps {
  title: string;
  content?: string | null;
}

export class RightDetail extends React.Component<RightDetailProps> {
  render() {
    const { title, content } = this.props;
    if (!content) {
      return null;
    }
    return (
      <>
        <div className={styles.detail_title}>{title}</div>
        {/* <p className={styles.detail_content}>{content} </p> */}
        {/* <textarea
          name="note"
          disabled
          className={`${styles.note} ${styles.detail_content}`}
        >
          {content}
        </textarea> */}
        <Input.TextArea
          name="note"
          autosize={true}
          disabled
          className={`${styles.note} ${styles.detail_content}`}
        >
          {content}
        </Input.TextArea>
      </>
    );
  }
}

class Detail extends React.Component {
  render() {
    const search: any = queryString.parse(window.location.search);
    return (
      <BaseLayout
        ContentStyle={{ background: 'rgba(240, 242, 245, 1)', padding: '0px' }}
      >
        <GetDemandByAdminComponent variables={{ id: search.id }}>
          {({ data, error, loading, refetch }) => {
            if (error || loading || !data) {
              return '';
            }
            const status = data.getDemandByAdmin.status;
            let color = 'geekblue';
            if (status === '等待中') {
              color = 'red';
            }
            if (status === '已完成') {
              color = 'green';
            }
            if (status === '已取消') {
              color = 'gray';
            }
            const demand = data.getDemandByAdmin;
            return (
              <div className={styles.container}>
                <div className={styles.left}>
                  <div className={styles.left_first}>
                    <div className={styles.left_title}>
                      {demand.name}{' '}
                      <Tag style={{ marginLeft: '12px' }} color={color}>
                        {status}
                      </Tag>
                    </div>
                    <div className={styles.left_first_middle}>
                      <div>
                        <div className={styles.user_name}>
                          <img
                            className="header_img_card"
                            src={demand.owner.headimgurl || ''}
                            alt=""
                          />
                          {demand.owner.username}
                          <span style={{ marginLeft: '120px' }}>
                            {demand.team.name}
                          </span>
                        </div>
                        <div style={{ marginTop: '12px' }}>
                          <span>
                            <Icon type="profile" />
                            &nbsp;&nbsp; 需求类别：{demand.project.title}
                          </span>
                          <span style={{ marginLeft: '120px' }}>
                            <Icon type="dashboard" />
                            &nbsp;&nbsp;提交时间:{' '}
                            {moment(demand.created_date).format('YYYY-MM-DD')}
                          </span>
                          {demand.finish_date && (
                            <span style={{ marginLeft: '120px' }}>
                              <Icon type="hourglass" />
                              &nbsp;&nbsp; 预计完成时间:{' '}
                              {moment(demand.finish_date).format('YYYY-MM-DD HH:mm')}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={`${styles.top_button}`}>
                    {status === '进行中' ||
                      (status === '等待中' && (
                        <ChangeDemandTimeModalForm
                          id={demand.id}
                          refetch={refetch}
                          finish_date={demand.finish_date}
                        />
                      ))}
                    <br />
                    {/* {status === '进行中' && ( */}
                      <FinishDemandModalForm id={demand.id} refetch={refetch} />
                    {/* )} */}
                    {status === '等待中' && (
                      <CancelDemandComponent>
                        {(send) => {
                          return (
                            <Button
                              onClick={() => {
                                Modal.confirm({
                                  title: '您确定取消需求吗？',
                                  content: '需求一旦取消则不可以恢复',
                                  okText: '确认取消',
                                  cancelText: '稍后再说',
                                  onOk: async () => {
                                    try {
                                      await send({
                                        variables: { id: demand.id },
                                      });
                                      message.success('修改成功');
                                      refetch();
                                    } catch (e) {
                                      message.error(e);
                                    }
                                  },
                                });
                              }}
                            >
                              取消需求
                            </Button>
                          );
                        }}
                      </CancelDemandComponent>
                    )}
                  </div>
                </div>
                <div>
                  <div className={styles.right}>
                    {demand.logo_name ? (
                      <RightDetail
                        title="Logo 需要体现的寓意，包含的要素等"
                        content={demand.description}
                      />
                    ) : (
                      <RightDetail
                        title="需求描述"
                        content={demand.description}
                      />
                    )}
                    {demand.description_files &&
                      demand.description_files.map(({ name, uri }) => {
                        return <FileCard key={uri} name={name} uri={uri} />;
                      })}
                    {demand.description_files && (
                      <>
                        <br />
                        <br />
                      </>
                    )}
                    {demand.client && (
                      <RightDetail
                        title="用户群体"
                        content={demand.client}
                      />
                    )}
                    {demand.client_desc && (
                      <RightDetail
                        title="用户群体描述"
                        content={demand.client_desc}
                      />
                    )}
                    {demand.logo_name && (
                      <RightDetail
                        title="Logo 对应的中英文品牌名称"
                        content={demand.logo_name}
                      />
                    )}
                    {demand.logo_type && (
                      <RightDetail
                        title="Logo 类型"
                        content={demand.logo_type}
                      />
                    )}
                    <RightDetail
                      title="视觉风格描述"
                      content={demand.ui_description}
                    />
                    {demand.ui_description_remark && (
                      <RightDetail
                        title="设计风格备注"
                        content={demand.ui_description_remark}
                      />
                    )}
                    {demand.ui_description_files &&
                      demand.ui_description_files.map(({ name, uri }) => {
                        return <FileCard key={uri} name={name} uri={uri} />;
                      })}
                    {demand.ui_description_files && (
                      <>
                        <br />
                        <br />
                      </>
                    )}
                    <RightDetail
                      title="色彩偏好"
                      content={demand.color_description || '无'}
                    />
                    <RightDetail
                      title="尺寸规格"
                      content={demand.size_description || '无特殊要求'}
                    />
                    {/* <RightDetail
                      title="源文件格式要求"
                      content={demand.format || '无特殊要求'}
                    /> */}
                    <RightDetail
                      title="源文件格式要求"
                      content={demand.original_file_format || '无特殊要求'}
                    />
                    <RightDetail
                      title="图片格式要求"
                      content={demand.file_format || '无特殊要求'}
                    />
                    <RightDetail
                      title="其他要求说明与备注"
                      content={demand.remark || '无'}
                    />
                  </div>
                </div>
              </div>
            );
          }}
        </GetDemandByAdminComponent>
      </BaseLayout>
    );
  }
}

export default Detail;
