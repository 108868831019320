import React from 'react'
import styled from 'styled-components'
import styles from './index.module.css';
import moment from 'moment';

const Container = styled.div`
  background: white;
  border-radius: 2px;
  box-shadow:
    0px 1px 5px 0px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14),
    0px 3px 1px -2px rgba(0, 0, 0, 0.12);
  padding: 12px 16px;
  margin-top: 8px;
  margin-left: 8px;
`

function Content(props: any) {
  const { geometry, owner, created_date, data } = props.annotation
  if (!geometry) return null
  return (
    <Container
      style={{
        position: 'absolute',
        left: `${geometry.x}%`,
        top: `${geometry.y + geometry.height}%`,
        ...props.style
      }}
      className={props.className}
    // geometry={geometry}
    >
      <div className={styles.user_name}>
        <img
          className="header_img_card"
          src={owner.headimgurl || ''}
          alt=""
        />
        <div>{owner.username}
          <br />{moment(created_date).format('YYYY-MM-DD HH:mm:ss')}
        </div>
      </div>
      {data && data.text}
    </Container>
  )
}

Content.defaultProps = {
  style: {},
  className: ''
}

export default Content
