import moment from 'moment';

export const columns = [
  // {
  //   title: '进度',
  //   dataIndex: 'status',
  //   key: 'status',
  //   search: true,
  //   fixed: 'left',
  //   width: 80,
  //   render: (text: string, record: any) => {
  //     let color = text.length > 5 ? 'geekblue' : 'green';
  //     return <Tag color={color}>{text}</Tag>;
  //   },
  // },
  {
    title: '名称',
    dataIndex: 'name',
    key: 'name',
    width: 120,
  },
  {
    title: '类别',
    dataIndex: 'type',
    key: 'type',
    width: 120,
  },
  {
    title: '提交时间',
    dataIndex: 'created_date',
    key: 'created_date',
    width: 120,
    sorter: true,
    render: (text: string, record: any) => moment(text).format('YYYY-MM-DD'),
  },
  {
    title: '需求提出人',
    dataIndex: 'owner_username',
    key: 'owner_username',
    width: 120,
  },
  {
    title: '（预计）完成时间',
    dataIndex: 'end_date',
    key: 'end_date',
    width: 120,
  },
];
