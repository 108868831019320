import { TeamModal } from './team-modal'
import React from 'react';
export const columns = (reFetch?: any) => [
  {
    title: '设计师姓名',
    dataIndex: 'username',
    key: 'username',
    // search: true,
    // sorter: true,
    fixed: 'left',
    width: 200,
  },
  {
    title: '手机号',
    dataIndex: 'mobile',
    key: 'mobile',
    width: 200,
  },
  {
    title: '接单数',
    dataIndex: 'order_count',
    key: 'order_count',
    width: 120,
  },
  {
    title: '总收入',
    dataIndex: 'amount',
    key: 'amount',
    width: 120,
    sorter: true,
  },
  {
    title: '余额',
    dataIndex: 'balance',
    key: 'balance',
    width: 120,
    sorter: true,
  },
  {
    title: '客户数',
    dataIndex: 'services_customs_count',
    key: 'services_customs_count',
    width: 120,
    render: (_: any, item: any) => {
      console.log(item)
      return (<TeamModal data={item.services_customs_count} teams={item.services_customs_list} />)
    }
    // sorter: true,
  },
  {
    title: '进行中需求数',
    dataIndex: 'demands_progress_count',
    key: 'demands_progress_count',
    width: 120,
    // sorter: true,
  },
  {
    title: '需求数',
    dataIndex: 'demands_count',
    key: 'demands_count',
    width: 120,
    // sorter: true,
  },
  // {
  //   title: '操作',
  //   width: 200,
  //   key: 'action',
  //   render: (_: string, record: any) => (
  //     <>
  //       <Button>
  //         <Link to={`/choose-type?team_id=${record.key}`}>+ 新增需求</Link>
  //       </Button>
  //     </>
  //   ),
  // },
];
