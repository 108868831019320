import React from 'react';
import empty_point from './image_point.png';
import styles from './empty-point.module.css';


export class EmptyPoint extends React.Component {
    render() {
        return (
            <div className={styles.container}>
                <img className={styles.container_img} src={empty_point} alt="empty" />
                <br />
                <br />
                <p>该文件类型暂不支持反馈标注</p>
            </div>
        );
    }
}
