import React from 'react';

import queryString from 'query-string';
import {
  BaseTable,
} from '../../../components/base-table';
import { DemandsComponent } from '../../../apollo';
import { AllTable } from '../../../components/base-table';
// import { BaseLayout } from '../../../layout/base-layout';
import { columns } from './demand.columns';
import { withRouter } from 'react-router';
import { ChangeDemandTimeModalForm } from './change-demand-time-modal-form';

function getData(data: any) {
  const name = 'demands';
  const new_data =
    data[name] && data[name].list
      ? data[name].list.map((item: any) => {
        return {
          ...item,
          key: item.id,
          status: item.status,
          name: item.name,
          type: item.project.title,
          created_date: item.created_date,
          end_date: item.end_date,
          owner_username: item.owner.username,
        };
      })
      : [];
  const count = (data[name] && data[name].count) || 0;
  return {
    data: new_data,
    count,
  };
}

const columns_titles = columns.map(({ title }) => title);

const base_titles = columns_titles;

class DemandTable extends BaseTable {
  state = {
    checked_options: base_titles,
    query_params: {},
    fixed: false,
  };

  onCheckAllChange = (e: any) => {
    this.setState({
      checked_options: e.target.checked ? columns_titles : [],
    });
  };

  onColumnChange = (checkedList: any) => {
    this.setState({
      checked_options: checkedList,
    });
  };

  render() {
    const params = queryString.parse(this.props.location.search);
    const skip = Number(params.skip) || 0;
    return (
      <DemandsComponent
        variables={{
          skip,
          take: 20,
          team_id: String(params.id),
          ...this.state.query_params,
        }}
        fetchPolicy="no-cache"
      >
        {({ data, error, refetch }) => {
          if (error) {
            console.log('error', error);
            return <>Access denied</>;
          }
          const result = getData(data);
          const new_columns = columns.map(item => {
            if (item.key === 'action') {
              return {
                ...item,
                render: (_: string, record: any) => (
                  <ChangeDemandTimeModalForm
                    id={record.id}
                    refetch={refetch}
                    finish_date={record.finish_date}
                  />
                ),
              };
            }
            return item;
          });
          return (
            <div className="table_container">
              {/* <TableTitle name={`用户管理`} number={result.count} /> */}
              {/* <TableSearch
                search={(e: any) => this.search(e.target.value)}
                options={columns_titles}
                checked_options={this.state.checked_options}
                onCheckAllChange={this.onCheckAllChange}
                onColumnChange={this.onColumnChange}
                outData={() => {}}
                columns={this.state.checked_options}
                fixed={this.state.fixed}
                fixedName={(fixed: any) => {
                  this.setState({ fixed });
                }}
                params={{
                  skip,
                  take: 20,
                  ...this.state.query_params,
                }}
              /> */}
              <AllTable
                bordered={false}
                scroll={{ x: true }}
                onChange={(pagination: any, filters: any, sorter: any) =>
                  this.onTableChange(pagination, filters, sorter, refetch)
                }
                columns={new_columns}
                loading={data && data['demands'] ? false : true}
                dataSource={result.data}
                pagination={{
                  total: result.count,
                  pageSize: 20,
                }}
                checked_options={this.state.checked_options}
                fixed={this.state.fixed}
              />
            </div>
          );
        }}
      </DemandsComponent>
    );
  }
}

export const Demand = withRouter(DemandTable);
