import { Modal, List, Button } from 'antd'
import React from 'react'
import { Link } from 'react-router-dom';
interface teamModaProps {
  data: number
  teams: Array<{ name: string, id: string, plan: string }>
}

export class TeamModal extends React.Component<teamModaProps> {
  state = {
    visible: false
  }
  render() {
    const { visible } = this.state
    const { teams, data } = this.props
    return (
      <div>
        {data === 0 ? <span>{data}</span> : <span style={{ "color": "#3d9eff" }} onClick={() => this.setState({ visible: true })}>{data}</span>}
        <Modal title='服务用户列表' visible={visible} onCancel={() => this.setState({ visible: false })}
          footer={[
            <Button key={'button'} type="primary" onClick={() => this.setState({ visible: false })}>
              确定
            </Button>,
          ]}
        >
          <List
            itemLayout="horizontal"
            dataSource={teams}
            renderItem={(item) => (
              <List.Item key={item.id} extra={<div>{item.plan}</div>}>
                <List.Item.Meta
                  title={<Link to={`/company?id=${item.id}`}><span style={{ "color": "#3d9eff" }} >{item.name}</span></Link>}

                />
              </List.Item>
            )}
          />
        </Modal>
      </div>
    )
  }

}