import React from 'react';
import { Modal, Input } from 'antd';

import styles from './index.module.css';
import { NotPassOrderComponent } from '../../../../apollo';
import { withRouter } from 'react-router';
import { message } from '../../../../components/message';

const { TextArea } = Input;

class NotPassOrderModal extends React.Component<any> {
  state = {
    visible: false,
    reason: '',
  };
  render() {
    return (
      <div style={{ display: 'inline-block' }}>
        <span
          style={{ color: 'rgb(36, 131, 248)' }}
          className="hand"
          onClick={() => this.setState({ visible: true })}
        >
          不通过
        </span>
        <NotPassOrderComponent>
          {send => {
            return (
              <Modal
                visible={this.state.visible}
                // className={`modal-cover modal-cover-body`}
                width={600}
                // footer={null}
                title={null}
                closable={true}
                okText="确定"
                cancelText="取消"
                maskClosable={true}
                onCancel={() => this.setState({ visible: false })}
                onOk={async () => {
                  if (!this.state.reason) {
                    return message.error(new Error('请输入原因'));
                  }
                  await send({
                    variables: {
                      NotPassOrder: {
                        order_id: this.props.id,
                        reason: this.state.reason,
                      },
                    },
                  });
                  message.success('操作成功');
                  if (this.props.reFetch) {
                    this.props.reFetch();
                  }
                  this.setState({
                    visible: false,
                  });
                }}
              >
                <div className={styles.modal_container}>
                  <div style={{ textAlign: 'left' }}>
                    <br />
                    <span>请在下方填写审核不通过的原因</span>
                    <br />
                    <br />
                    <span style={{ color: 'red' }}>*</span>原因
                    <br />
                    <br />
                    <TextArea
                      rows={4}
                      className="king_normal_text_area"
                      placeholder="请输⼊审核不通过的原因 (不多于30字)"
                      onChange={e => this.setState({ reason: e.target.value })}
                      maxLength={30}
                    />
                  </div>
                  {/* <Button
                    className={`linear-gradient-gray ${styles.sure} normal_button`}
                    onClick={async () => {
                      if (!this.state.reason) {
                        return message.error(new Error('请输入原因'));
                      }
                      await send({
                        variables: {
                          NotPassOrder: {
                            order_id: this.props.id,
                            reason: this.state.reason,
                          },
                        },
                      });
                      message.success('操作成功');
                      if (this.props.reFetch) {
                        this.props.reFetch();
                      }
                      this.setState({
                        visible: false,
                      });
                    }}
                    type="primary"
                  >
                    确定
                  </Button> */}
                </div>
              </Modal>
            );
          }}
        </NotPassOrderComponent>
      </div>
    );
  }
}

export default withRouter(NotPassOrderModal);
