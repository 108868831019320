import client from '../apollo/client';
import { RequestUploadOssFileDocument } from '../apollo';
import { message } from 'antd';

export async function signFile(file_name: string) {
  const { data } = await client.query({
    query: RequestUploadOssFileDocument,
    variables: {
      file_name,
    },
    fetchPolicy: 'network-only',
  });
  return data;
}

export async function upload(file: any) {
  const data = await signFile(file.name);
  const body = new FormData();
  body.append('key', data.requestUploadOSSFile.key);
  body.append('policy', data.requestUploadOSSFile.policy);
  body.append('OSSAccessKeyId', data.requestUploadOSSFile.access_key_id);
  body.append('signature', data.requestUploadOSSFile.signature);
  body.append('callback', data.requestUploadOSSFile.callback);
  body.append('success_action_status', '200');
  body.append('file', file);
  // const uploadResponse =
  await fetch(data.requestUploadOSSFile.host, {
    method: 'POST',
    body,
  });
  // const { data: fileData } = await uploadResponse.json();
  // await uploadResponse.json();
  return data.requestUploadOSSFile.key;
}

export async function uploadWithProcess(file: any, callback: ({ percent }: { percent: number }) => void) {
  const data = await signFile(file.name);
  const body = new FormData();
  body.append('key', data.requestUploadOSSFile.key);
  body.append('policy', data.requestUploadOSSFile.policy);
  body.append('OSSAccessKeyId', data.requestUploadOSSFile.access_key_id);
  body.append('signature', data.requestUploadOSSFile.signature);
  body.append('callback', data.requestUploadOSSFile.callback);
  body.append('success_action_status', '200');
  body.append('file', file);
  //创建xhr对象
  await new Promise((resolve, reject) => {
    var xhr = new XMLHttpRequest();
    xhr.open('POST', data.requestUploadOSSFile.host, true);
    xhr.onload = resolve; //请求完成
    xhr.onerror = reject; //请求失败
    xhr.upload.onprogress = (data) => {
      const per = data.loaded / data.total * 100
      callback({ percent: per })
    }//上传进度调用方法实现
    xhr.send(body);
  })
  return data.requestUploadOSSFile.key;
}
const listType: any = `picture`;

export const up_load_life_photo_options = (callback: (uri: string, file: any) => void) => {
  return {
    name: 'file',
    listType,
    onChange(info: any) {
      if (info.file.status !== 'uploading') {
        // console.log(info.file);
      }
      if (info.file.status === 'done') {
        message.success(`${info.file.name} 上传成功`);
      } else if (info.file.status === 'error') {
        message.error(new Error(`${info.file.name} 上传失败`));
      }
    },
    customRequest: async ({ file, onSuccess, onProgress }: any) => {
      const insurance_book_uri = await uploadWithProcess(file, onProgress);
      callback(insurance_book_uri, file);
      onSuccess();
      return {
        abort() { },
      };
    },
  };
};
