import React from 'react';
import styles from './index.module.css';
import { Icon } from 'antd';
import { GetIndependentByAdminComponent } from '../../apollo';
import queryString from 'query-string';
import moment from 'moment';
import { BaseLayout } from '../../layout/base-layout';

interface RightDetailProps {
  title: string;
  content?: string | null;
}

export class RightDetail extends React.Component<RightDetailProps> {
  render() {
    const { title, content } = this.props;
    return (
      <>
        <div className={styles.detail_title}>{title}</div>
        <p className={styles.detail_content}>{content} </p>
      </>
    );
  }
}

class Detail extends React.Component {
  render() {
    const search: any = queryString.parse(window.location.search);
    return (
      <BaseLayout
        ContentStyle={{ background: 'rgba(240, 242, 245, 1)', padding: '0px' }}
      >
        <GetIndependentByAdminComponent variables={{ id: search.id }}>
          {({ data, error, loading, refetch }) => {
            if (error || loading || !data) {
              return '';
            }
            const independent = data.getIndependentByAdmin;
            return (
              <div className={styles.container}>
                <div className={styles.left}>
                  <div className={styles.left_first}>
                    <div className={styles.left_title}>{independent.name} </div>
                    <div className={styles.left_first_middle}>
                      <div>
                        <div className={styles.user_name}>
                          <img
                            className="header_img_card"
                            src={independent.owner.headimgurl || ''}
                            alt=""
                          />
                          {independent.owner.username}
                          <span style={{ marginLeft: '120px' }}>
                            {independent.team.name}
                          </span>
                        </div>
                        <div style={{ marginTop: '12px' }}>
                          <span style={{ marginLeft: '120px' }}>
                            <Icon type="dashboard" />
                            &nbsp;&nbsp;提交时间:{' '}
                            {moment(independent.created_date).format(
                              'YYYY-MM-DD',
                            )}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div className={styles.right}>
                    <RightDetail
                      title="需求描述"
                      content={independent.description}
                    />
                  </div>
                </div>
              </div>
            );
          }}
        </GetIndependentByAdminComponent>
      </BaseLayout>
    );
  }
}

export default Detail;
