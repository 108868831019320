import React from 'react';
import { Button, Modal, Form, Input } from 'antd';

import { ChangeTeamNameComponent, GetTeamOldNamesByIdComponent } from '../../apollo';
import { message } from '../../components/message';
import moment from "moment";

class ChangeTeamNameModal extends React.Component<any> {
  state = {
    visible: false,
    new_name: '',
  };

  handleSubmit = (send: any, refetch: any, e: any) => {
    e.preventDefault();
    this.props.form.validateFields(async (err: any, values: any) => {
      if (!err) {
        Modal.confirm({
          title: '您确定要更改团队名称吗？',
          content: '一旦修改，C端也将发生相应变化',
          okText: '确认',
          cancelText: '取消',
          onOk: async () => {
            try {
              await send({
                variables: {
                  new_name: values.new_name,
                  team_id: this.props.id,
                },
              });
              message.success('修改成功');
              this.props.refetch();
              refetch();
              this.close();
            } catch (e) {
              message.error(e);
            }
          },
        });
      }
    });
  };

  close = () => {
    const { resetFields } = this.props.form;
    resetFields();
    this.setState({ visible: false, uri: '' });
  };

  render() {
    const { visible } = this.state;
    const { form } = this.props;
    const { getFieldDecorator } = form;
    return (
      <div style={{ display: "inline-block", marginRight: "8px" }}>
        <Button onClick={() => this.setState({ visible: true })}>
          修改名称
        </Button>
        <Modal
          title="更改"
          centered
          visible={visible}
          onCancel={() => this.setState({ visible: false })}
          footer={null}
        >
          <GetTeamOldNamesByIdComponent variables={{ id: this.props.id }}>
            {({ data, loading, error, refetch }) => {
              if (loading || error || !data) {
                return <div></div>
              }
              return <Form>
                <p>
                  当前团队名称 {data.getTeamById.name}
                </p>
                <Form.Item label="请输入新的名称">
                  {getFieldDecorator('new_name', {
                    rules: [{ required: true, message: '请输入新的名称!' }],
                  })(<Input />)}
                </Form.Item>
                <p style={{ color: 'rgba(0,0,0,0.3)' }}>
                  注：一旦确认修改后，前端会立刻变化
                </p>
                {data.getTeamById.old_team_names.map((item) => {
                  return <div key={item.id}>
                    <p>{moment(item.created_date).utcOffset(8).format('YYYY-MM-DD HH:mm::ss')} {item.user.nickname} 将团队名称由 {`{${item.old_name}}`} 修改为 {`{${item.new_name}}`}</p>
                  </div>
                })}
                <Form.Item style={{ textAlign: 'right' }}>
                  <Button
                    onClick={() => this.setState({ visible: false })}
                    style={{ marginRight: '30px' }}
                  >
                    取消
                  </Button>
                  <ChangeTeamNameComponent>
                    {(send, { loading }) => {
                      return (
                        <Button
                          type="primary"
                          loading={loading}
                          onClick={this.handleSubmit.bind(this, send, refetch)}
                        >
                          确定
                        </Button>
                      );
                    }}
                  </ChangeTeamNameComponent>
                </Form.Item>
              </Form>
            }}
          </GetTeamOldNamesByIdComponent>
        </Modal>
      </div>
    );
  }
}

export const ChangeTeamNameModalForm: any = Form.create({
  name: 'change-team-end-date',
})(ChangeTeamNameModal);
