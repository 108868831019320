import React from 'react';
import {
  Button,
  Modal,
  Form,
  DatePicker,
  TimePicker,
} from 'antd';

import { ChangeDemandTimeComponent } from '../../../../apollo';
import { message } from '../../../../components/message';
import moment from 'moment';

class ChangeDemandTimeModal extends React.Component<any> {
  state = {
    visible: false,
    uri: '',
  };

  handleSubmit = (send: any, e: any) => {
    e.preventDefault();
    this.props.form.validateFields(async (err: any, values: any) => {
      if (!err) {
        try {
          const date_string = values.finish_date.format("YYYY-MM-DD") + " " + values.finish_date_time.format("HH:mm:ss");
          await send({
            variables: {
              ChangeDemandTime: { finish_date: date_string, id: this.props.id },
              // ChangeDemandTime: { finish_date: new Date(values.finish_date.format("YYYY-MM-DD HH:mm")), id: this.props.id },
            },
          });
          message.success('修改成功');
          const { refetch } = this.props;
          refetch();
          this.close();
        } catch (e) {
          message.error(e);
        }
      }
    });
  };

  close = () => {
    const { resetFields } = this.props.form;
    resetFields();
    this.setState({ visible: false, uri: '' });
  };

  render() {
    const { visible } = this.state;
    const { form } = this.props;
    const { getFieldDecorator } = form;
    return (
      <div>
        <Button onClick={() => this.setState({ visible: true })}>
          修改时间
        </Button>
        <Modal
          title="修改时间"
          centered
          visible={visible}
          onCancel={() => this.setState({ visible: false })}
          footer={null}
        >
          <div>
            现有交付时间：预计{' '}
            {moment(this.props.finish_date).format('YYYY-MM-DD HH:mm')}
          </div>
          <br />
          <Form>
            <Form.Item label="日期" style={{ display: 'flex' }}>
              {getFieldDecorator('finish_date', {
                rules: [{ required: true, message: '请选择日期!' }],
              })(<DatePicker placeholder="选择日期" />)}
            </Form.Item>
            <Form.Item label="时间" style={{ display: 'flex' }}>
              {getFieldDecorator('finish_date_time', {
                rules: [{ required: true, message: '请选择时间!' }],
              })(<TimePicker placeholder="选择时间" defaultOpenValue={moment('00:00:00', 'HH:mm:ss')} />)}
            </Form.Item>
            <p style={{ color: 'rgba(0,0,0,0.3)' }}>
              注：不可以选择周六周日以及法定节假日
              <br />
              一旦选择修改后，其他需求时间也将相应发生变化
            </p>
            <Form.Item style={{ textAlign: 'right' }}>
              <Button
                onClick={() => this.setState({ visible: false })}
                style={{ marginRight: '30px' }}
              >
                取消
              </Button>
              <ChangeDemandTimeComponent>
                {(send, { loading }) => {
                  return (
                    <Button
                      type="primary"
                      loading={loading}
                      onClick={this.handleSubmit.bind(this, send)}
                    >
                      确定
                    </Button>
                  );
                }}
              </ChangeDemandTimeComponent>
            </Form.Item>
          </Form>
        </Modal>
      </div>
    );
  }
}

export const ChangeDemandTimeModalForm: any = Form.create({
  name: 'change-demand-time-modal-form',
})(ChangeDemandTimeModal);
