import * as ReactApollo from "react-apollo";
import * as React from "react";
import gql from "graphql-tag";

export type Maybe<T> = T | null;

export interface OrderFilterOrder {
  created_date?: Maybe<string>;
}

export interface UsersFilterOrder {
  created_date?: Maybe<string>;
}

export interface PlanRecordFilterOrder {
  created_date?: Maybe<string>;
}

export interface DemandFilterOrder {
  created_date?: Maybe<string>;

  finish_date?: Maybe<string>;
}

export interface NoticeFilterOrder {
  created_date?: Maybe<string>;
}

export interface TeamFilterOrder {
  created_date?: Maybe<string>;

  name?: Maybe<string>;

  plan_end_date?: Maybe<string>;

  balance?: Maybe<string>;
}

export interface ProjectFilterOrder {
  created_date?: Maybe<string>;
}

export interface MemberFilterOrder {
  created_date?: Maybe<string>;
}

export interface IndependentFilterOrder {
  created_date?: Maybe<string>;
}

export interface PackageFilterOrder {
  created_date?: Maybe<string>;
}

export interface SubscriptionFilterOrder {
  created_date?: Maybe<string>;
}

export interface OrderItemFilterOrder {
  created_date?: Maybe<string>;
}

export interface AnnexFilterOrder {
  created_date?: Maybe<string>;
}

export interface AnnotationFilterOrder {
  created_date?: Maybe<string>;
}

export interface DemandMoreFilterOrder {
  created_date?: Maybe<string>;
}

export interface LoginByAdmin {
  mobile: string;

  password: string;
}

export interface LoginByUi {
  mobile: string;

  password: string;
}

export interface CreateDesigner {
  username: string;

  password: string;

  mobile: string;
}

export interface CreateProductOrder {
  product_name: string;

  demand_id: string;
}

export interface CreateSubscriptionOrder {
  subscription_name: string;

  cycle: number;

  price: number;
  /** 月 季度 年 */
  cycle_name: string;
}

export interface UpdateSubscriptionByOrder {
  name: string;

  number: number;

  pay_type: string;
}

export interface SignProductOrder {
  order_id: string;

  pay_type: string;
}

export interface UploadOrderImage {
  pay_uri: string;

  order_id: string;
}

export interface ChangeOrderStatus {
  order_id: string;
}

export interface NotPassOrder {
  reason: string;

  order_id: string;
}

export interface CreatePlanRecord {
  name: string;

  number: number;

  cycle: number;

  pay_type: string;

  cycle_name: string;

  plan_type: string;
}

export interface UpdateSubscription {
  name: string;

  number: number;

  pay_type: string;
}

export interface CreateDemand {
  name: string;
  /** 项目名称 */
  project_name: string;
  /** 需求描述 */
  description: string;
  /** 需求描述文件 */
  description_files?: Maybe<string>;
  /** 视觉风格描述 */
  ui_description?: Maybe<string>;
  /** 视觉风格描述文件 */
  ui_description_files?: Maybe<string>;
  /** 用户群体 */
  client?: Maybe<string>;
  /** 用户群体描述 */
  client_desc?: Maybe<string>;
  /** 设计风格备注 */
  ui_description_remark?: Maybe<string>;
  /** 色彩偏好描述 */
  color_description?: Maybe<string>;
  /** 尺寸描述 */
  size_description?: Maybe<string>;
  /** 尺寸描述文件 */
  size_description_files?: Maybe<string>;
  /** 备注 */
  remark?: Maybe<string>;
  /** 源文件格式要求 */
  original_file_format: string;
  /** 图片格式要求 */
  file_format: string;
  /** logo 类型 */
  logo_type?: Maybe<string>;
  /** logo 名称 */
  logo_name?: Maybe<string>;
  /** 排序 */
  position?: Maybe<number>;
}

export interface ChangeDemandTime {
  id: string;

  finish_date?: Maybe<string>;

  status?: Maybe<string>;
}

export interface AppointDesigner {
  id: string;

  designer_id: string;
}

export interface FinishDemand {
  id: string;

  finish_remark?: Maybe<string>;

  rate?: Maybe<number>;

  appropriate_rate?: Maybe<number>;

  feedback?: Maybe<string>;
}

export interface SortDemandList {
  /** 需求列表 */
  demandList?: Maybe<SortDemand[]>;
}

export interface SortDemand {
  id: string;

  position: number;
}

export interface CreateNotice {
  /** 消息 */
  message: string;
  /** 拥有者 */
  owner_id: string;
}

export interface SignWeChatInput {
  /** url */
  url: string;
  /** 这个字段特殊 使用大写 */
  jsApiList: string[];
}

export interface ChangeTeamEndDate {
  team_id: string;

  plan_end_date: string;
}

export interface CreateTeam {
  name: string;

  mobile?: Maybe<string>;

  code?: Maybe<string>;
}

export interface AddTeam {
  team_code: string;

  mobile?: Maybe<string>;

  code?: Maybe<string>;
}

export interface AppointTeamDesigner {
  id: string;

  designer_id: string;
}

export interface AppointTeamDesignerAdviser {
  id: string;

  designer_adviser_id: string;
}

export interface AddBalance {
  team_id: string;

  balance: number;

  remark: string;
}

export interface ChangeTeam {
  team_id: string;
}

export interface CreateProject {
  name: string;
}

export interface CreateMember {
  name: string;
}

export interface CreateIndependent {
  name: string;
  /** 需求描述 */
  description: string;
  /** 需求描述文件 */
  description_files?: Maybe<string>;
  /** 项目周期 */
  cycle: string;
  /** 预算 */
  budget: string;
}

export interface CreatePackage {
  name: string;
}

export interface CreateSubscription {
  name: string;
}

export interface ChangeSubscriptionCount {
  team_id: string;

  count: number;
}

export interface CreateOrderItem {
  /** 服务类型 */
  type: string;
  /** 明细 */
  detail: string;
  /** 金额 */
  price: number;
  /** 增 减 0 1 */
  order_type: number;
  /** 订单号 */
  out_trade_no: string;
}

export interface CreateAnnex {
  /** 需求 id */
  demand_id: string;
  /** 分组 id */
  group_id: string;
  /** 创建时间 */
  group_date: DateTime;
  /** 文件 */
  path: string;
  /** 文件原名 */
  file_old_name?: Maybe<string>;
}

export interface FinishAnnex {
  /** id */
  id: string;
}

export interface CreateAnnotation {
  /** id */
  id?: Maybe<string>;
  /** 附件 id */
  annex_id: string;
  /** 内容 */
  text: string;

  geometry: string;

  data: string;
}

export interface UpdateAnnotation {
  /** id */
  id: string;
  /** 附件 id */
  annex_id: string;
  /** 内容 */
  text: string;

  geometry: string;

  data: string;
}

export interface CreateDemandMore {
  context: string;

  uris?: Maybe<string>;

  demand_id: string;
}

export interface UpdateDemandMore {
  id: string;

  context: string;

  uris: string;
}

export interface CreateInvoice {
  order_id: string;

  company_name: string;

  identification_number: string;

  amount: string;

  invoice_type: string;

  email: string;

  phone: string;
}

/** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
export type DateTime = any;

// ====================================================
// Scalars
// ====================================================

// ====================================================
// Types
// ====================================================

export interface Query {
  viewer: UserEntity;
  /** 用户管理 */
  users: UserList;
  /** 设计师管理 */
  designerList: UserList;
  /** 获取所有设计师 */
  designers: UserEntity[];

  getOrderById: OrderEntity;
  /** 列表 */
  orders: OrderList;

  getPlanRecordById: PlanRecordEntity;
  /** 列表 */
  planRecords: PlanRecordList;

  getDemandById: DemandEntity;

  getDemandByAdmin: DemandEntity;
  /** 列表 */
  demands: DemandList;
  /** 列表 */
  designerDemands: DemandList;
  /** 列表 */
  ownerDemands: DemandList;

  getNoticeById: NoticeEntity;
  /** 列表 */
  ownNotices: NoticeEntity[];
  /** 列表 */
  notices: NoticeList;

  getFileById: FileEntity;

  requestUploadOSSFile?: Maybe<StsTokenType>;

  getTeamById: TeamEntity;
  /** 创建邀请链接通过团队id */
  createTeamUriById: TeamUri;
  /** 创建邀请链接 */
  createTeamUri: TeamUri;
  /** 通过邀请码获取团队 */
  getTeamByCode: BaseTeam;
  /** 列表 */
  teams: TeamList;
  /** 设计师团队列表 */
  designerTeamList: TeamList;

  getProjectById: ProjectEntity;
  /** 列表 */
  projects: ProjectList;

  getMemberById: MemberEntity;
  /** 列表 */
  members: MemberList;

  getIndependentById: IndependentEntity;

  getIndependentByAdmin: IndependentEntity;
  /** 列表 */
  independents: IndependentList;

  getPackageById: PackageEntity;
  /** 列表 */
  packages: PackageList;

  getSubscriptionById: SubscriptionEntity;
  /** 列表 */
  subscriptions: SubscriptionList;

  getOrderItemById: OrderItemEntity;
  /** 列表 */
  orderItems: OrderItemList;

  getAnnexById: AnnexEntity;
  /** 获取需求下设计稿 */
  annexGroupList: AnnexGroupList[];
  /** 列表 */
  annexList: AnnexList;

  getAnnotationById: AnnotationEntity;
  /** 列表 */
  annexAnnotations: AnnotationEntity[];
  /** 列表 */
  annotations: AnnotationList;

  getDemandMoreById: DemandMoreEntity;
  /** 列表 */
  demandMores: DemandMoreList;

  getInvoiceById: InvoiceEntity;
  /** 列表 */
  invoices: InvoiceList;
}

/** 用户 */
export interface UserEntity {
  id: string;

  username?: Maybe<string>;

  role?: Maybe<string>;

  avatar?: Maybe<string>;

  mobile?: Maybe<string>;

  nickname?: Maybe<string>;

  headimgurl?: Maybe<string>;

  proxy_headimgurl?: Maybe<string>;

  created_date?: Maybe<DateTime>;

  demands: DemandEntity[];

  orders: OrderList;

  team?: Maybe<TeamEntity>;

  teams: TeamEntity[];
  /** 服务的额客户数量 */
  services_customs_count: number;
  /** 服务的额客户列表 */
  services_customs_list: TeamEntity[];
  /** 服务的需求数 */
  demands_count: number;
  /** 服务的需求进行中数 */
  demands_progress_count: number;

  is_mobile: boolean;
}

export interface DemandEntity {
  id: string;
  /** 需求名称 */
  name: string;
  /** 花费的时间 */
  cost_day: number;
  /** 状态 进度 进行中 等待中 已完成 已取消 */
  status: string;
  /** 类别 独立项目 订阅计划 */
  type: string;
  /** 项目名称 */
  project_name: string;
  /** 需求描述 */
  description: string;
  /** 视觉风格描述 */
  ui_description?: Maybe<string>;
  /** 色彩偏好描述 */
  color_description?: Maybe<string>;
  /** 尺寸描述 */
  size_description?: Maybe<string>;
  /** 备注 */
  remark?: Maybe<string>;
  /** 需求开始时间 */
  start_date?: Maybe<DateTime>;
  /** 需求预计完成时间 */
  estimated_finish_date?: Maybe<DateTime>;
  /** 需求完成时间 */
  finish_date: DateTime;
  /** 源文件格式要求 */
  original_file_format?: Maybe<string>;
  /** 图片格式要求 */
  file_format?: Maybe<string>;
  /** logo 类型 */
  logo_type?: Maybe<string>;
  /** logo 名称 */
  logo_name?: Maybe<string>;
  /** logo 名称 */
  cover?: Maybe<string>;
  /** 完成备注 */
  finish_remark?: Maybe<string>;
  /** 是否有新的修改意见 */
  is_new?: Maybe<boolean>;
  /** ui设计师是否新上传设计稿 */
  is_new_ui?: Maybe<boolean>;
  /** 是否是新创建的需求 */
  designer_is_new?: Maybe<boolean>;
  /** 用户群体 */
  client?: Maybe<string>;
  /** 评分 */
  rate?: Maybe<number>;
  /** 评分 */
  appropriate_rate?: Maybe<number>;
  /** 反馈 */
  feedback?: Maybe<string>;
  /** 用户群体描述 */
  client_desc?: Maybe<string>;
  /** 设计风格备注 */
  ui_description_remark?: Maybe<string>;
  /** 排序 */
  position?: Maybe<number>;
  /** team订阅到期后更新该状态，设计师端查询是不查询该数据，1为已过期，其它非 */
  is_expire?: Maybe<number>;

  created_date?: Maybe<DateTime>;

  cover_uri?: Maybe<string>;

  updated_date?: Maybe<DateTime>;

  designer_status?: Maybe<string>;

  description_files?: Maybe<FileItemList[]>;

  ui_description_files?: Maybe<FileItemList[]>;

  size_description_files?: Maybe<FileItemList[]>;

  owner?: Maybe<UserEntity>;

  designer?: Maybe<UserEntity>;

  team: TeamEntity;

  project: ProjectEntity;

  order?: Maybe<OrderEntity>;

  more?: Maybe<DemandMoreEntity>;

  mores: DemandMoreEntity[];
}

export interface FileItemList {
  uri: string;

  name: string;
}

export interface TeamEntity {
  id: string;

  name: string;

  balance: number;
  /** 订阅名称 */
  plan?: Maybe<string>;
  /** 项目周期 */
  cycle?: Maybe<number>;
  /** 项目周期名称 */
  cycle_name?: Maybe<string>;
  /** 设计师 id */
  designer_id?: Maybe<string>;
  /** 设计师顾问id */
  designer_adviser_id?: Maybe<string>;

  plan_end_date?: Maybe<DateTime>;

  now_plan_end_date?: Maybe<DateTime>;

  plan_count?: Maybe<number>;

  card_uri?: Maybe<string>;

  created_date?: Maybe<DateTime>;

  owner: UserEntity;

  designer?: Maybe<UserEntity>;

  designerAdviser?: Maybe<UserEntity>;

  members: MemberEntity[];

  oss_card_uri?: Maybe<string>;

  subscriptions: SubscriptionEntity[];

  now_subscription?: Maybe<SubscriptionEntity>;

  orders: OrderEntity[];

  order_count: number;

  old_team_names: ChangeTeamRecordEntity[];
}

export interface MemberEntity {
  id: string;

  team_id: string;

  created_date?: Maybe<DateTime>;

  owner?: Maybe<UserEntity>;
}

export interface SubscriptionEntity {
  id: string;
  /** 名称 */
  name: string;
  /** 数量 */
  count: number;
  /** 订阅开始时间 */
  start_date?: Maybe<DateTime>;
  /** 订阅到期时间 */
  end_date?: Maybe<DateTime>;
  /** 项目周期 */
  cycle: number;
  /** 项目周期名称 */
  cycle_name: string;
  /** 价格 */
  price?: Maybe<number>;
  /** 状态 未付款 已付款 */
  status: string;
  /** 订单号 */
  out_trade_no: string;
  /** 支付宝 微信 */
  pay_type?: Maybe<string>;

  created_date?: Maybe<DateTime>;
}

export interface OrderEntity {
  id: string;
  /** 服务类型 单次独立项目 */
  type: string;
  /** 续订 新订 升级 */
  team_type?: Maybe<string>;
  /** 可同时进行的需求数 */
  demand_count?: Maybe<number>;
  /** 明细 */
  detail: string;
  /** 状态 待付款 已付款 待审核 审核通过 审核未通过 */
  status: string;
  /** 付款金额 */
  price: number;
  /** 原价 */
  old_price: number;
  /** 订单号 */
  out_trade_no: string;
  /** 支付宝 微信 对公账户 */
  pay_type?: Maybe<string>;
  /** 付款时间 */
  pay_date?: Maybe<DateTime>;
  /** 备注 */
  remark?: Maybe<string>;
  /** 原因 */
  reason?: Maybe<string>;
  /** 签名 uri */
  uri?: Maybe<string>;
  /** 付款凭证 */
  pay_uri?: Maybe<string>;
  /** 项目周期 */
  cycle?: Maybe<number>;
  /** 项目周期名称 */
  cycle_name?: Maybe<string>;
  /** 是否开票 */
  open_invoice: boolean;

  created_date?: Maybe<DateTime>;

  owner?: Maybe<UserEntity>;

  pay_user?: Maybe<UserEntity>;

  items: OrderItemEntity[];

  team: TeamEntity;
}

export interface OrderItemEntity {
  id: string;
  /** 服务类型 */
  type: string;
  /** 明细 */
  detail: string;
  /** 金额 */
  price: number;
  /** 增 减 0 1 */
  order_type: number;
  /** 订单号 */
  out_trade_no: string;
}

export interface ChangeTeamRecordEntity {
  id: string;

  old_name: string;

  new_name: string;

  user: UserEntity;

  created_date?: Maybe<DateTime>;
}

export interface ProjectEntity {
  id: string;
  /** 名称 */
  title: string;
  /** 主图 */
  uri: string;
  /** 类型  或者说是属于哪个套餐 */
  type?: Maybe<string>;
  /** 线上 线下 */
  item_type: string;
  /** 内容 */
  content: string;
  /** 介绍 */
  description: string;
  /** 需要的时间 单位 日 */
  cost_day: number;
}

export interface DemandMoreEntity {
  id: string;
  /** 需求内容 */
  context: string;
  /** 文件 */
  uris: string;

  oss_uris: DemandMoreUris[];
}

export interface DemandMoreUris {
  uri: string;

  oss_uri: string;
}

export interface OrderList {
  count: number;

  list: OrderEntity[];
}

export interface UserList {
  count: number;

  list: UserEntity[];
}

export interface PlanRecordEntity {
  id: string;
  /** 订阅名称 */
  name: string;
  /** 需求数目 */
  number: number;
  /** 项目周期 */
  cycle: number;
  /** 项目周期名称 */
  cycle_name: string;
  /** 价格 */
  price: number;
  /** 状态 未付款 已付款 */
  status: string;
  /** 订单号 */
  out_trade_no: string;
  /** 支付宝 微信 */
  pay_type?: Maybe<string>;
  /** 计划类型 */
  plan_type?: Maybe<string>;
  /** 签名 uri */
  uri?: Maybe<string>;
}

export interface PlanRecordList {
  count: number;

  list: PlanRecordEntity[];
}

export interface DemandList {
  count: number;

  list: DemandEntity[];
}

export interface NoticeEntity {
  id: string;
  /** 消息 */
  message: string;
  /** 是否已读 */
  is_read: boolean;
  /** 拥有者 */
  owner_id: string;
}

export interface NoticeList {
  count: number;

  list: NoticeEntity[];
}

export interface FileEntity {
  id: string;
}

export interface StsTokenType {
  access_key_id: string;

  policy: string;

  signature: string;

  key: string;

  host: string;

  expiration: DateTime;

  dir?: Maybe<string>;

  callback: string;
}

export interface TeamUri {
  uri: string;
}

export interface BaseTeam {
  id: string;

  name: string;

  owner: UserEntity;
}

export interface TeamList {
  count: number;

  list: TeamEntity[];
}

export interface ProjectList {
  count: number;

  list: ProjectEntity[];
}

export interface MemberList {
  count: number;

  list: MemberEntity[];
}

export interface IndependentEntity {
  id: string;
  /** 需求名称 */
  name: string;
  /** 需求描述 */
  description: string;
  /** 项目周期 */
  cycle: string;
  /** 预算 */
  budget: string;

  created_date?: Maybe<DateTime>;

  description_files?: Maybe<FileItemList[]>;

  owner: UserEntity;

  team: TeamEntity;
}

export interface IndependentList {
  count: number;

  list: IndependentEntity[];
}

export interface PackageEntity {
  id: string;
  /** 名称 */
  name: string;
  /** 现价 */
  price: number;
  /** 得到多少余额 */
  balance: number;
  /** 状态 上架 下架 */
  status: string;
  /** 介绍 */
  introduction: string;
  /** 备注 */
  remark?: Maybe<string>;
}

export interface PackageList {
  count: number;

  list: PackageEntity[];
}

export interface SubscriptionList {
  count: number;

  list: SubscriptionEntity[];
}

export interface OrderItemList {
  count: number;

  list: OrderItemEntity[];
}

export interface AnnexEntity {
  id: string;
  /** 设计师 id */
  designer_id: string;
  /** 需求 id */
  demand_id: string;
  /** 创建时间 */
  group_date: DateTime;
  /** 创建时间 */
  group_id: string;
  /** 文件 */
  path: string;
  /** 文件原名 */
  file_old_name?: Maybe<string>;
  /** 是否有反馈 */
  is_new?: Maybe<boolean>;
  /** 地址 */
  uri: string;
}

export interface AnnexGroupList {
  items: AnnexEntity[];

  date: string;

  is_new?: Maybe<boolean>;

  group_id: string;
}

export interface AnnexList {
  count: number;

  list: AnnexEntity[];
}

export interface AnnotationEntity {
  id: string;
  /** 创建人 */
  create_owner_id: string;
  /** 附件 id */
  annex_id: string;
  /** 内容 */
  text: string;
  /** 是否处理 */
  is_finish?: Maybe<boolean>;

  geometry: string;

  data: string;

  owner: UserEntity;

  created_date: DateTime;
}

export interface AnnotationList {
  count: number;

  list: AnnotationEntity[];
}

export interface DemandMoreList {
  count: number;

  list: DemandMoreEntity[];
}

export interface InvoiceEntity {
  id: string;
  /** 公司名称 */
  company_name: string;
  /** 纳税人识别号 */
  identification_number: string;
  /** 金额 */
  amount: string;
  /** 发票类型 */
  invoice_type?: Maybe<string>;
  /** 邮箱 */
  email?: Maybe<string>;
  /** 手机号 */
  phone: string;

  owner?: Maybe<UserEntity>;

  order?: Maybe<OrderEntity>;
}

export interface InvoiceList {
  count: number;

  list: InvoiceEntity[];
}

export interface Mutation {
  /** 管理员登录 */
  loginByAdmin: AccessToken;
  /** UI 登录 */
  loginByUi: AccessToken;
  /** 注册短信验证码 */
  createMobileCode: Result;
  /** 创建设计师 */
  createDesigner: UserEntity;
  /** 创建独立项目订单 */
  createProductOrder: OrderEntity;
  /** 创建订阅项目订单 */
  createSubscriptionOrder: OrderEntity;
  /** 升级订阅项目订单 */
  updateSubscriptionOrder: OrderEntity;
  /** 订单签名 */
  signProductOrder: OrderEntity;
  /** 上传凭证 */
  uploadPayImage: OrderEntity;
  /** 退款 */
  backOrderByAdmin: OrderEntity;
  /** 通过订单 */
  changeOrderStatusByAdmin: OrderEntity;
  /** 不通过订单 */
  notPassOrder: OrderEntity;
  /** 创建 */
  createPlanRecord: PlanRecordEntity;
  /** 升级 */
  updateSubscription: PlanRecordEntity;
  /** 余额支付 */
  balancePay: PlanRecordEntity;
  /** 余额支付更新 */
  balancePayUpdate: PlanRecordEntity;
  /** 创建 */
  createDemand: DemandEntity;
  /** 创建 */
  createDemandByAdmin: DemandEntity;
  /** 创建 */
  createDemandByUi: DemandEntity;
  /** 修改需求时间 */
  changeDemandTime: DemandEntity;
  /** 指定设计师 */
  appointDesigner: DemandEntity;
  /** 完成需求 */
  finishDemand: DemandEntity;
  /** 取消需求 */
  cancelDemand: DemandEntity;
  /** 取消需求 */
  cancelDemandByOwner: DemandEntity;
  /** 置顶需求 */
  topDemandByOwner: DemandEntity;
  /** 转化需求 */
  changeDemandType: DemandEntity;
  /** 排序等待中的需求 */
  sortDemands: Result;
  /** 创建 */
  createNotice: NoticeEntity;
  /** 完成 */
  readNotice: NoticeEntity;
  /** 微信签名 */
  signWeChat: SignWeChatResult;

  changeTeamEndDate: TeamEntity;
  /** 创建 */
  createTeam: TeamEntity;
  /** 删除团队 */
  deleteTeam: TeamEntity;
  /** 加入团队 */
  addTeam: TeamEntity;
  /** 上传名片 */
  uploadTeamCardUri: TeamEntity;
  /** 指定设计师 */
  appointTeamDesigner: TeamEntity;
  /** 指定设计师 */
  appointTeamDesignerAdviser: TeamEntity;
  /** 赠送余额 */
  addBalance: TeamEntity;
  /** 切换团队 */
  changeTeam: TeamEntity;
  /** 转移团队管理人 */
  changeTeamOwner: TeamEntity;
  /** 修改团队名称 */
  changeTeamName: TeamEntity;
  /** 创建 */
  createProject: ProjectEntity;
  /** 创建 */
  createMember: MemberEntity;
  /** 退出团队 */
  outTeam: MemberEntity;
  /** 删除成员 */
  deleteMember: MemberEntity;
  /** 移动成员 */
  moveMember: MemberEntity;
  /** 创建 */
  createIndependent: IndependentEntity;
  /** 创建 */
  createPackage: PackageEntity;
  /** 创建 */
  createSubscription: SubscriptionEntity;
  /** 修改同时并发项目的数量 */
  changeSubscriptionCount: SubscriptionEntity;
  /** 创建 */
  createOrderItem: OrderItemEntity;
  /** 删除 */
  deleteOrderItem: OrderItemEntity;
  /** 创建 */
  createAnnex: AnnexEntity;
  /** 处理 */
  finishAnnex: AnnexEntity;
  /** 创建 */
  createAnnotation: AnnotationEntity;
  /** 更新 */
  updateAnnotation: AnnotationEntity;
  /** 删除 */
  deleteAnnotation: AnnotationEntity;
  /** 创建 */
  createDemandMore: DemandMoreEntity;
  /** 创建 */
  updateDemandMore: DemandMoreEntity;
  /** 创建开票申请 */
  CreateInvoice: InvoiceEntity;
}

export interface AccessToken {
  user_id?: Maybe<string>;

  expires_in?: Maybe<number>;

  access_token?: Maybe<string>;
}

export interface Result {
  success: boolean;
}

export interface SignWeChatResult {
  debug?: Maybe<boolean>;

  appId?: Maybe<string>;

  timestamp?: Maybe<string>;

  nonceStr?: Maybe<string>;

  signature?: Maybe<string>;

  jsApiList?: Maybe<string[]>;

  mobile?: Maybe<string>;

  true_name?: Maybe<string>;
}

export interface PageInfo {
  startCursor?: Maybe<string>;

  endCursor?: Maybe<string>;

  hasPreviousPage: boolean;

  hasNextPage: boolean;

  totalCount: boolean;
}

// ====================================================
// Arguments
// ====================================================

export interface UsersQueryArgs {
  search?: Maybe<string>;

  columns?: Maybe<string[]>;

  skip?: number;

  take?: number;

  order?: Maybe<UsersFilterOrder>;

  role?: Maybe<string>;
}
export interface DesignerListQueryArgs {
  search?: Maybe<string>;

  columns?: Maybe<string[]>;

  skip?: number;

  take?: number;

  order?: Maybe<UsersFilterOrder>;

  role?: Maybe<string>;
}
export interface GetOrderByIdQueryArgs {
  id: string;
}
export interface OrdersQueryArgs {
  search?: Maybe<string>;

  columns?: Maybe<string[]>;

  skip?: number;

  take?: number;

  order?: Maybe<OrderFilterOrder>;

  team_id?: Maybe<string>;

  status?: Maybe<string[]>;

  pay_type?: Maybe<string[]>;

  type?: Maybe<string[]>;
}
export interface GetPlanRecordByIdQueryArgs {
  id: string;
}
export interface PlanRecordsQueryArgs {
  search?: Maybe<string>;

  columns?: Maybe<string[]>;

  skip?: number;

  take?: number;

  order?: Maybe<PlanRecordFilterOrder>;
}
export interface GetDemandByIdQueryArgs {
  id: string;
}
export interface GetDemandByAdminQueryArgs {
  id: string;
}
export interface DemandsQueryArgs {
  search?: Maybe<string>;

  columns?: Maybe<string[]>;

  skip?: number;

  take?: number;

  order?: Maybe<DemandFilterOrder>;

  team_id?: Maybe<string>;

  status?: Maybe<string>;

  designer_id?: Maybe<string>;

  owner_id?: Maybe<string>;

  name?: Maybe<string[]>;

  owner_username?: Maybe<string[]>;

  company_name?: Maybe<string[]>;

  type?: Maybe<string[]>;

  designer_status?: Maybe<string[]>;
}
export interface DesignerDemandsQueryArgs {
  search?: Maybe<string>;

  columns?: Maybe<string[]>;

  skip?: number;

  take?: number;

  order?: Maybe<DemandFilterOrder>;

  team_id?: Maybe<string>;

  status?: Maybe<string>;

  designer_id?: Maybe<string>;

  owner_id?: Maybe<string>;

  name?: Maybe<string[]>;

  owner_username?: Maybe<string[]>;

  company_name?: Maybe<string[]>;

  type?: Maybe<string[]>;

  designer_status?: Maybe<string[]>;
}
export interface OwnerDemandsQueryArgs {
  search?: Maybe<string>;

  columns?: Maybe<string[]>;

  skip?: number;

  take?: number;

  order?: Maybe<DemandFilterOrder>;

  team_id?: Maybe<string>;

  status?: Maybe<string>;

  designer_id?: Maybe<string>;

  owner_id?: Maybe<string>;

  name?: Maybe<string[]>;

  owner_username?: Maybe<string[]>;

  company_name?: Maybe<string[]>;

  type?: Maybe<string[]>;

  designer_status?: Maybe<string[]>;
}
export interface GetNoticeByIdQueryArgs {
  id: string;
}
export interface NoticesQueryArgs {
  search?: Maybe<string>;

  columns?: Maybe<string[]>;

  skip?: number;

  take?: number;

  order?: Maybe<NoticeFilterOrder>;
}
export interface GetFileByIdQueryArgs {
  id: string;
}
export interface RequestUploadOssFileQueryArgs {
  file_name: string;
}
export interface GetTeamByIdQueryArgs {
  id: string;
}
export interface CreateTeamUriByIdQueryArgs {
  id: string;
}
export interface GetTeamByCodeQueryArgs {
  code: string;
}
export interface TeamsQueryArgs {
  search?: Maybe<string>;

  columns?: Maybe<string[]>;

  skip?: number;

  take?: number;

  order?: Maybe<TeamFilterOrder>;

  range?: Maybe<string>;

  designer_id?: Maybe<string>;
}
export interface GetProjectByIdQueryArgs {
  id: string;
}
export interface ProjectsQueryArgs {
  search?: Maybe<string>;

  columns?: Maybe<string[]>;

  skip?: number;

  take?: number;

  order?: Maybe<ProjectFilterOrder>;

  type?: Maybe<string>;
}
export interface GetMemberByIdQueryArgs {
  id: string;
}
export interface MembersQueryArgs {
  search?: Maybe<string>;

  columns?: Maybe<string[]>;

  skip?: number;

  take?: number;

  order?: Maybe<MemberFilterOrder>;

  team_id?: Maybe<string>;
}
export interface GetIndependentByIdQueryArgs {
  id: string;
}
export interface GetIndependentByAdminQueryArgs {
  id: string;
}
export interface IndependentsQueryArgs {
  search?: Maybe<string>;

  columns?: Maybe<string[]>;

  skip?: number;

  take?: number;

  order?: Maybe<IndependentFilterOrder>;
}
export interface GetPackageByIdQueryArgs {
  id: string;
}
export interface PackagesQueryArgs {
  search?: Maybe<string>;

  columns?: Maybe<string[]>;

  skip?: number;

  take?: number;

  order?: Maybe<PackageFilterOrder>;
}
export interface GetSubscriptionByIdQueryArgs {
  id: string;
}
export interface SubscriptionsQueryArgs {
  search?: Maybe<string>;

  columns?: Maybe<string[]>;

  skip?: number;

  take?: number;

  order?: Maybe<SubscriptionFilterOrder>;

  team_id?: Maybe<string>;
}
export interface GetOrderItemByIdQueryArgs {
  id: string;
}
export interface OrderItemsQueryArgs {
  search?: Maybe<string>;

  columns?: Maybe<string[]>;

  skip?: number;

  take?: number;

  order?: Maybe<OrderItemFilterOrder>;
}
export interface GetAnnexByIdQueryArgs {
  id: string;
}
export interface AnnexGroupListQueryArgs {
  group_id?: Maybe<string>;

  id: string;
}
export interface AnnexListQueryArgs {
  search?: Maybe<string>;

  columns?: Maybe<string[]>;

  skip?: number;

  take?: number;

  order?: Maybe<AnnexFilterOrder>;
}
export interface GetAnnotationByIdQueryArgs {
  id: string;
}
export interface AnnexAnnotationsQueryArgs {
  annex_id: string;
}
export interface AnnotationsQueryArgs {
  search?: Maybe<string>;

  columns?: Maybe<string[]>;

  skip?: number;

  take?: number;

  order?: Maybe<AnnotationFilterOrder>;

  annex_id?: Maybe<string>;
}
export interface GetDemandMoreByIdQueryArgs {
  id: string;
}
export interface DemandMoresQueryArgs {
  search?: Maybe<string>;

  columns?: Maybe<string[]>;

  skip?: number;

  take?: number;

  order?: Maybe<DemandMoreFilterOrder>;
}
export interface GetInvoiceByIdQueryArgs {
  id: string;
}
export interface InvoicesQueryArgs {
  search?: Maybe<string>;

  columns?: Maybe<string[]>;

  skip?: number;

  take?: number;
}
export interface OrdersUserEntityArgs {
  search?: Maybe<string>;

  columns?: Maybe<string[]>;

  skip?: number;

  take?: number;

  order?: Maybe<OrderFilterOrder>;

  team_id?: Maybe<string>;

  status?: Maybe<string[]>;

  pay_type?: Maybe<string[]>;

  type?: Maybe<string[]>;
}
export interface OrdersTeamEntityArgs {
  skip?: number;

  take?: number;

  search?: Maybe<string>;

  columns?: Maybe<string[]>;
}
export interface LoginByAdminMutationArgs {
  LoginByAdmin: LoginByAdmin;
}
export interface LoginByUiMutationArgs {
  LoginByUi: LoginByUi;
}
export interface CreateMobileCodeMutationArgs {
  /** 手机号 */
  mobile: string;
  /** 国家区号 */
  country_number: string;
}
export interface CreateDesignerMutationArgs {
  CreateDesigner: CreateDesigner;
}
export interface CreateProductOrderMutationArgs {
  CreateProductOrder: CreateProductOrder;
}
export interface CreateSubscriptionOrderMutationArgs {
  CreateSubscriptionOrder: CreateSubscriptionOrder;
}
export interface UpdateSubscriptionOrderMutationArgs {
  UpdateSubscriptionByOrder: UpdateSubscriptionByOrder;
}
export interface SignProductOrderMutationArgs {
  SignProductOrder: SignProductOrder;
}
export interface UploadPayImageMutationArgs {
  UploadOrderImage: UploadOrderImage;
}
export interface BackOrderByAdminMutationArgs {
  ChangeOrderStatus: ChangeOrderStatus;
}
export interface ChangeOrderStatusByAdminMutationArgs {
  ChangeOrderStatus: ChangeOrderStatus;
}
export interface NotPassOrderMutationArgs {
  NotPassOrder: NotPassOrder;
}
export interface CreatePlanRecordMutationArgs {
  CreatePlanRecord: CreatePlanRecord;
}
export interface UpdateSubscriptionMutationArgs {
  UpdateSubscription: UpdateSubscription;
}
export interface BalancePayMutationArgs {
  plan_record_id: string;
}
export interface BalancePayUpdateMutationArgs {
  plan_record_id: string;
}
export interface CreateDemandMutationArgs {
  CreateDemand: CreateDemand;
}
export interface CreateDemandByAdminMutationArgs {
  owner_id: string;

  team_id: string;

  CreateDemand: CreateDemand;
}
export interface CreateDemandByUiMutationArgs {
  owner_id: string;

  team_id: string;

  CreateDemand: CreateDemand;
}
export interface ChangeDemandTimeMutationArgs {
  ChangeDemandTime: ChangeDemandTime;
}
export interface AppointDesignerMutationArgs {
  AppointDesigner: AppointDesigner;
}
export interface FinishDemandMutationArgs {
  FinishDemand: FinishDemand;
}
export interface CancelDemandMutationArgs {
  id: string;
}
export interface CancelDemandByOwnerMutationArgs {
  id: string;
}
export interface TopDemandByOwnerMutationArgs {
  id: string;
}
export interface ChangeDemandTypeMutationArgs {
  id: string;
}
export interface SortDemandsMutationArgs {
  SortDemandList: SortDemandList;
}
export interface CreateNoticeMutationArgs {
  CreateNotice: CreateNotice;
}
export interface ReadNoticeMutationArgs {
  /** id */
  id: string;
}
export interface SignWeChatMutationArgs {
  SignWeChatInput: SignWeChatInput;
}
export interface ChangeTeamEndDateMutationArgs {
  ChangeTeamEndDate: ChangeTeamEndDate;
}
export interface CreateTeamMutationArgs {
  CreateTeam: CreateTeam;
}
export interface DeleteTeamMutationArgs {
  id: string;
}
export interface AddTeamMutationArgs {
  AddTeam: AddTeam;
}
export interface UploadTeamCardUriMutationArgs {
  card_uri: string;

  id: string;
}
export interface AppointTeamDesignerMutationArgs {
  AppointTeamDesigner: AppointTeamDesigner;
}
export interface AppointTeamDesignerAdviserMutationArgs {
  AppointTeamDesignerAdviser: AppointTeamDesignerAdviser;
}
export interface AddBalanceMutationArgs {
  AddBalance: AddBalance;
}
export interface ChangeTeamMutationArgs {
  ChangeTeam: ChangeTeam;
}
export interface ChangeTeamOwnerMutationArgs {
  user_id: string;
}
export interface ChangeTeamNameMutationArgs {
  new_name: string;

  team_id: string;
}
export interface CreateProjectMutationArgs {
  CreateProject: CreateProject;
}
export interface CreateMemberMutationArgs {
  CreateMember: CreateMember;
}
export interface DeleteMemberMutationArgs {
  user_id: string;
}
export interface MoveMemberMutationArgs {
  old_team_id: string;

  to_team_id: string;

  user_id: string;
}
export interface CreateIndependentMutationArgs {
  CreateIndependent: CreateIndependent;
}
export interface CreatePackageMutationArgs {
  CreatePackage: CreatePackage;
}
export interface CreateSubscriptionMutationArgs {
  CreateSubscription: CreateSubscription;
}
export interface ChangeSubscriptionCountMutationArgs {
  ChangeSubscriptionCount: ChangeSubscriptionCount;
}
export interface CreateOrderItemMutationArgs {
  CreateOrderItem: CreateOrderItem;
}
export interface DeleteOrderItemMutationArgs {
  id: string;
}
export interface CreateAnnexMutationArgs {
  CreateAnnex: CreateAnnex;
}
export interface FinishAnnexMutationArgs {
  FinishAnnex: FinishAnnex;
}
export interface CreateAnnotationMutationArgs {
  CreateAnnotation: CreateAnnotation;
}
export interface UpdateAnnotationMutationArgs {
  UpdateAnnotation: UpdateAnnotation;
}
export interface DeleteAnnotationMutationArgs {
  /** id */
  id: string;
}
export interface CreateDemandMoreMutationArgs {
  CreateDemandMore: CreateDemandMore;
}
export interface UpdateDemandMoreMutationArgs {
  UpdateDemandMore: UpdateDemandMore;
}
export interface CreateInvoiceMutationArgs {
  CreateInvoice: CreateInvoice;
}






// ====================================================
// Components
// ====================================================

export const AddBalanceDocument = gql`
  mutation AddBalance($AddBalance: AddBalance!) {
    addBalance(AddBalance: $AddBalance) {
      id
      balance
    }
  }
`;
export interface AddBalanceMutation {
  addBalance: Mutation["addBalance"];
}
export class AddBalanceComponent extends React.Component<
  Partial<ReactApollo.MutationProps<AddBalanceMutation, AddBalanceMutationArgs>>
> {
  render() {
    return (
      <ReactApollo.Mutation<AddBalanceMutation, AddBalanceMutationArgs>
        mutation={AddBalanceDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type AddBalanceProps<TChildProps = any> = Partial<
  ReactApollo.MutateProps<AddBalanceMutation, AddBalanceMutationArgs>
> &
  TChildProps;
export type AddBalanceMutationFn = ReactApollo.MutationFn<
  AddBalanceMutation,
  AddBalanceMutationArgs
>;
export function AddBalanceHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        AddBalanceMutation,
        AddBalanceMutationArgs,
        AddBalanceProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    AddBalanceMutation,
    AddBalanceMutationArgs,
    AddBalanceProps<TChildProps>
  >(AddBalanceDocument, operationOptions);
}
export const AnnexAnnotationsDocument = gql`
  query AnnexAnnotations($annex_id: String!) {
    annexAnnotations(annex_id: $annex_id) {
      id
      owner {
        id
        username
        headimgurl
      }
      annex_id
      text
      geometry
      data
      created_date
      is_finish
    }
  }
`;
export interface AnnexAnnotationsQuery {
  annexAnnotations: Query["annexAnnotations"];
}
export class AnnexAnnotationsComponent extends React.Component<
  Partial<
    ReactApollo.QueryProps<AnnexAnnotationsQuery, AnnexAnnotationsQueryArgs>
  >
> {
  render() {
    return (
      <ReactApollo.Query<AnnexAnnotationsQuery, AnnexAnnotationsQueryArgs>
        query={AnnexAnnotationsDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type AnnexAnnotationsProps<TChildProps = any> = Partial<
  ReactApollo.DataProps<AnnexAnnotationsQuery, AnnexAnnotationsQueryArgs>
> &
  TChildProps;
export function AnnexAnnotationsHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        AnnexAnnotationsQuery,
        AnnexAnnotationsQueryArgs,
        AnnexAnnotationsProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    AnnexAnnotationsQuery,
    AnnexAnnotationsQueryArgs,
    AnnexAnnotationsProps<TChildProps>
  >(AnnexAnnotationsDocument, operationOptions);
}
export const AnnexGroupListDocument = gql`
  query AnnexGroupList($id: String!, $group_id: String) {
    annexGroupList(id: $id, group_id: $group_id) {
      date
      group_id
      is_new
      items {
        is_new
        id
        path
        uri
        file_old_name
      }
    }
  }
`;
export interface AnnexGroupListQuery {
  annexGroupList: Query["annexGroupList"];
}
export class AnnexGroupListComponent extends React.Component<
  Partial<ReactApollo.QueryProps<AnnexGroupListQuery, AnnexGroupListQueryArgs>>
> {
  render() {
    return (
      <ReactApollo.Query<AnnexGroupListQuery, AnnexGroupListQueryArgs>
        query={AnnexGroupListDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type AnnexGroupListProps<TChildProps = any> = Partial<
  ReactApollo.DataProps<AnnexGroupListQuery, AnnexGroupListQueryArgs>
> &
  TChildProps;
export function AnnexGroupListHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        AnnexGroupListQuery,
        AnnexGroupListQueryArgs,
        AnnexGroupListProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    AnnexGroupListQuery,
    AnnexGroupListQueryArgs,
    AnnexGroupListProps<TChildProps>
  >(AnnexGroupListDocument, operationOptions);
}
export const CreateAnnexDocument = gql`
  mutation CreateAnnex($CreateAnnex: CreateAnnex!) {
    createAnnex(CreateAnnex: $CreateAnnex) {
      id
      group_id
      path
    }
  }
`;
export interface CreateAnnexMutation {
  createAnnex: Mutation["createAnnex"];
}
export class CreateAnnexComponent extends React.Component<
  Partial<
    ReactApollo.MutationProps<CreateAnnexMutation, CreateAnnexMutationArgs>
  >
> {
  render() {
    return (
      <ReactApollo.Mutation<CreateAnnexMutation, CreateAnnexMutationArgs>
        mutation={CreateAnnexDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type CreateAnnexProps<TChildProps = any> = Partial<
  ReactApollo.MutateProps<CreateAnnexMutation, CreateAnnexMutationArgs>
> &
  TChildProps;
export type CreateAnnexMutationFn = ReactApollo.MutationFn<
  CreateAnnexMutation,
  CreateAnnexMutationArgs
>;
export function CreateAnnexHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        CreateAnnexMutation,
        CreateAnnexMutationArgs,
        CreateAnnexProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    CreateAnnexMutation,
    CreateAnnexMutationArgs,
    CreateAnnexProps<TChildProps>
  >(CreateAnnexDocument, operationOptions);
}
export const CreateAnnotationDocument = gql`
  mutation CreateAnnotation($CreateAnnotation: CreateAnnotation!) {
    createAnnotation(CreateAnnotation: $CreateAnnotation) {
      id
    }
  }
`;
export interface CreateAnnotationMutation {
  createAnnotation: Mutation["createAnnotation"];
}
export class CreateAnnotationComponent extends React.Component<
  Partial<
    ReactApollo.MutationProps<
      CreateAnnotationMutation,
      CreateAnnotationMutationArgs
    >
  >
> {
  render() {
    return (
      <ReactApollo.Mutation<
        CreateAnnotationMutation,
        CreateAnnotationMutationArgs
      >
        mutation={CreateAnnotationDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type CreateAnnotationProps<TChildProps = any> = Partial<
  ReactApollo.MutateProps<
    CreateAnnotationMutation,
    CreateAnnotationMutationArgs
  >
> &
  TChildProps;
export type CreateAnnotationMutationFn = ReactApollo.MutationFn<
  CreateAnnotationMutation,
  CreateAnnotationMutationArgs
>;
export function CreateAnnotationHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        CreateAnnotationMutation,
        CreateAnnotationMutationArgs,
        CreateAnnotationProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    CreateAnnotationMutation,
    CreateAnnotationMutationArgs,
    CreateAnnotationProps<TChildProps>
  >(CreateAnnotationDocument, operationOptions);
}
export const DeleteAnnotationDocument = gql`
  mutation DeleteAnnotation($id: String!) {
    deleteAnnotation(id: $id) {
      id
    }
  }
`;
export interface DeleteAnnotationMutation {
  deleteAnnotation: Mutation["deleteAnnotation"];
}
export class DeleteAnnotationComponent extends React.Component<
  Partial<
    ReactApollo.MutationProps<
      DeleteAnnotationMutation,
      DeleteAnnotationMutationArgs
    >
  >
> {
  render() {
    return (
      <ReactApollo.Mutation<
        DeleteAnnotationMutation,
        DeleteAnnotationMutationArgs
      >
        mutation={DeleteAnnotationDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type DeleteAnnotationProps<TChildProps = any> = Partial<
  ReactApollo.MutateProps<
    DeleteAnnotationMutation,
    DeleteAnnotationMutationArgs
  >
> &
  TChildProps;
export type DeleteAnnotationMutationFn = ReactApollo.MutationFn<
  DeleteAnnotationMutation,
  DeleteAnnotationMutationArgs
>;
export function DeleteAnnotationHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        DeleteAnnotationMutation,
        DeleteAnnotationMutationArgs,
        DeleteAnnotationProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    DeleteAnnotationMutation,
    DeleteAnnotationMutationArgs,
    DeleteAnnotationProps<TChildProps>
  >(DeleteAnnotationDocument, operationOptions);
}
export const FinishAnnexDocument = gql`
  mutation FinishAnnex($FinishAnnex: FinishAnnex!) {
    finishAnnex(FinishAnnex: $FinishAnnex) {
      id
    }
  }
`;
export interface FinishAnnexMutation {
  finishAnnex: Mutation["finishAnnex"];
}
export class FinishAnnexComponent extends React.Component<
  Partial<
    ReactApollo.MutationProps<FinishAnnexMutation, FinishAnnexMutationArgs>
  >
> {
  render() {
    return (
      <ReactApollo.Mutation<FinishAnnexMutation, FinishAnnexMutationArgs>
        mutation={FinishAnnexDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type FinishAnnexProps<TChildProps = any> = Partial<
  ReactApollo.MutateProps<FinishAnnexMutation, FinishAnnexMutationArgs>
> &
  TChildProps;
export type FinishAnnexMutationFn = ReactApollo.MutationFn<
  FinishAnnexMutation,
  FinishAnnexMutationArgs
>;
export function FinishAnnexHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        FinishAnnexMutation,
        FinishAnnexMutationArgs,
        FinishAnnexProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    FinishAnnexMutation,
    FinishAnnexMutationArgs,
    FinishAnnexProps<TChildProps>
  >(FinishAnnexDocument, operationOptions);
}
export const UpdateAnnotationDocument = gql`
  mutation UpdateAnnotation($UpdateAnnotation: UpdateAnnotation!) {
    updateAnnotation(UpdateAnnotation: $UpdateAnnotation) {
      id
    }
  }
`;
export interface UpdateAnnotationMutation {
  updateAnnotation: Mutation["updateAnnotation"];
}
export class UpdateAnnotationComponent extends React.Component<
  Partial<
    ReactApollo.MutationProps<
      UpdateAnnotationMutation,
      UpdateAnnotationMutationArgs
    >
  >
> {
  render() {
    return (
      <ReactApollo.Mutation<
        UpdateAnnotationMutation,
        UpdateAnnotationMutationArgs
      >
        mutation={UpdateAnnotationDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type UpdateAnnotationProps<TChildProps = any> = Partial<
  ReactApollo.MutateProps<
    UpdateAnnotationMutation,
    UpdateAnnotationMutationArgs
  >
> &
  TChildProps;
export type UpdateAnnotationMutationFn = ReactApollo.MutationFn<
  UpdateAnnotationMutation,
  UpdateAnnotationMutationArgs
>;
export function UpdateAnnotationHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        UpdateAnnotationMutation,
        UpdateAnnotationMutationArgs,
        UpdateAnnotationProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    UpdateAnnotationMutation,
    UpdateAnnotationMutationArgs,
    UpdateAnnotationProps<TChildProps>
  >(UpdateAnnotationDocument, operationOptions);
}
export const ChangeSubscriptionCountDocument = gql`
  mutation ChangeSubscriptionCount(
    $ChangeSubscriptionCount: ChangeSubscriptionCount!
  ) {
    changeSubscriptionCount(ChangeSubscriptionCount: $ChangeSubscriptionCount) {
      id
      count
    }
  }
`;
export interface ChangeSubscriptionCountMutation {
  changeSubscriptionCount: Mutation["changeSubscriptionCount"];
}
export class ChangeSubscriptionCountComponent extends React.Component<
  Partial<
    ReactApollo.MutationProps<
      ChangeSubscriptionCountMutation,
      ChangeSubscriptionCountMutationArgs
    >
  >
> {
  render() {
    return (
      <ReactApollo.Mutation<
        ChangeSubscriptionCountMutation,
        ChangeSubscriptionCountMutationArgs
      >
        mutation={ChangeSubscriptionCountDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type ChangeSubscriptionCountProps<TChildProps = any> = Partial<
  ReactApollo.MutateProps<
    ChangeSubscriptionCountMutation,
    ChangeSubscriptionCountMutationArgs
  >
> &
  TChildProps;
export type ChangeSubscriptionCountMutationFn = ReactApollo.MutationFn<
  ChangeSubscriptionCountMutation,
  ChangeSubscriptionCountMutationArgs
>;
export function ChangeSubscriptionCountHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        ChangeSubscriptionCountMutation,
        ChangeSubscriptionCountMutationArgs,
        ChangeSubscriptionCountProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    ChangeSubscriptionCountMutation,
    ChangeSubscriptionCountMutationArgs,
    ChangeSubscriptionCountProps<TChildProps>
  >(ChangeSubscriptionCountDocument, operationOptions);
}
export const AppointDesignerDocument = gql`
  mutation AppointDesigner($AppointDesigner: AppointDesigner!) {
    appointDesigner(AppointDesigner: $AppointDesigner) {
      id
    }
  }
`;
export interface AppointDesignerMutation {
  appointDesigner: Mutation["appointDesigner"];
}
export class AppointDesignerComponent extends React.Component<
  Partial<
    ReactApollo.MutationProps<
      AppointDesignerMutation,
      AppointDesignerMutationArgs
    >
  >
> {
  render() {
    return (
      <ReactApollo.Mutation<
        AppointDesignerMutation,
        AppointDesignerMutationArgs
      >
        mutation={AppointDesignerDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type AppointDesignerProps<TChildProps = any> = Partial<
  ReactApollo.MutateProps<AppointDesignerMutation, AppointDesignerMutationArgs>
> &
  TChildProps;
export type AppointDesignerMutationFn = ReactApollo.MutationFn<
  AppointDesignerMutation,
  AppointDesignerMutationArgs
>;
export function AppointDesignerHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        AppointDesignerMutation,
        AppointDesignerMutationArgs,
        AppointDesignerProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    AppointDesignerMutation,
    AppointDesignerMutationArgs,
    AppointDesignerProps<TChildProps>
  >(AppointDesignerDocument, operationOptions);
}
export const AppointTeamDesignerAdviserDocument = gql`
  mutation AppointTeamDesignerAdviser(
    $AppointTeamDesignerAdviser: AppointTeamDesignerAdviser!
  ) {
    appointTeamDesignerAdviser(
      AppointTeamDesignerAdviser: $AppointTeamDesignerAdviser
    ) {
      id
    }
  }
`;
export interface AppointTeamDesignerAdviserMutation {
  appointTeamDesignerAdviser: Mutation["appointTeamDesignerAdviser"];
}
export class AppointTeamDesignerAdviserComponent extends React.Component<
  Partial<
    ReactApollo.MutationProps<
      AppointTeamDesignerAdviserMutation,
      AppointTeamDesignerAdviserMutationArgs
    >
  >
> {
  render() {
    return (
      <ReactApollo.Mutation<
        AppointTeamDesignerAdviserMutation,
        AppointTeamDesignerAdviserMutationArgs
      >
        mutation={AppointTeamDesignerAdviserDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type AppointTeamDesignerAdviserProps<TChildProps = any> = Partial<
  ReactApollo.MutateProps<
    AppointTeamDesignerAdviserMutation,
    AppointTeamDesignerAdviserMutationArgs
  >
> &
  TChildProps;
export type AppointTeamDesignerAdviserMutationFn = ReactApollo.MutationFn<
  AppointTeamDesignerAdviserMutation,
  AppointTeamDesignerAdviserMutationArgs
>;
export function AppointTeamDesignerAdviserHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        AppointTeamDesignerAdviserMutation,
        AppointTeamDesignerAdviserMutationArgs,
        AppointTeamDesignerAdviserProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    AppointTeamDesignerAdviserMutation,
    AppointTeamDesignerAdviserMutationArgs,
    AppointTeamDesignerAdviserProps<TChildProps>
  >(AppointTeamDesignerAdviserDocument, operationOptions);
}
export const CancelDemandDocument = gql`
  mutation CancelDemand($id: String!) {
    cancelDemand(id: $id) {
      id
      status
    }
  }
`;
export interface CancelDemandMutation {
  cancelDemand: Mutation["cancelDemand"];
}
export class CancelDemandComponent extends React.Component<
  Partial<
    ReactApollo.MutationProps<CancelDemandMutation, CancelDemandMutationArgs>
  >
> {
  render() {
    return (
      <ReactApollo.Mutation<CancelDemandMutation, CancelDemandMutationArgs>
        mutation={CancelDemandDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type CancelDemandProps<TChildProps = any> = Partial<
  ReactApollo.MutateProps<CancelDemandMutation, CancelDemandMutationArgs>
> &
  TChildProps;
export type CancelDemandMutationFn = ReactApollo.MutationFn<
  CancelDemandMutation,
  CancelDemandMutationArgs
>;
export function CancelDemandHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        CancelDemandMutation,
        CancelDemandMutationArgs,
        CancelDemandProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    CancelDemandMutation,
    CancelDemandMutationArgs,
    CancelDemandProps<TChildProps>
  >(CancelDemandDocument, operationOptions);
}
export const ChangeDemandTimeDocument = gql`
  mutation ChangeDemandTime($ChangeDemandTime: ChangeDemandTime!) {
    changeDemandTime(ChangeDemandTime: $ChangeDemandTime) {
      id
      finish_date
    }
  }
`;
export interface ChangeDemandTimeMutation {
  changeDemandTime: Mutation["changeDemandTime"];
}
export class ChangeDemandTimeComponent extends React.Component<
  Partial<
    ReactApollo.MutationProps<
      ChangeDemandTimeMutation,
      ChangeDemandTimeMutationArgs
    >
  >
> {
  render() {
    return (
      <ReactApollo.Mutation<
        ChangeDemandTimeMutation,
        ChangeDemandTimeMutationArgs
      >
        mutation={ChangeDemandTimeDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type ChangeDemandTimeProps<TChildProps = any> = Partial<
  ReactApollo.MutateProps<
    ChangeDemandTimeMutation,
    ChangeDemandTimeMutationArgs
  >
> &
  TChildProps;
export type ChangeDemandTimeMutationFn = ReactApollo.MutationFn<
  ChangeDemandTimeMutation,
  ChangeDemandTimeMutationArgs
>;
export function ChangeDemandTimeHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        ChangeDemandTimeMutation,
        ChangeDemandTimeMutationArgs,
        ChangeDemandTimeProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    ChangeDemandTimeMutation,
    ChangeDemandTimeMutationArgs,
    ChangeDemandTimeProps<TChildProps>
  >(ChangeDemandTimeDocument, operationOptions);
}
export const CreateDemandDocument = gql`
  mutation CreateDemand($CreateDemand: CreateDemand!) {
    createDemand(CreateDemand: $CreateDemand) {
      id
      finish_date
    }
  }
`;
export interface CreateDemandMutation {
  createDemand: Mutation["createDemand"];
}
export class CreateDemandComponent extends React.Component<
  Partial<
    ReactApollo.MutationProps<CreateDemandMutation, CreateDemandMutationArgs>
  >
> {
  render() {
    return (
      <ReactApollo.Mutation<CreateDemandMutation, CreateDemandMutationArgs>
        mutation={CreateDemandDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type CreateDemandProps<TChildProps = any> = Partial<
  ReactApollo.MutateProps<CreateDemandMutation, CreateDemandMutationArgs>
> &
  TChildProps;
export type CreateDemandMutationFn = ReactApollo.MutationFn<
  CreateDemandMutation,
  CreateDemandMutationArgs
>;
export function CreateDemandHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        CreateDemandMutation,
        CreateDemandMutationArgs,
        CreateDemandProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    CreateDemandMutation,
    CreateDemandMutationArgs,
    CreateDemandProps<TChildProps>
  >(CreateDemandDocument, operationOptions);
}
export const CreateDemandByAdminDocument = gql`
  mutation CreateDemandByAdmin(
    $CreateDemand: CreateDemand!
    $team_id: String!
    $owner_id: String!
  ) {
    createDemandByAdmin(
      CreateDemand: $CreateDemand
      team_id: $team_id
      owner_id: $owner_id
    ) {
      id
      finish_date
    }
  }
`;
export interface CreateDemandByAdminMutation {
  createDemandByAdmin: Mutation["createDemandByAdmin"];
}
export class CreateDemandByAdminComponent extends React.Component<
  Partial<
    ReactApollo.MutationProps<
      CreateDemandByAdminMutation,
      CreateDemandByAdminMutationArgs
    >
  >
> {
  render() {
    return (
      <ReactApollo.Mutation<
        CreateDemandByAdminMutation,
        CreateDemandByAdminMutationArgs
      >
        mutation={CreateDemandByAdminDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type CreateDemandByAdminProps<TChildProps = any> = Partial<
  ReactApollo.MutateProps<
    CreateDemandByAdminMutation,
    CreateDemandByAdminMutationArgs
  >
> &
  TChildProps;
export type CreateDemandByAdminMutationFn = ReactApollo.MutationFn<
  CreateDemandByAdminMutation,
  CreateDemandByAdminMutationArgs
>;
export function CreateDemandByAdminHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        CreateDemandByAdminMutation,
        CreateDemandByAdminMutationArgs,
        CreateDemandByAdminProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    CreateDemandByAdminMutation,
    CreateDemandByAdminMutationArgs,
    CreateDemandByAdminProps<TChildProps>
  >(CreateDemandByAdminDocument, operationOptions);
}
export const DemandsDocument = gql`
  query Demands($take: Int, $skip: Int, $team_id: String, $status: String) {
    demands(take: $take, team_id: $team_id, status: $status, skip: $skip) {
      count
      list {
        id
        name
        status
        finish_date
        created_date
        position
        owner {
          id
          mobile
          username
        }
        designer {
          id
          username
        }
        project {
          id
          title
        }
        team {
          name
        }
      }
    }
  }
`;
export interface DemandsQuery {
  demands: Query["demands"];
}
export class DemandsComponent extends React.Component<
  Partial<ReactApollo.QueryProps<DemandsQuery, DemandsQueryArgs>>
> {
  render() {
    return (
      <ReactApollo.Query<DemandsQuery, DemandsQueryArgs>
        query={DemandsDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type DemandsProps<TChildProps = any> = Partial<
  ReactApollo.DataProps<DemandsQuery, DemandsQueryArgs>
> &
  TChildProps;
export function DemandsHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        DemandsQuery,
        DemandsQueryArgs,
        DemandsProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    DemandsQuery,
    DemandsQueryArgs,
    DemandsProps<TChildProps>
  >(DemandsDocument, operationOptions);
}
export const DemandsCountDocument = gql`
  query DemandsCount($take: Int) {
    wait_count: demands(take: $take, status: "等待中") {
      count
    }
    ing_count: demands(take: $take, status: "进行中") {
      count
    }
    cancel_count: demands(take: $take, status: "已取消") {
      count
    }
    finish_count: demands(take: $take, status: "已完成") {
      count
    }
  }
`;
export interface DemandsCountQuery {
  demands: Query["demands"];
}
export class DemandsCountComponent extends React.Component<
  Partial<ReactApollo.QueryProps<DemandsCountQuery, any>>
> {
  render() {
    return (
      <ReactApollo.Query<DemandsCountQuery, any>
        query={DemandsCountDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type DemandsCountProps<TChildProps = any> = Partial<
  ReactApollo.DataProps<DemandsCountQuery, any>
> &
  TChildProps;
export function DemandsCountHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        DemandsCountQuery,
        any,
        DemandsCountProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    DemandsCountQuery,
    any,
    DemandsCountProps<TChildProps>
  >(DemandsCountDocument, operationOptions);
}
export const FinishDemandDocument = gql`
  mutation FinishDemand($FinishDemand: FinishDemand!) {
    finishDemand(FinishDemand: $FinishDemand) {
      id
      finish_date
    }
  }
`;
export interface FinishDemandMutation {
  finishDemand: Mutation["finishDemand"];
}
export class FinishDemandComponent extends React.Component<
  Partial<
    ReactApollo.MutationProps<FinishDemandMutation, FinishDemandMutationArgs>
  >
> {
  render() {
    return (
      <ReactApollo.Mutation<FinishDemandMutation, FinishDemandMutationArgs>
        mutation={FinishDemandDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type FinishDemandProps<TChildProps = any> = Partial<
  ReactApollo.MutateProps<FinishDemandMutation, FinishDemandMutationArgs>
> &
  TChildProps;
export type FinishDemandMutationFn = ReactApollo.MutationFn<
  FinishDemandMutation,
  FinishDemandMutationArgs
>;
export function FinishDemandHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        FinishDemandMutation,
        FinishDemandMutationArgs,
        FinishDemandProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    FinishDemandMutation,
    FinishDemandMutationArgs,
    FinishDemandProps<TChildProps>
  >(FinishDemandDocument, operationOptions);
}
export const GetDemandByAdminDocument = gql`
  query GetDemandByAdmin($id: String!) {
    getDemandByAdmin(id: $id) {
      id
      name
      status
      description
      ui_description
      color_description
      size_description
      remark
      finish_date
      created_date
      start_date
      updated_date
      finish_remark
      original_file_format
      file_format
      project_name
      logo_type
      logo_name
      client
      client_desc
      ui_description_remark
      description_files {
        uri
        name
      }
      ui_description_files {
        uri
        name
      }
      size_description_files {
        uri
        name
      }
      project {
        id
        title
        uri
        type
        content
        description
        cost_day
      }
      owner {
        id
        username
        headimgurl
      }
      team {
        id
        name
      }
    }
  }
`;
export interface GetDemandByAdminQuery {
  getDemandByAdmin: Query["getDemandByAdmin"];
}
export class GetDemandByAdminComponent extends React.Component<
  Partial<
    ReactApollo.QueryProps<GetDemandByAdminQuery, GetDemandByAdminQueryArgs>
  >
> {
  render() {
    return (
      <ReactApollo.Query<GetDemandByAdminQuery, GetDemandByAdminQueryArgs>
        query={GetDemandByAdminDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type GetDemandByAdminProps<TChildProps = any> = Partial<
  ReactApollo.DataProps<GetDemandByAdminQuery, GetDemandByAdminQueryArgs>
> &
  TChildProps;
export function GetDemandByAdminHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        GetDemandByAdminQuery,
        GetDemandByAdminQueryArgs,
        GetDemandByAdminProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    GetDemandByAdminQuery,
    GetDemandByAdminQueryArgs,
    GetDemandByAdminProps<TChildProps>
  >(GetDemandByAdminDocument, operationOptions);
}
export const GetDemandByIdDocument = gql`
  query GetDemandById($id: String!) {
    getDemandById(id: $id) {
      id
      name
      status
      description
      ui_description
      color_description
      size_description
      remark
      finish_date
      created_date
      start_date
      updated_date
      finish_remark
      original_file_format
      file_format
      project_name
      logo_type
      logo_name
      client
      client_desc
      ui_description_remark
      description_files {
        name
        uri
      }
      ui_description_files {
        name
        uri
      }
      size_description_files {
        name
        uri
      }
      project {
        id
        title
        uri
        type
        content
        description
        cost_day
      }
      owner {
        id
        username
        headimgurl
      }
    }
  }
`;
export interface GetDemandByIdQuery {
  getDemandById: Query["getDemandById"];
}
export class GetDemandByIdComponent extends React.Component<
  Partial<ReactApollo.QueryProps<GetDemandByIdQuery, GetDemandByIdQueryArgs>>
> {
  render() {
    return (
      <ReactApollo.Query<GetDemandByIdQuery, GetDemandByIdQueryArgs>
        query={GetDemandByIdDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type GetDemandByIdProps<TChildProps = any> = Partial<
  ReactApollo.DataProps<GetDemandByIdQuery, GetDemandByIdQueryArgs>
> &
  TChildProps;
export function GetDemandByIdHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        GetDemandByIdQuery,
        GetDemandByIdQueryArgs,
        GetDemandByIdProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    GetDemandByIdQuery,
    GetDemandByIdQueryArgs,
    GetDemandByIdProps<TChildProps>
  >(GetDemandByIdDocument, operationOptions);
}
export const CreateDesignerDocument = gql`
  mutation CreateDesigner($CreateDesigner: CreateDesigner!) {
    createDesigner(CreateDesigner: $CreateDesigner) {
      id
      username
      mobile
    }
  }
`;
export interface CreateDesignerMutation {
  createDesigner: Mutation["createDesigner"];
}
export class CreateDesignerComponent extends React.Component<
  Partial<
    ReactApollo.MutationProps<
      CreateDesignerMutation,
      CreateDesignerMutationArgs
    >
  >
> {
  render() {
    return (
      <ReactApollo.Mutation<CreateDesignerMutation, CreateDesignerMutationArgs>
        mutation={CreateDesignerDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type CreateDesignerProps<TChildProps = any> = Partial<
  ReactApollo.MutateProps<CreateDesignerMutation, CreateDesignerMutationArgs>
> &
  TChildProps;
export type CreateDesignerMutationFn = ReactApollo.MutationFn<
  CreateDesignerMutation,
  CreateDesignerMutationArgs
>;
export function CreateDesignerHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        CreateDesignerMutation,
        CreateDesignerMutationArgs,
        CreateDesignerProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    CreateDesignerMutation,
    CreateDesignerMutationArgs,
    CreateDesignerProps<TChildProps>
  >(CreateDesignerDocument, operationOptions);
}
export const DesignerListDocument = gql`
  query DesignerList($search: String, $role: String, $take: Int = 25) {
    designerList(take: $take, search: $search, role: $role) {
      count
      list {
        id
        username
        mobile
        demands_progress_count
        demands_count
        services_customs_count
        services_customs_list {
          name
          id
          plan
        }
      }
    }
  }
`;
export interface DesignerListQuery {
  designerList: Query["designerList"];
}
export class DesignerListComponent extends React.Component<
  Partial<ReactApollo.QueryProps<DesignerListQuery, DesignerListQueryArgs>>
> {
  render() {
    return (
      <ReactApollo.Query<DesignerListQuery, DesignerListQueryArgs>
        query={DesignerListDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type DesignerListProps<TChildProps = any> = Partial<
  ReactApollo.DataProps<DesignerListQuery, DesignerListQueryArgs>
> &
  TChildProps;
export function DesignerListHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        DesignerListQuery,
        DesignerListQueryArgs,
        DesignerListProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    DesignerListQuery,
    DesignerListQueryArgs,
    DesignerListProps<TChildProps>
  >(DesignerListDocument, operationOptions);
}
export const DesignersDocument = gql`
  query Designers {
    designers {
      id
      username
      mobile
    }
  }
`;
export interface DesignersQuery {
  designers: Query["designers"];
}
export class DesignersComponent extends React.Component<
  Partial<ReactApollo.QueryProps<DesignersQuery, any>>
> {
  render() {
    return (
      <ReactApollo.Query<DesignersQuery, any>
        query={DesignersDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type DesignersProps<TChildProps = any> = Partial<
  ReactApollo.DataProps<DesignersQuery, any>
> &
  TChildProps;
export function DesignersHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        DesignersQuery,
        any,
        DesignersProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    DesignersQuery,
    any,
    DesignersProps<TChildProps>
  >(DesignersDocument, operationOptions);
}
export const GetIndependentByAdminDocument = gql`
  query GetIndependentByAdmin($id: String!) {
    getIndependentByAdmin(id: $id) {
      id
      name
      description
      cycle
      budget
      created_date
      description_files {
        name
        uri
      }
      owner {
        id
        username
        headimgurl
      }
      team {
        id
        name
      }
    }
  }
`;
export interface GetIndependentByAdminQuery {
  getIndependentByAdmin: Query["getIndependentByAdmin"];
}
export class GetIndependentByAdminComponent extends React.Component<
  Partial<
    ReactApollo.QueryProps<
      GetIndependentByAdminQuery,
      GetIndependentByAdminQueryArgs
    >
  >
> {
  render() {
    return (
      <ReactApollo.Query<
        GetIndependentByAdminQuery,
        GetIndependentByAdminQueryArgs
      >
        query={GetIndependentByAdminDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type GetIndependentByAdminProps<TChildProps = any> = Partial<
  ReactApollo.DataProps<
    GetIndependentByAdminQuery,
    GetIndependentByAdminQueryArgs
  >
> &
  TChildProps;
export function GetIndependentByAdminHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        GetIndependentByAdminQuery,
        GetIndependentByAdminQueryArgs,
        GetIndependentByAdminProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    GetIndependentByAdminQuery,
    GetIndependentByAdminQueryArgs,
    GetIndependentByAdminProps<TChildProps>
  >(GetIndependentByAdminDocument, operationOptions);
}
export const IndependentsDocument = gql`
  query Independents($take: Int) {
    independents(take: $take) {
      count
      list {
        id
        name
        description
        cycle
        budget
        description_files {
          name
          uri
        }
        owner {
          id
          username
          headimgurl
        }
        team {
          id
          name
        }
      }
    }
  }
`;
export interface IndependentsQuery {
  independents: Query["independents"];
}
export class IndependentsComponent extends React.Component<
  Partial<ReactApollo.QueryProps<IndependentsQuery, IndependentsQueryArgs>>
> {
  render() {
    return (
      <ReactApollo.Query<IndependentsQuery, IndependentsQueryArgs>
        query={IndependentsDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type IndependentsProps<TChildProps = any> = Partial<
  ReactApollo.DataProps<IndependentsQuery, IndependentsQueryArgs>
> &
  TChildProps;
export function IndependentsHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        IndependentsQuery,
        IndependentsQueryArgs,
        IndependentsProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    IndependentsQuery,
    IndependentsQueryArgs,
    IndependentsProps<TChildProps>
  >(IndependentsDocument, operationOptions);
}
export const InvoicesDocument = gql`
  query Invoices($take: Int, $skip: Int) {
    invoices(take: $take, skip: $skip) {
      count
      list {
        id
        company_name
        amount
        invoice_type
        identification_number
        email
        phone
        owner {
          username
        }
      }
    }
  }
`;
export interface InvoicesQuery {
  invoices: Query["invoices"];
}
export class InvoicesComponent extends React.Component<
  Partial<ReactApollo.QueryProps<InvoicesQuery, InvoicesQueryArgs>>
> {
  render() {
    return (
      <ReactApollo.Query<InvoicesQuery, InvoicesQueryArgs>
        query={InvoicesDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type InvoicesProps<TChildProps = any> = Partial<
  ReactApollo.DataProps<InvoicesQuery, InvoicesQueryArgs>
> &
  TChildProps;
export function InvoicesHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        InvoicesQuery,
        InvoicesQueryArgs,
        InvoicesProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    InvoicesQuery,
    InvoicesQueryArgs,
    InvoicesProps<TChildProps>
  >(InvoicesDocument, operationOptions);
}
export const LoginByAdminDocument = gql`
  mutation LoginByAdmin($LoginByAdmin: LoginByAdmin!) {
    loginByAdmin(LoginByAdmin: $LoginByAdmin) {
      user_id
      expires_in
      access_token
    }
  }
`;
export interface LoginByAdminMutation {
  loginByAdmin: Mutation["loginByAdmin"];
}
export class LoginByAdminComponent extends React.Component<
  Partial<
    ReactApollo.MutationProps<LoginByAdminMutation, LoginByAdminMutationArgs>
  >
> {
  render() {
    return (
      <ReactApollo.Mutation<LoginByAdminMutation, LoginByAdminMutationArgs>
        mutation={LoginByAdminDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type LoginByAdminProps<TChildProps = any> = Partial<
  ReactApollo.MutateProps<LoginByAdminMutation, LoginByAdminMutationArgs>
> &
  TChildProps;
export type LoginByAdminMutationFn = ReactApollo.MutationFn<
  LoginByAdminMutation,
  LoginByAdminMutationArgs
>;
export function LoginByAdminHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        LoginByAdminMutation,
        LoginByAdminMutationArgs,
        LoginByAdminProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    LoginByAdminMutation,
    LoginByAdminMutationArgs,
    LoginByAdminProps<TChildProps>
  >(LoginByAdminDocument, operationOptions);
}
export const MembersDocument = gql`
  query Members($team_id: String, $take: Int) {
    members(team_id: $team_id, take: $take) {
      count
      list {
        id
        created_date
        team_id
        owner {
          id
          username
          mobile
          headimgurl
        }
      }
    }
  }
`;
export interface MembersQuery {
  members: Query["members"];
}
export class MembersComponent extends React.Component<
  Partial<ReactApollo.QueryProps<MembersQuery, MembersQueryArgs>>
> {
  render() {
    return (
      <ReactApollo.Query<MembersQuery, MembersQueryArgs>
        query={MembersDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type MembersProps<TChildProps = any> = Partial<
  ReactApollo.DataProps<MembersQuery, MembersQueryArgs>
> &
  TChildProps;
export function MembersHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        MembersQuery,
        MembersQueryArgs,
        MembersProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    MembersQuery,
    MembersQueryArgs,
    MembersProps<TChildProps>
  >(MembersDocument, operationOptions);
}
export const MoveMemberDocument = gql`
  mutation MoveMember(
    $user_id: String!
    $to_team_id: String!
    $old_team_id: String!
  ) {
    moveMember(
      user_id: $user_id
      to_team_id: $to_team_id
      old_team_id: $old_team_id
    ) {
      id
    }
  }
`;
export interface MoveMemberMutation {
  moveMember: Mutation["moveMember"];
}
export class MoveMemberComponent extends React.Component<
  Partial<ReactApollo.MutationProps<MoveMemberMutation, MoveMemberMutationArgs>>
> {
  render() {
    return (
      <ReactApollo.Mutation<MoveMemberMutation, MoveMemberMutationArgs>
        mutation={MoveMemberDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type MoveMemberProps<TChildProps = any> = Partial<
  ReactApollo.MutateProps<MoveMemberMutation, MoveMemberMutationArgs>
> &
  TChildProps;
export type MoveMemberMutationFn = ReactApollo.MutationFn<
  MoveMemberMutation,
  MoveMemberMutationArgs
>;
export function MoveMemberHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        MoveMemberMutation,
        MoveMemberMutationArgs,
        MoveMemberProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    MoveMemberMutation,
    MoveMemberMutationArgs,
    MoveMemberProps<TChildProps>
  >(MoveMemberDocument, operationOptions);
}
export const BackOrderByAdminDocument = gql`
  mutation BackOrderByAdmin($ChangeOrderStatus: ChangeOrderStatus!) {
    backOrderByAdmin(ChangeOrderStatus: $ChangeOrderStatus) {
      id
      status
    }
  }
`;
export interface BackOrderByAdminMutation {
  backOrderByAdmin: Mutation["backOrderByAdmin"];
}
export class BackOrderByAdminComponent extends React.Component<
  Partial<
    ReactApollo.MutationProps<
      BackOrderByAdminMutation,
      BackOrderByAdminMutationArgs
    >
  >
> {
  render() {
    return (
      <ReactApollo.Mutation<
        BackOrderByAdminMutation,
        BackOrderByAdminMutationArgs
      >
        mutation={BackOrderByAdminDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type BackOrderByAdminProps<TChildProps = any> = Partial<
  ReactApollo.MutateProps<
    BackOrderByAdminMutation,
    BackOrderByAdminMutationArgs
  >
> &
  TChildProps;
export type BackOrderByAdminMutationFn = ReactApollo.MutationFn<
  BackOrderByAdminMutation,
  BackOrderByAdminMutationArgs
>;
export function BackOrderByAdminHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        BackOrderByAdminMutation,
        BackOrderByAdminMutationArgs,
        BackOrderByAdminProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    BackOrderByAdminMutation,
    BackOrderByAdminMutationArgs,
    BackOrderByAdminProps<TChildProps>
  >(BackOrderByAdminDocument, operationOptions);
}
export const ChangeOrderStatusByAdminDocument = gql`
  mutation ChangeOrderStatusByAdmin($ChangeOrderStatus: ChangeOrderStatus!) {
    changeOrderStatusByAdmin(ChangeOrderStatus: $ChangeOrderStatus) {
      id
      status
    }
  }
`;
export interface ChangeOrderStatusByAdminMutation {
  changeOrderStatusByAdmin: Mutation["changeOrderStatusByAdmin"];
}
export class ChangeOrderStatusByAdminComponent extends React.Component<
  Partial<
    ReactApollo.MutationProps<
      ChangeOrderStatusByAdminMutation,
      ChangeOrderStatusByAdminMutationArgs
    >
  >
> {
  render() {
    return (
      <ReactApollo.Mutation<
        ChangeOrderStatusByAdminMutation,
        ChangeOrderStatusByAdminMutationArgs
      >
        mutation={ChangeOrderStatusByAdminDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type ChangeOrderStatusByAdminProps<TChildProps = any> = Partial<
  ReactApollo.MutateProps<
    ChangeOrderStatusByAdminMutation,
    ChangeOrderStatusByAdminMutationArgs
  >
> &
  TChildProps;
export type ChangeOrderStatusByAdminMutationFn = ReactApollo.MutationFn<
  ChangeOrderStatusByAdminMutation,
  ChangeOrderStatusByAdminMutationArgs
>;
export function ChangeOrderStatusByAdminHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        ChangeOrderStatusByAdminMutation,
        ChangeOrderStatusByAdminMutationArgs,
        ChangeOrderStatusByAdminProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    ChangeOrderStatusByAdminMutation,
    ChangeOrderStatusByAdminMutationArgs,
    ChangeOrderStatusByAdminProps<TChildProps>
  >(ChangeOrderStatusByAdminDocument, operationOptions);
}
export const CreateOrderItemDocument = gql`
  mutation CreateOrderItem($CreateOrderItem: CreateOrderItem!) {
    createOrderItem(CreateOrderItem: $CreateOrderItem) {
      id
    }
  }
`;
export interface CreateOrderItemMutation {
  createOrderItem: Mutation["createOrderItem"];
}
export class CreateOrderItemComponent extends React.Component<
  Partial<
    ReactApollo.MutationProps<
      CreateOrderItemMutation,
      CreateOrderItemMutationArgs
    >
  >
> {
  render() {
    return (
      <ReactApollo.Mutation<
        CreateOrderItemMutation,
        CreateOrderItemMutationArgs
      >
        mutation={CreateOrderItemDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type CreateOrderItemProps<TChildProps = any> = Partial<
  ReactApollo.MutateProps<CreateOrderItemMutation, CreateOrderItemMutationArgs>
> &
  TChildProps;
export type CreateOrderItemMutationFn = ReactApollo.MutationFn<
  CreateOrderItemMutation,
  CreateOrderItemMutationArgs
>;
export function CreateOrderItemHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        CreateOrderItemMutation,
        CreateOrderItemMutationArgs,
        CreateOrderItemProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    CreateOrderItemMutation,
    CreateOrderItemMutationArgs,
    CreateOrderItemProps<TChildProps>
  >(CreateOrderItemDocument, operationOptions);
}
export const DeleteOrderItemDocument = gql`
  mutation DeleteOrderItem($id: String!) {
    deleteOrderItem(id: $id) {
      id
    }
  }
`;
export interface DeleteOrderItemMutation {
  deleteOrderItem: Mutation["deleteOrderItem"];
}
export class DeleteOrderItemComponent extends React.Component<
  Partial<
    ReactApollo.MutationProps<
      DeleteOrderItemMutation,
      DeleteOrderItemMutationArgs
    >
  >
> {
  render() {
    return (
      <ReactApollo.Mutation<
        DeleteOrderItemMutation,
        DeleteOrderItemMutationArgs
      >
        mutation={DeleteOrderItemDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type DeleteOrderItemProps<TChildProps = any> = Partial<
  ReactApollo.MutateProps<DeleteOrderItemMutation, DeleteOrderItemMutationArgs>
> &
  TChildProps;
export type DeleteOrderItemMutationFn = ReactApollo.MutationFn<
  DeleteOrderItemMutation,
  DeleteOrderItemMutationArgs
>;
export function DeleteOrderItemHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        DeleteOrderItemMutation,
        DeleteOrderItemMutationArgs,
        DeleteOrderItemProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    DeleteOrderItemMutation,
    DeleteOrderItemMutationArgs,
    DeleteOrderItemProps<TChildProps>
  >(DeleteOrderItemDocument, operationOptions);
}
export const NotPassOrderDocument = gql`
  mutation NotPassOrder($NotPassOrder: NotPassOrder!) {
    notPassOrder(NotPassOrder: $NotPassOrder) {
      id
      status
      reason
    }
  }
`;
export interface NotPassOrderMutation {
  notPassOrder: Mutation["notPassOrder"];
}
export class NotPassOrderComponent extends React.Component<
  Partial<
    ReactApollo.MutationProps<NotPassOrderMutation, NotPassOrderMutationArgs>
  >
> {
  render() {
    return (
      <ReactApollo.Mutation<NotPassOrderMutation, NotPassOrderMutationArgs>
        mutation={NotPassOrderDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type NotPassOrderProps<TChildProps = any> = Partial<
  ReactApollo.MutateProps<NotPassOrderMutation, NotPassOrderMutationArgs>
> &
  TChildProps;
export type NotPassOrderMutationFn = ReactApollo.MutationFn<
  NotPassOrderMutation,
  NotPassOrderMutationArgs
>;
export function NotPassOrderHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        NotPassOrderMutation,
        NotPassOrderMutationArgs,
        NotPassOrderProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    NotPassOrderMutation,
    NotPassOrderMutationArgs,
    NotPassOrderProps<TChildProps>
  >(NotPassOrderDocument, operationOptions);
}
export const OrdersDocument = gql`
  query Orders(
    $take: Int
    $team_id: String
    $order: OrderFilterOrder
    $status: [String!]
    $pay_type: [String!]
    $type: [String!]
    $skip: Int
  ) {
    orders(
      take: $take
      team_id: $team_id
      order: $order
      status: $status
      pay_type: $pay_type
      type: $type
      skip: $skip
    ) {
      count
      list {
        id
        out_trade_no
        status
        created_date
        type
        detail
        pay_uri
        old_price
        price
        pay_type
        pay_date
        owner {
          username
        }
        team {
          id
          name
        }
        pay_user {
          id
          username
        }
        children: items {
          key: id
          id
          type
          detail
          old_price: price
          order_type
          out_trade_no
        }
      }
    }
  }
`;
export interface OrdersQuery {
  orders: Query["orders"];
}
export class OrdersComponent extends React.Component<
  Partial<ReactApollo.QueryProps<OrdersQuery, OrdersQueryArgs>>
> {
  render() {
    return (
      <ReactApollo.Query<OrdersQuery, OrdersQueryArgs>
        query={OrdersDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type OrdersProps<TChildProps = any> = Partial<
  ReactApollo.DataProps<OrdersQuery, OrdersQueryArgs>
> &
  TChildProps;
export function OrdersHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        OrdersQuery,
        OrdersQueryArgs,
        OrdersProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    OrdersQuery,
    OrdersQueryArgs,
    OrdersProps<TChildProps>
  >(OrdersDocument, operationOptions);
}
export const ProjectsDocument = gql`
  query Projects($take: Int) {
    projects(take: $take) {
      count
      list {
        id
        title
        uri
        type
        content
        description
        cost_day
        item_type
      }
    }
  }
`;
export interface ProjectsQuery {
  projects: Query["projects"];
}
export class ProjectsComponent extends React.Component<
  Partial<ReactApollo.QueryProps<ProjectsQuery, ProjectsQueryArgs>>
> {
  render() {
    return (
      <ReactApollo.Query<ProjectsQuery, ProjectsQueryArgs>
        query={ProjectsDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type ProjectsProps<TChildProps = any> = Partial<
  ReactApollo.DataProps<ProjectsQuery, ProjectsQueryArgs>
> &
  TChildProps;
export function ProjectsHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        ProjectsQuery,
        ProjectsQueryArgs,
        ProjectsProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    ProjectsQuery,
    ProjectsQueryArgs,
    ProjectsProps<TChildProps>
  >(ProjectsDocument, operationOptions);
}
export const RequestUploadOssFileDocument = gql`
  query RequestUploadOSSFile($file_name: String!) {
    requestUploadOSSFile(file_name: $file_name) {
      access_key_id
      policy
      signature
      key
      host
      expiration
      dir
      callback
    }
  }
`;
export interface RequestUploadOssFileQuery {
  requestUploadOSSFile: Query["requestUploadOSSFile"];
}
export class RequestUploadOssFileComponent extends React.Component<
  Partial<
    ReactApollo.QueryProps<
      RequestUploadOssFileQuery,
      RequestUploadOssFileQueryArgs
    >
  >
> {
  render() {
    return (
      <ReactApollo.Query<
        RequestUploadOssFileQuery,
        RequestUploadOssFileQueryArgs
      >
        query={RequestUploadOssFileDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type RequestUploadOssFileProps<TChildProps = any> = Partial<
  ReactApollo.DataProps<
    RequestUploadOssFileQuery,
    RequestUploadOssFileQueryArgs
  >
> &
  TChildProps;
export function RequestUploadOssFileHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        RequestUploadOssFileQuery,
        RequestUploadOssFileQueryArgs,
        RequestUploadOssFileProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    RequestUploadOssFileQuery,
    RequestUploadOssFileQueryArgs,
    RequestUploadOssFileProps<TChildProps>
  >(RequestUploadOssFileDocument, operationOptions);
}
export const SignWeChatDocument = gql`
  mutation SignWeChat($SignWeChatInput: SignWeChatInput!) {
    signWeChat(SignWeChatInput: $SignWeChatInput) {
      debug
      appId
      timestamp
      nonceStr
      signature
      jsApiList
    }
  }
`;
export interface SignWeChatMutation {
  signWeChat: Mutation["signWeChat"];
}
export class SignWeChatComponent extends React.Component<
  Partial<ReactApollo.MutationProps<SignWeChatMutation, SignWeChatMutationArgs>>
> {
  render() {
    return (
      <ReactApollo.Mutation<SignWeChatMutation, SignWeChatMutationArgs>
        mutation={SignWeChatDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type SignWeChatProps<TChildProps = any> = Partial<
  ReactApollo.MutateProps<SignWeChatMutation, SignWeChatMutationArgs>
> &
  TChildProps;
export type SignWeChatMutationFn = ReactApollo.MutationFn<
  SignWeChatMutation,
  SignWeChatMutationArgs
>;
export function SignWeChatHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        SignWeChatMutation,
        SignWeChatMutationArgs,
        SignWeChatProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    SignWeChatMutation,
    SignWeChatMutationArgs,
    SignWeChatProps<TChildProps>
  >(SignWeChatDocument, operationOptions);
}
export const SubscriptionsDocument = gql`
  query Subscriptions($team_id: String, $take: Int) {
    subscriptions(team_id: $team_id, take: $take) {
      count
      list {
        id
        name
        count
        end_date
        created_date
        cycle_name
        price
        pay_type
        start_date
      }
    }
  }
`;
export interface SubscriptionsQuery {
  subscriptions: Query["subscriptions"];
}
export class SubscriptionsComponent extends React.Component<
  Partial<ReactApollo.QueryProps<SubscriptionsQuery, SubscriptionsQueryArgs>>
> {
  render() {
    return (
      <ReactApollo.Query<SubscriptionsQuery, SubscriptionsQueryArgs>
        query={SubscriptionsDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type SubscriptionsProps<TChildProps = any> = Partial<
  ReactApollo.DataProps<SubscriptionsQuery, SubscriptionsQueryArgs>
> &
  TChildProps;
export function SubscriptionsHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        SubscriptionsQuery,
        SubscriptionsQueryArgs,
        SubscriptionsProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    SubscriptionsQuery,
    SubscriptionsQueryArgs,
    SubscriptionsProps<TChildProps>
  >(SubscriptionsDocument, operationOptions);
}
export const AppointTeamDesignerDocument = gql`
  mutation AppointTeamDesigner($AppointTeamDesigner: AppointTeamDesigner!) {
    appointTeamDesigner(AppointTeamDesigner: $AppointTeamDesigner) {
      id
    }
  }
`;
export interface AppointTeamDesignerMutation {
  appointTeamDesigner: Mutation["appointTeamDesigner"];
}
export class AppointTeamDesignerComponent extends React.Component<
  Partial<
    ReactApollo.MutationProps<
      AppointTeamDesignerMutation,
      AppointTeamDesignerMutationArgs
    >
  >
> {
  render() {
    return (
      <ReactApollo.Mutation<
        AppointTeamDesignerMutation,
        AppointTeamDesignerMutationArgs
      >
        mutation={AppointTeamDesignerDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type AppointTeamDesignerProps<TChildProps = any> = Partial<
  ReactApollo.MutateProps<
    AppointTeamDesignerMutation,
    AppointTeamDesignerMutationArgs
  >
> &
  TChildProps;
export type AppointTeamDesignerMutationFn = ReactApollo.MutationFn<
  AppointTeamDesignerMutation,
  AppointTeamDesignerMutationArgs
>;
export function AppointTeamDesignerHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        AppointTeamDesignerMutation,
        AppointTeamDesignerMutationArgs,
        AppointTeamDesignerProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    AppointTeamDesignerMutation,
    AppointTeamDesignerMutationArgs,
    AppointTeamDesignerProps<TChildProps>
  >(AppointTeamDesignerDocument, operationOptions);
}
export const ChangeTeamEndDateDocument = gql`
  mutation ChangeTeamEndDate($ChangeTeamEndDate: ChangeTeamEndDate!) {
    changeTeamEndDate(ChangeTeamEndDate: $ChangeTeamEndDate) {
      id
      plan_end_date
    }
  }
`;
export interface ChangeTeamEndDateMutation {
  changeTeamEndDate: Mutation["changeTeamEndDate"];
}
export class ChangeTeamEndDateComponent extends React.Component<
  Partial<
    ReactApollo.MutationProps<
      ChangeTeamEndDateMutation,
      ChangeTeamEndDateMutationArgs
    >
  >
> {
  render() {
    return (
      <ReactApollo.Mutation<
        ChangeTeamEndDateMutation,
        ChangeTeamEndDateMutationArgs
      >
        mutation={ChangeTeamEndDateDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type ChangeTeamEndDateProps<TChildProps = any> = Partial<
  ReactApollo.MutateProps<
    ChangeTeamEndDateMutation,
    ChangeTeamEndDateMutationArgs
  >
> &
  TChildProps;
export type ChangeTeamEndDateMutationFn = ReactApollo.MutationFn<
  ChangeTeamEndDateMutation,
  ChangeTeamEndDateMutationArgs
>;
export function ChangeTeamEndDateHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        ChangeTeamEndDateMutation,
        ChangeTeamEndDateMutationArgs,
        ChangeTeamEndDateProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    ChangeTeamEndDateMutation,
    ChangeTeamEndDateMutationArgs,
    ChangeTeamEndDateProps<TChildProps>
  >(ChangeTeamEndDateDocument, operationOptions);
}
export const ChangeTeamNameDocument = gql`
  mutation ChangeTeamName($new_name: String!, $team_id: String!) {
    changeTeamName(new_name: $new_name, team_id: $team_id) {
      id
    }
  }
`;
export interface ChangeTeamNameMutation {
  changeTeamName: Mutation["changeTeamName"];
}
export class ChangeTeamNameComponent extends React.Component<
  Partial<
    ReactApollo.MutationProps<
      ChangeTeamNameMutation,
      ChangeTeamNameMutationArgs
    >
  >
> {
  render() {
    return (
      <ReactApollo.Mutation<ChangeTeamNameMutation, ChangeTeamNameMutationArgs>
        mutation={ChangeTeamNameDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type ChangeTeamNameProps<TChildProps = any> = Partial<
  ReactApollo.MutateProps<ChangeTeamNameMutation, ChangeTeamNameMutationArgs>
> &
  TChildProps;
export type ChangeTeamNameMutationFn = ReactApollo.MutationFn<
  ChangeTeamNameMutation,
  ChangeTeamNameMutationArgs
>;
export function ChangeTeamNameHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        ChangeTeamNameMutation,
        ChangeTeamNameMutationArgs,
        ChangeTeamNameProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    ChangeTeamNameMutation,
    ChangeTeamNameMutationArgs,
    ChangeTeamNameProps<TChildProps>
  >(ChangeTeamNameDocument, operationOptions);
}
export const CreateTeamUriByIdDocument = gql`
  query CreateTeamUriById($id: String!) {
    createTeamUriById(id: $id) {
      uri
    }
  }
`;
export interface CreateTeamUriByIdQuery {
  createTeamUriById: Query["createTeamUriById"];
}
export class CreateTeamUriByIdComponent extends React.Component<
  Partial<
    ReactApollo.QueryProps<CreateTeamUriByIdQuery, CreateTeamUriByIdQueryArgs>
  >
> {
  render() {
    return (
      <ReactApollo.Query<CreateTeamUriByIdQuery, CreateTeamUriByIdQueryArgs>
        query={CreateTeamUriByIdDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type CreateTeamUriByIdProps<TChildProps = any> = Partial<
  ReactApollo.DataProps<CreateTeamUriByIdQuery, CreateTeamUriByIdQueryArgs>
> &
  TChildProps;
export function CreateTeamUriByIdHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        CreateTeamUriByIdQuery,
        CreateTeamUriByIdQueryArgs,
        CreateTeamUriByIdProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    CreateTeamUriByIdQuery,
    CreateTeamUriByIdQueryArgs,
    CreateTeamUriByIdProps<TChildProps>
  >(CreateTeamUriByIdDocument, operationOptions);
}
export const DeleteTeamDocument = gql`
  mutation DeleteTeam($id: String!) {
    deleteTeam(id: $id) {
      id
    }
  }
`;
export interface DeleteTeamMutation {
  deleteTeam: Mutation["deleteTeam"];
}
export class DeleteTeamComponent extends React.Component<
  Partial<ReactApollo.MutationProps<DeleteTeamMutation, DeleteTeamMutationArgs>>
> {
  render() {
    return (
      <ReactApollo.Mutation<DeleteTeamMutation, DeleteTeamMutationArgs>
        mutation={DeleteTeamDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type DeleteTeamProps<TChildProps = any> = Partial<
  ReactApollo.MutateProps<DeleteTeamMutation, DeleteTeamMutationArgs>
> &
  TChildProps;
export type DeleteTeamMutationFn = ReactApollo.MutationFn<
  DeleteTeamMutation,
  DeleteTeamMutationArgs
>;
export function DeleteTeamHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        DeleteTeamMutation,
        DeleteTeamMutationArgs,
        DeleteTeamProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    DeleteTeamMutation,
    DeleteTeamMutationArgs,
    DeleteTeamProps<TChildProps>
  >(DeleteTeamDocument, operationOptions);
}
export const GetTeamByIdDocument = gql`
  query GetTeamById($id: String!) {
    getTeamById(id: $id) {
      id
      name
      balance
      plan
      plan_count
      plan_end_date
      created_date
      owner {
        id
        username
        mobile
        headimgurl
      }
      subscriptions {
        id
        name
        count
        end_date
        start_date
        created_date
      }
    }
  }
`;
export interface GetTeamByIdQuery {
  getTeamById: Query["getTeamById"];
}
export class GetTeamByIdComponent extends React.Component<
  Partial<ReactApollo.QueryProps<GetTeamByIdQuery, GetTeamByIdQueryArgs>>
> {
  render() {
    return (
      <ReactApollo.Query<GetTeamByIdQuery, GetTeamByIdQueryArgs>
        query={GetTeamByIdDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type GetTeamByIdProps<TChildProps = any> = Partial<
  ReactApollo.DataProps<GetTeamByIdQuery, GetTeamByIdQueryArgs>
> &
  TChildProps;
export function GetTeamByIdHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        GetTeamByIdQuery,
        GetTeamByIdQueryArgs,
        GetTeamByIdProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    GetTeamByIdQuery,
    GetTeamByIdQueryArgs,
    GetTeamByIdProps<TChildProps>
  >(GetTeamByIdDocument, operationOptions);
}
export const GetTeamOldNamesByIdDocument = gql`
  query GetTeamOldNamesById($id: String!) {
    getTeamById(id: $id) {
      id
      name
      old_team_names {
        id
        old_name
        new_name
        created_date
        user {
          id
          nickname
          username
          headimgurl
        }
      }
    }
  }
`;
export interface GetTeamOldNamesByIdQuery {
  getTeamById: Query["getTeamById"];
}
export class GetTeamOldNamesByIdComponent extends React.Component<
  Partial<
    ReactApollo.QueryProps<
      GetTeamOldNamesByIdQuery,
      GetTeamOldNamesByIdQueryArgs
    >
  >
> {
  render() {
    return (
      <ReactApollo.Query<GetTeamOldNamesByIdQuery, GetTeamOldNamesByIdQueryArgs>
        query={GetTeamOldNamesByIdDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type GetTeamOldNamesByIdProps<TChildProps = any> = Partial<
  ReactApollo.DataProps<GetTeamOldNamesByIdQuery, GetTeamOldNamesByIdQueryArgs>
> &
  TChildProps;
export function GetTeamOldNamesByIdHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        GetTeamOldNamesByIdQuery,
        GetTeamOldNamesByIdQueryArgs,
        GetTeamOldNamesByIdProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    GetTeamOldNamesByIdQuery,
    GetTeamOldNamesByIdQueryArgs,
    GetTeamOldNamesByIdProps<TChildProps>
  >(GetTeamOldNamesByIdDocument, operationOptions);
}
export const TeamsDocument = gql`
  query Teams(
    $take: Int
    $skip: Int
    $search: String
    $range: String
    $order: TeamFilterOrder
  ) {
    teams(
      take: $take
      skip: $skip
      order: $order
      search: $search
      range: $range
    ) {
      count
      list {
        id
        name
        balance
        created_date
        plan_end_date
        card_uri
        oss_card_uri
        owner {
          id
          username
          mobile
        }
        designer {
          id
          username
          mobile
        }
        designerAdviser {
          id
          username
          mobile
        }
        subscriptions {
          id
          name
          count
          end_date
        }
      }
    }
  }
`;
export interface TeamsQuery {
  teams: Query["teams"];
}
export class TeamsComponent extends React.Component<
  Partial<ReactApollo.QueryProps<TeamsQuery, TeamsQueryArgs>>
> {
  render() {
    return (
      <ReactApollo.Query<TeamsQuery, TeamsQueryArgs>
        query={TeamsDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type TeamsProps<TChildProps = any> = Partial<
  ReactApollo.DataProps<TeamsQuery, TeamsQueryArgs>
> &
  TChildProps;
export function TeamsHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        TeamsQuery,
        TeamsQueryArgs,
        TeamsProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    TeamsQuery,
    TeamsQueryArgs,
    TeamsProps<TChildProps>
  >(TeamsDocument, operationOptions);
}
export const UploadTeamCardUriDocument = gql`
  mutation UploadTeamCardUri($id: String!, $card_uri: String!) {
    uploadTeamCardUri(id: $id, card_uri: $card_uri) {
      id
      card_uri
    }
  }
`;
export interface UploadTeamCardUriMutation {
  uploadTeamCardUri: Mutation["uploadTeamCardUri"];
}
export class UploadTeamCardUriComponent extends React.Component<
  Partial<
    ReactApollo.MutationProps<
      UploadTeamCardUriMutation,
      UploadTeamCardUriMutationArgs
    >
  >
> {
  render() {
    return (
      <ReactApollo.Mutation<
        UploadTeamCardUriMutation,
        UploadTeamCardUriMutationArgs
      >
        mutation={UploadTeamCardUriDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type UploadTeamCardUriProps<TChildProps = any> = Partial<
  ReactApollo.MutateProps<
    UploadTeamCardUriMutation,
    UploadTeamCardUriMutationArgs
  >
> &
  TChildProps;
export type UploadTeamCardUriMutationFn = ReactApollo.MutationFn<
  UploadTeamCardUriMutation,
  UploadTeamCardUriMutationArgs
>;
export function UploadTeamCardUriHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        UploadTeamCardUriMutation,
        UploadTeamCardUriMutationArgs,
        UploadTeamCardUriProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    UploadTeamCardUriMutation,
    UploadTeamCardUriMutationArgs,
    UploadTeamCardUriProps<TChildProps>
  >(UploadTeamCardUriDocument, operationOptions);
}
export const UsersDocument = gql`
  query Users($take: Int = 25) {
    users(take: $take) {
      count
      list {
        id
        username
      }
    }
  }
`;
export interface UsersQuery {
  users: Query["users"];
}
export class UsersComponent extends React.Component<
  Partial<ReactApollo.QueryProps<UsersQuery, UsersQueryArgs>>
> {
  render() {
    return (
      <ReactApollo.Query<UsersQuery, UsersQueryArgs>
        query={UsersDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type UsersProps<TChildProps = any> = Partial<
  ReactApollo.DataProps<UsersQuery, UsersQueryArgs>
> &
  TChildProps;
export function UsersHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        UsersQuery,
        UsersQueryArgs,
        UsersProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    UsersQuery,
    UsersQueryArgs,
    UsersProps<TChildProps>
  >(UsersDocument, operationOptions);
}
export const ViewerDocument = gql`
  query Viewer {
    viewer {
      id
    }
  }
`;
export interface ViewerQuery {
  viewer: Query["viewer"];
}
export class ViewerComponent extends React.Component<
  Partial<ReactApollo.QueryProps<ViewerQuery, any>>
> {
  render() {
    return (
      <ReactApollo.Query<ViewerQuery, any>
        query={ViewerDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type ViewerProps<TChildProps = any> = Partial<
  ReactApollo.DataProps<ViewerQuery, any>
> &
  TChildProps;
export function ViewerHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        ViewerQuery,
        any,
        ViewerProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    ViewerQuery,
    any,
    ViewerProps<TChildProps>
  >(ViewerDocument, operationOptions);
}
