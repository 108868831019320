import React from 'react';

import {
  BaseTable,
} from '../../components/base-table';
import { DemandsComponent, DemandsCountComponent } from '../../apollo';
import { AllTable } from '../../components/base-table';
import { BaseLayout } from '../../layout/base-layout';
import { columns } from './demand.columns';
import { Tabs, Row, Col, Button } from 'antd';
import { ChangeDemandTimeModalForm } from '../company/demand/change-demand-time-modal-form';
import { ChangeDemandDesignerModalForm } from '../company/demand/change-demand-designer-modal-form';
import { Link } from 'react-router-dom';

const { TabPane } = Tabs;

const tabarList = [
  { key: '1', value: '进行中' },
  { key: '4', value: '已提交' },
  { key: '2', value: '已完成' },
  { key: '3', value: '已取消' },
]

function getData(data: any) {
  const name = 'demands';
  const new_data =
    data[name] && data[name].list
      ? data[name].list.map((item: any) => {
        return {
          ...item,
          key: item.id,
          status: item.status,
          name: item.name,
          created_date: item.created_date,
          end_date: item.end_date,
          type: item.project.title,
          owner_username: item.owner?item.owner.username:'',
          designer_username: item.designer && item.designer.username,
          company_name: item.team.name,
        };
      })
      : [];
  const count = (data[name] && data[name].count) || 0;
  return {
    data: new_data,
    count,
  };
}

const columns_titles = columns().map(({ title }) => title);

const base_titles = columns_titles;

class DemandTable extends BaseTable {
  state = {
    checked_options: [...base_titles, '完成时间'],
    query_params: {},
    fixed: false,
  };

  onCheckAllChange = (e: any) => {
    this.setState({
      checked_options: e.target.checked ? columns_titles : [],
    });
  };

  onColumnChange = (checkedList: any) => {
    this.setState({
      checked_options: checkedList,
    });
  };

  render() {
    // const params = queryString.parse(this.props.location.search);
    // const skip = Number(params.skip) || 0;
    const { status } = this.props;
    return (
      <DemandsComponent
        variables={{
          // skip,
          take: 20,
          status,
          ...this.state.query_params,
        }}
        fetchPolicy="no-cache"
      >
        {({ data, error,loading, refetch }) => {
          if (error) {
            console.log('error', error);
            return <>Access denied</>;
          }
          const result = getData(data);
          let new_columns: any = columns(refetch).map((item) => {
            if (item.key === 'finish_date' && status === '已完成') {
              return {
                ...item,
                title: '完成时间',
              };
            }
            if (
              (status === '已提交' || status === '进行中') &&
              item.key === 'action'
            ) {
              return {
                ...item,
                render: (_: string, record: any) => (
                  <Row type="flex" justify="start" gutter={12}>
                    <Col span={8}>
                      <ChangeDemandTimeModalForm
                        id={record.id}
                        refetch={refetch}
                        finish_date={record.finish_date}
                      />
                    </Col>
                    <Col span={8}>
                      <ChangeDemandDesignerModalForm
                        id={record.id}
                        refetch={refetch}
                      />
                    </Col>
                    <Col span={8}>
                      <Button>
                        <Link to={`/demand-chat?id=${record.key}`}>聊天</Link>
                      </Button>
                    </Col>
                  </Row>
                ),
              };
            }
            return item;
          });
          new_columns = new_columns.filter((item: any) => {
            if (status === '已取消' && item.key === 'finish_date') {
              return false;
            }
            if (
              (status === '已取消' || status === '已完成') &&
              item.key === 'action'
            ) {
              return false;
            }
            return item;
          });
          return (
            <div className="table_container">
              {/* <TableTitle name={`订阅需求管理`} number={result.count} /> */}
              {/* <TableTitle
                  name={`订阅需求管理(${result.count})`}
                  Right={
                    <Link
                      style={{
                        position: 'absolute',
                        right: '8px',
                        top: '12px',
                      }}
                      to={'/independent'}
                    >
                      查看独立需求
                    </Link>
                  }
                />
                <br /> */}
              {/* <TableSearch
                  search={(e: any) => this.search(e.target.value)}
                  options={columns_titles}
                  checked_options={this.state.checked_options}
                  onCheckAllChange={this.onCheckAllChange}
                  onColumnChange={this.onColumnChange}
                  outData={() => {}}
                  columns={this.state.checked_options}
                  fixed={this.state.fixed}
                  fixedName={(fixed: any) => {
                    this.setState({ fixed });
                  }}
                  params={{
                    skip,
                    take: 20,
                    ...this.state.query_params,
                  }}
                /> */}
              <AllTable
                scroll={{ x: true }}
                onChange={(pagination: any, filters: any, sorter: any) =>
                  this.onTableChange(pagination, filters, sorter, refetch)
                }
                columns={new_columns}
                loading={loading}
                dataSource={result.data}
                pagination={{
                  total: result.count,
                  pageSize: 20,
                }}
                checked_options={this.state.checked_options}
                fixed={this.state.fixed}
              />
            </div>
          );
        }}
      </DemandsComponent>
    );
  }
}

export default class Demand extends React.Component<any> {
  state = {
    activeKey: '1',
    panes: tabarList,
    status: '进行中'
  }
  onChange = (key: string,refetch:()=>void) => {
    refetch()
    this.state.panes.forEach(item => {
      if (item.key === key) {
        this.setState({ activeKey: key, status: item.value })
      }
    })
  }
  render() {
    return (
      <BaseLayout>
        <div style={{ position: 'relative' }}>
          {/* <Button
            onClick={() => this.props.history.push('/choose-type')}
            style={{
              position: 'absolute',
              right: '8px',
              top: '12px',
            }}
          >
            + 新增需求
          </Button> */}
          <br />
          <br />
          <br />
          <DemandsCountComponent>
            {({ data, loading, error,refetch }) => {
              if (!data || loading || error) {
                return '';
              }
              const new_data: any = data;
              return (
                <div style={{ position: 'relative' }}>
                  {/* <Tabs defaultActiveKey="1"> */}
                  <Tabs
                    onChange={(key) => this.onChange(key,refetch)}
                    activeKey={this.state.activeKey}
                  >
                    {this.state.panes.map(pane => (
                      <TabPane
                        tab={
                          pane.key === '1' ?
                            `进行中(${new_data.ing_count.count})`
                            :
                            pane.key === '2'
                              ?
                              `已完成(${new_data.finish_count.count})`
                              :
                              pane.key === '3'?
                              `已取消(${new_data.cancel_count.count})`
                              :
                              `已提交(${new_data.wait_count.count})`
                        }
                        key={pane.key}
                      >
                        <DemandTable status={this.state.status} />
                      </TabPane>
                    ))}
                    {/* <TabPane
                      tab={`进行中(${new_data.ing_count.count})`}
                      key="1"
                    >
                      <DemandTable status="进行中" />
                    </TabPane> */}
                    {/* <TabPane
                      tab={`已提交(${new_data.wait_count.count})`}
                      key="2"
                    >
                      <DemandTable status="已提交" />
                    </TabPane>
                    <TabPane
                      tab={`已完成(${new_data.finish_count.count})`}
                      key="3"
                    >
                      <DemandTable status="已完成" />
                    </TabPane>
                    <TabPane
                      tab={`已取消(${new_data.cancel_count.count})`}
                      key="4"
                    >
                      <DemandTable status="已取消" />
                    </TabPane> */}
                  </Tabs>
                  {/* <Link
                    style={{
                      position: 'absolute',
                      right: '8px',
                      top: '12px',
                    }}
                    to={'/independent'}
                  >
                    查看独立需求
                  </Link> */}
                </div>
              );
            }}
          </DemandsCountComponent>
        </div>
      </BaseLayout>
    );
  }
}
