import React from 'react';
import {
  Button,
  Modal,
  Form,
  Select,
} from 'antd';

import {
  AppointDesignerComponent,
  DesignerListComponent,
} from '../../../../apollo';
import { message } from '../../../../components/message';

class ChangeDemandDesignerModal extends React.Component<any> {
  state = {
    visible: false,
    uri: '',
    query_params: {
      search: undefined,
    },
  };

  handleSubmit = (send: any, e: any) => {
    e.preventDefault();
    this.props.form.validateFields(async (err: any, values: any) => {
      if (!err) {
        try {
          await send({
            variables: {
              AppointDesigner: {
                id: this.props.id,
                designer_id: values.designer_id,
              },
            },
          });
          message.success('修改成功');
          const { refetch } = this.props;
          refetch();
          this.close();
        } catch (e) {
          message.error(e);
        }
      }
    });
  };

  close = () => {
    const { resetFields } = this.props.form;
    resetFields();
    this.setState({ visible: false, uri: '' });
  };

  render() {
    const { visible } = this.state;
    const { form } = this.props;
    const { getFieldDecorator } = form;
    return (
      <div>
        <Button onClick={() => this.setState({ visible: true })}>
          修改设计师
        </Button>
        <Modal
          title="修改设计师"
          centered
          visible={visible}
          onCancel={() => this.setState({ visible: false })}
          footer={null}
        >
          <Form>
            <DesignerListComponent
              variables={{
                skip: 0,
                take: 20,
                ...this.state.query_params,
              }}
              fetchPolicy="no-cache"
            >
              {({ data, error, refetch }) => {
                if (error) {
                  return <>Access denied</>;
                }
                if (!data || !data.designerList) {
                  return <>loading...</>;
                }
                const options =
                  data &&
                  data.designerList.list.map((d) => (
                    <Select.Option key={d.id}>{d.username}</Select.Option>
                  ));
                return (
                  <Form.Item label="修改为" style={{ display: 'flex' }}>
                    {getFieldDecorator('designer_id', {
                      rules: [{ required: true, message: '请选择设计师!' }],
                    })(
                      <Select
                        showSearch
                        // value={this.state.query_params.search}
                        placeholder={this.props.placeholder}
                        style={{ width: '300px' }}
                        defaultActiveFirstOption={false}
                        showArrow={false}
                        filterOption={false}
                        onSearch={(value) =>
                          this.setState({ query_params: { search: value } })
                        }
                        // onChange={(value) => this.setState({query_params: {search: value}})}
                        notFoundContent={null}
                      >
                        {options}
                      </Select>,
                    )}
                  </Form.Item>
                );
              }}
            </DesignerListComponent>
            <Form.Item style={{ textAlign: 'right' }}>
              <Button
                onClick={() => this.setState({ visible: false })}
                style={{ marginRight: '30px' }}
              >
                取消
              </Button>
              <AppointDesignerComponent>
                {(send, { loading }) => {
                  return (
                    <Button
                      type="primary"
                      loading={loading}
                      onClick={this.handleSubmit.bind(this, send)}
                    >
                      确定
                    </Button>
                  );
                }}
              </AppointDesignerComponent>
            </Form.Item>
          </Form>
        </Modal>
      </div>
    );
  }
}

export const ChangeDemandDesignerModalForm: any = Form.create({
  name: 'change-demand-time-modal-form',
})(ChangeDemandDesignerModal);
