import React, { Component } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';

import { PrivateRoute } from './private-route';
// import { LocationListener } from './LocationListener';
// import Index from '../pages/Index';
import Login from '../pages/login';
import Team from '../pages/team';
import Demand from '../pages/demand';
import Company from '../pages/company';
import Detail from '../pages/detail';
import IndependentDetail from '../pages/independent-detail';
import Independent from '../pages/independent';
import { CreateDemandForm } from '../pages/create-demand-new';
import { Order } from '../pages/order';
import Designer from '../pages/designer';
import ChatDetail from '../pages/demand-chat';
import Photo from '../pages/photo';
import ChooseDemandType from '../pages/choose-demand-type';
import ChooseType from '../pages/choose-type-new';
import { Invoice } from '../pages/invoice';

class Routers extends Component {
  render() {
    return (
      <Router>
        {/* <LocationListener> */}
        {/* <Route exact path="/" component={Index} /> */}
        <Route exact path="/login" component={Login} />
        <PrivateRoute exact path="/" component={Team} />
        <PrivateRoute exact path="/independent" component={Independent} />
        <PrivateRoute exact path="/team" component={Team} />
        <PrivateRoute exact path="/demand" component={Demand} />
        <PrivateRoute exact path="/company" component={Company} />
        <PrivateRoute exact path="/detail" component={Detail} />
        <PrivateRoute exact path="/order" component={Order} />
        <PrivateRoute exact path="/invoice" component={Invoice} />
        <PrivateRoute exact path="/independent-detail" component={IndependentDetail} />
        <PrivateRoute exact path="/choose-type" component={ChooseType} />
        <PrivateRoute exact path="/create-demand" component={CreateDemandForm} />
        <PrivateRoute exact path="/designer" component={Designer} />
        <PrivateRoute exact path="/demand-chat" component={ChatDetail} />
        <PrivateRoute exact path="/photo" component={Photo} />
        <PrivateRoute exact path="/choose-demand-type" component={Photo} />
        <PrivateRoute
          exact
          path="/choose-demand-type"
          component={ChooseDemandType}
        />
        {/* </LocationListener> */}
      </Router>
    );
  }
}

export default Routers;
