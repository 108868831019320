import React from 'react';
import { Form, Icon, Input, Button } from 'antd';

import './login.css';
import styles from './login.module.css';
import { Link, withRouter } from 'react-router-dom';
import { LoginByAdminComponent } from '../../apollo';
import { message } from '../../components/message';

class Login extends React.Component<any, any> {
  handleSubmit = (send: any, e: any) => {
    e.preventDefault();
    this.props.form.validateFields(async (err: any, values: any) => {
      if (!err) {
        try {
          const { data } = await send({
            variables: {
              LoginByAdmin: values,
            },
          });
          message.success('登录成功');
          localStorage.session = JSON.stringify(data.loginByAdmin);
          localStorage.token = data.loginByAdmin.access_token;
          this.props.history.push('/');
        } catch (e) {
          message.error(e);
        }
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <div className={styles.container}>
        <div className={styles.content}>
          <div className={styles.top}>
            <div className={styles.header}>
              <Link to="/">
                {/* <img alt="logo" className={styles.logo} src={logo} /> */}
                <span className={styles.title}>时间伙伴</span>
              </Link>
            </div>
            <div className={styles.desc}>时间伙伴后台管理</div>
          </div>
          <div className={styles.main}>
            <Form className="login-form">
              <Form.Item>
                {getFieldDecorator('mobile', {
                  rules: [{ required: true, message: '请输入手机号!' }],
                })(
                  <Input
                    prefix={
                      <Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />
                    }
                    placeholder="手机号"
                  />,
                )}
              </Form.Item>
              <Form.Item>
                {getFieldDecorator('password', {
                  rules: [{ required: true, message: '请输入密码!' }],
                })(
                  <Input
                    prefix={
                      <Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />
                    }
                    type="password"
                    placeholder="密码"
                  />,
                )}
              </Form.Item>
              <Form.Item>
                <LoginByAdminComponent>
                  {(send, { loading }) => {
                    return (
                      <Button
                        type="primary"
                        loading={loading}
                        onClick={this.handleSubmit.bind(this, send)}
                        className="login-form-button"
                      >
                        登录
                      </Button>
                    );
                  }}
                </LoginByAdminComponent>
              </Form.Item>
            </Form>
          </div>
        </div>
        {/* <Footer /> */}
      </div>
    );
  }
}

const WrappedNormalLoginForm = Form.create({ name: 'normal_login' })(
  withRouter(Login),
);

export default WrappedNormalLoginForm;
